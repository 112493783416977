import React from 'react';
import styled from 'styled-components';

const CloseIconStyled = styled.svg`
  width: 6px;
  height: 7px;

  @media only screen and (min-width: 1400px) {
    width: 10px;
    height: 10px;
  }
`;
function CloseIcon() {
  return (
    <CloseIconStyled xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#B21F28"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M.862 8.883l7.89-7.89M8.752 8.883L.862.993" />
      </g>
    </CloseIconStyled>
  );
}

export default CloseIcon;

import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';

const Router = () => (
  <BrowserRouter>
    <ErrorBoundary>
      <Route exact path="/" component={App} />
      <Route path="/:dealerId" component={App} />
    </ErrorBoundary>
  </BrowserRouter>
);

export default React.memo(Router);

import styled from 'styled-components';

const StyledSidebar = styled.aside`
  flex: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  min-width: 460px;
  @media only screen and (max-width: 1400px) {
    min-width: 375px;
    max-width: 450px;
    min-height: 100vh;
  }
  position: ${({ position }) => position};
  transform: ${({ transform }) => transform};
  z-index: 60;
  background: #fff;
  border-right: #ececec 1px solid;
  .sidebar-toggle {
    position: absolute;
    background-color: #0075c9;
    top: 0;
    bottom: 0;
    right: -1px;
    width: 5px;
  }
  .sidebar-toggle button {
    height: 32px;
    width: 110px;
    color: white;
    background-color: #0075c9;
    padding: 0;
    position: absolute;
    border: none;
    top: 50%;
    /* Move up by height + width / 2 to center */
    transform: rotate(90deg) translateX(-82px);
    transform-origin: bottom left;
    font-family: 'source-sans-pro';
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sidebar-toggle span.arrow {
    flex: 0;
    max-width: 5px;
    margin-left: 10px;
    border-top: 5px solid white;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    transition: all 0.3s;
  }
  .collapsed.sidebar-toggle span.arrow {
    transform: rotateX(180deg);
  }
`;

export default StyledSidebar;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  .st0 {
    fill: ${props => (props.white ? '#ffffff' : '#93D500')};
  }
  .st1 {
    fill: ${props => (props.white ? '#ffffff' : '#0075C9')};
  }
`;

const ClaytonHomesLogo = ({ width, height, white }) => (
  <LogoWrapper white={white}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 255 119"
      xmlSpace="preserve"
    >
      <polygon
        className="st0"
        points="151.4,4 86.1,29.3 86.1,34 151.4,20.1 216.7,34 216.7,29.3 "
      />
      <g>
        <path
          className="st1"
          d="M246.7,75.6c-1.8,0-3.3,1.3-3.3,3.3c0,1.9,1.4,3.3,3.3,3.3c1.8,0,3.3-1.3,3.3-3.3
		C250,76.9,248.5,75.6,246.7,75.6z M246.7,81.7c-1.6,0-2.6-1.2-2.6-2.8c0-1.6,1.1-2.8,2.6-2.8c1.4,0,2.6,1.1,2.6,2.8
		C249.3,80.6,248.1,81.7,246.7,81.7z"
        />
        <path
          className="st1"
          d="M248.2,78.2c0-0.7-0.5-1.1-1.4-1.1h-1.4v3.7h0.6v-1.6h0.6l1,1.6h0.7l-1-1.6
		C247.8,79.1,248.2,78.9,248.2,78.2z M246,78.7v-1.1h0.7c0.4,0,0.8,0.1,0.8,0.5c0,0.5-0.4,0.6-0.9,0.6H246z"
        />
        <path
          className="st1"
          d="M29.7,40.3c5.7,0,9.8,2.8,10.5,8h13.6C53.4,35.8,41.2,28.7,30,28.7c-15.7,0-26,12-26,27.1
		c0,15.1,10.2,27.1,26,27.1c13.2,0,23-7.9,23.8-21.3H40.2c-0.5,6-4.1,9.6-10.5,9.6c-8.8,0-12.1-7.7-12.1-15.5
		C17.6,48.1,20.9,40.3,29.7,40.3z"
        />
        <rect x="55.4" y="29.7" className="st1" width="12.4" height="52" />
        <path
          className="st1"
          d="M106.2,55.6c0-10.7-8.7-12.7-17.6-12.7c-4.3,0-8.7,0.7-12,2.6c-3.3,2-5.7,5.2-5.9,10.1h11.6
		c0.6-3.2,2.8-4.8,6.2-4.8c2.6,0,6,1.1,6,3.9c0,2.3-1.2,3.1-3.3,3.5c-8.6,1.7-22,0.8-22,13.1c0,7.8,5.7,11.5,12.9,11.5
		c4.5,0,9.2-1.3,12.5-4.7c0.1,1.2,0.3,2.4,0.7,3.6h12.5c-1.5-3-1.5-6.5-1.5-9.7V55.6z M86.7,75.2c-2.8,0-5.2-1.2-5.2-4.3
		c0-3,2.3-4.2,5.1-4.8c2.7-0.7,5.9-0.9,7.6-2C94.5,71.9,92.6,75.2,86.7,75.2z"
        />
        <path
          className="st1"
          d="M145.4,52v16.8c0,10.3,2.7,13.3,13.3,13.3c2.3,0,4.5-0.3,6.8-0.4v-9.1c-1.1,0-2.2,0.1-3.2,0.1
		c-2.7,0-4.5-0.6-4.5-3.6V52h7.7v-8h-7.7V32.5h-12.4V44h-0.9h-5.4h-7.3l-7.1,24.7h-0.1L117.4,44h-13.1l9.8,26.3
		c0.7,2,3.1,7.9,3.1,9.9c0,2.6-2.1,4.2-4.9,4.2h-4.3v10.1h5.8c8.4,0,12.8-1,15.8-9.3l12-33.2H145.4z"
        />
        <path
          className="st1"
          d="M183.6,82.9c11.7,0,20-8.6,20-20c0-11.4-8.2-20.1-20-20.1c-11.7,0-19.9,8.7-19.9,20.1
		C163.7,74.3,171.9,82.9,183.6,82.9z M183.6,52.3c5.8,0,7.6,5.8,7.6,10.6c0,4.8-1.8,10.5-7.6,10.5c-5.8,0-7.5-5.7-7.5-10.5
		C176.1,58.1,177.9,52.3,183.6,52.3z"
        />
        <path
          className="st1"
          d="M217,61.2c0-4.2,2-8.1,6.7-8.1c6.8,0,6.2,5.2,6.2,10.4v18.3h12.4V57.5c0-5.5-1-14.7-13.6-14.7
		c-4.5,0-9.6,2.4-11.9,6.3h-0.1V44h-12v37.8H217V61.2z"
        />
        <path
          className="st1"
          d="M147.1,93.7c-2,0-4.2,1-5.6,3.2h-0.1V87h-5.2v26.3h5.2v-10c0-3.9,1.2-5.5,4.1-5.5c2.4,0,3.4,1.5,3.4,4.7
		v10.8h5.2v-11.7C154,96.8,152.6,93.7,147.1,93.7z"
        />
        <path
          className="st1"
          d="M164.9,93.7c-6,0-9.8,4-9.8,10.1c0,6,3.9,10,9.8,10c6,0,9.9-4,9.9-10C174.7,97.7,170.9,93.7,164.9,93.7z
		 M164.9,109.8c-3.5,0-4.6-3.1-4.6-6.1c0-3.1,1.1-6.1,4.6-6.1c3.6,0,4.6,3.1,4.6,6.1C169.5,106.8,168.4,109.8,164.9,109.8z"
        />
        <path
          className="st1"
          d="M197.8,93.7c-2.6,0-4.6,1.5-5.7,3.2c-1-2.3-3.2-3.2-5.6-3.2c-2.5,0-4.5,1.1-5.8,3.1h-0.1v-2.6h-4.9v19h5.2
		v-11c0-3.7,2.5-4.4,3.5-4.4c3.3,0,3.2,2.5,3.2,4.7v10.7h5.2v-10.6c0-2.4,0.6-4.8,3.5-4.8c3,0,3.2,2.1,3.2,4.6v10.8h5.2v-12.7
		C204.8,95.6,201.9,93.7,197.8,93.7z"
        />
        <path
          className="st1"
          d="M215.5,93.7c-5.8,0-9.7,4.3-9.7,10.1c0,5.9,3.7,10,9.7,10c4.3,0,7.4-1.9,8.9-6.4h-4.6
		c-0.3,1.2-2,2.5-4.1,2.5c-2.9,0-4.5-1.5-4.7-4.8h13.7C225.1,99.2,222,93.7,215.5,93.7z M211,101.7c0.1-1.5,1-4.1,4.3-4.1
		c2.5,0,3.7,1.4,4.2,4.1H211z"
        />
        <path
          className="st1"
          d="M236.9,101.8c-3-0.7-5.9-1-5.9-2.8c0-1.5,1.7-1.8,2.9-1.8c1.9,0,3.4,0.6,3.6,2.6h5c-0.4-4.7-4.2-6.1-8.4-6.1
		c-4.1,0-8.3,1.3-8.3,6.1c0,3.4,3,4.4,6,5.1c3.8,0.8,5.9,1.4,5.9,2.9c0,1.8-1.9,2.4-3.4,2.4c-2.1,0-4-0.9-4-3.2h-5
		c0.2,5,4.5,6.7,8.9,6.7c4.3,0,8.7-1.6,8.7-6.6C242.9,103.6,239.9,102.5,236.9,101.8z"
        />
      </g>
    </svg>
  </LogoWrapper>
);

ClaytonHomesLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  white: PropTypes.bool,
};

ClaytonHomesLogo.defaultProps = {
  height: 43,
  width: 94,
  white: false,
};

export default ClaytonHomesLogo;

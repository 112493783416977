import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Ripples from 'react-ripples';
import StyledToggle from './Toggle.Sytle';
import Header from '../Typography/Header';

export default class Toggle extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    selected: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { selected } = this.props;

    this.state = {
      selected,
    };

    this.setSelected = this.setSelected.bind(this);
  }

  componentWillReceiveProps(props) {
    const { selected } = props;
    this.setState({
      selected,
    });
  }

  setSelected(event) {
    const { onChange } = this.props;

    this.setState({
      selected: event.target.value,
    });
    onChange(event);
  }

  render() {
    const { selected } = this.state;
    const { options, label, id } = this.props;

    const optionsToSelect = options.map(item => (
      <li key={item}>
        <Ripples color="rgba(0, 104, 179, .5)" during={750}>
          <button
            className={` ${item === selected ? 'selected' : ''}`}
            onClick={this.setSelected}
            type="button"
            id={id}
            value={item}
          >
            {item}
          </button>
        </Ripples>
      </li>
    ));

    return (
      <StyledToggle>
        <Header headerType="h3" isBold>
          {label}
        </Header>
        <ul>{optionsToSelect}</ul>
      </StyledToggle>
    );
  }
}

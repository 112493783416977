import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import StyledDetails from './Details.Style';
import DetailsCarousel from './DetailsCarousel/DetailsCarousel';
import Sidebar from '../Sidebar/Sidebar';
import TitleBar from '../TitleBar';
import HomeInfo from './HomeInfo';
import FlagIcon from '../../images/FlagIcon';
import ArrowLeft from '../../images/ArrowLeft';
import ArrowRight from '../../images/ArrowRight';
import Header from '../Typography/Header';
import Hyperlink from '../Typography/Hyperlink';
// import ESHLogo from '../../images/Details/svg/ESHLogo';
import { isIe } from '../../utils';
import {
  FULL_PRICING_DISCLAIMER,
  SHORT_PRICING_DISCLAIMER,
} from '../../config';

const titleCase = str =>
  str.replace(
    /\b\w+/g,
    string => string.charAt(0).toUpperCase() + string.substr(1).toLowerCase()
  );

const formatDealerName = name =>
  name ? titleCase(name.toLowerCase()).replace('-', ' of ') : false;

const SlideFadePosed = posed.div({
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 500,
      delay: 200,
    },
  },
  exit: {
    opacity: isIe ? 1 : 0,
    x: isIe ? 0 : 250,
  },
});

const SlideYFadePosed = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 500,
    },
  },
  exit: {
    opacity: isIe ? 1 : 0,
    y: isIe ? 0 : 200,
  },
});

class Details extends Component {
  static propTypes = {
    dealer: PropTypes.shape({}),
    home: PropTypes.shape({}),
  };

  static defaultProps = {
    home: null,
    dealer: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      homeNotFound: false,
      modalIsVisible: false,
      modalContent: this.floorPlan(),
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.checkForHome();
    }, 1000);
  }

  checkForHome = () => {
    const { home } = this.props;
    if (!home) {
      this.setState({ homeNotFound: true });
    }
  };

  modalClick = type => {
    if (type === 'floor-plan')
      this.setState({ modalContent: this.floorPlan() }, this.toggleModal());
    else if (type === 'disclaimer') {
      this.setState({ modalContent: this.disclaimer() }, this.toggleModal());
    } else {
      this.setState({ modalContent: this.virtualTour() }, this.toggleModal());
    }
  };

  toggleModal = () => {
    const { modalIsVisible } = this.state;

    this.setState({
      modalIsVisible: !modalIsVisible,
    });
  };

  floorPlan = () => {
    const { home } = this.props;

    return (
      home && (
        <div className="model-item">
          <img
            src={`${home.floorPlanImage}?flatten=true`}
            alt="floorPlanImage"
          />
        </div>
      )
    );
  };

  disclaimer = () => (
    <p className="full-disclaimer">{FULL_PRICING_DISCLAIMER}</p>
  );

  virtualTour = () => {
    const { home } = this.props;

    return (
      home && (
        <iframe
          title="virtual tour"
          scrolling="no"
          frameBorder="0"
          src={home.virtualTour}
          id="vt"
        />
      )
    );
  };

  render() {
    const { homeNotFound, modalContent, modalIsVisible } = this.state;
    const { home, dealer } = this.props;
    // Redirect to welcome page with error message
    if (dealer && homeNotFound) {
      return (
        <Redirect
          to={{
            pathname: `/${dealer.dealerId}/listing`,
            state: { error: 'home not found' },
          }}
        />
      );
    }

    if (!home) {
      return (
        <StyledDetails>
          <Sidebar />
          <div role="main">
            <h2>Looking for Home</h2>
          </div>
        </StyledDetails>
      );
    }
    return (
      <StyledDetails>
        <Sidebar>
          {dealer && (
            <TitleBar
              dealerId={dealer.dealerId}
              brand={dealer.brandAcronym}
              name={dealer.dealerName}
            />
          )}
          <div className="details-contain">
            <Link
              to={`/${dealer.dealerId}/listing`}
              className="home-listing-link"
            >
              <>
                <ArrowLeft height={9} />
                Home Listings
              </>
            </Link>
            <HomeInfo
              flagType={
                home.stickerDisplayText && (
                  <FlagIcon text={home.stickerDisplayText} />
                )
              }
              homeDescription={home.name}
              priceString={home.priceLabel}
              sqFeet={home.squareFeet}
              isMulti={home.isMultiSection}
              beds={home.beds}
              bath={home.baths}
            />
            <PoseGroup animateOnMount>
              {home.homeBuilder && (
                <SlideYFadePosed key={0} className="building-facility">
                  <Header>Who Builds This Home</Header>
                  {home.homeBuilderUrl && (
                    <Hyperlink
                      href={`https://${home.homeBuilderUrl}`}
                      data-builder={formatDealerName(home.homeBuilder)}
                      className="builder-link"
                      target="_blank"
                    >
                      <>
                        {home.homeBuilderLogo && (
                          <>
                            <img
                              src={home.homeBuilderLogo}
                              alt={home.homeBuilder}
                            />
                            <br />
                          </>
                        )}
                        {`${formatDealerName(
                          home.homeBuilder
                        )}\u00A0\u00A0\u00A0\u00A0`}
                        <ArrowRight height={9} />
                      </>
                    </Hyperlink>
                  )}
                  {!home.homeBuilderUrl && (
                    <Header
                      headerType="h2"
                      isBold
                      className="home-builder-name"
                    >
                      {formatDealerName(home.homeBuilder)}
                    </Header>
                  )}
                </SlideYFadePosed>
              )}
            </PoseGroup>
            <div className="energy-smart-disclaimer">
              {/* <a
                href={`https://www.${dealer.firstVanityUrl}/energy-smart-home`}
                target="blank"
              >
                <ESHLogo />
              </a> */}
              <p className="light-disclaimer">{SHORT_PRICING_DISCLAIMER}</p>
              <button
                className="disclaimer-button"
                type="button"
                onClick={() => {
                  this.modalClick('disclaimer');
                }}
              >
                <p className="disclaimer-button-text">
                  {' '}
                  View full pricing information
                </p>
              </button>
            </div>
          </div>
        </Sidebar>
        <div role="main">
          <PoseGroup animateOnMount>
            <SlideFadePosed key={0} className="carousel-wrapper">
              <DetailsCarousel images={home ? home.images : ['']} />
            </SlideFadePosed>
            <SlideFadePosed key={1} className="model_items">
              <button
                type="button"
                onClick={() => {
                  this.modalClick('floor-plan');
                }}
              >
                {this.floorPlan()}
              </button>

              {home.virtualTour && (
                <button
                  type="button"
                  onClick={() => {
                    this.modalClick('vt');
                  }}
                  className="vt-button"
                >
                  {this.virtualTour()}
                  <div className="vt-overlay" />
                </button>
              )}
            </SlideFadePosed>
          </PoseGroup>
        </div>
        <Modal
          isVisible={modalIsVisible}
          modalSize="full"
          onCloseClicked={this.toggleModal}
          showCloseButton
        >
          {modalContent || <></>}
        </Modal>
      </StyledDetails>
    );
  }
}
export default Details;

import styled from 'styled-components';

const StyledWelcome = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${props => props.background});
  background-position: center bottom;
  background-size: cover;
  display: flex;
  flex-flow: column wrap;
  header {
    min-height: 50px;
  }

  div[role='main'] {
    margin-top: 70px;
    flex: 1 1 auto;
    align-items: center;
    display: flex;
    flex-flow: column wrap;
  }
  .cta {
    margin-top: 30px;
    display: inline-block;
    border: 1px solid #ffffff;
    line-height: 85px;
    padding-bottom: 4px;
    color: #ffffff;
    font-family: 'acumin-pro';
    font-size: 30px;
    letter-spacing: -1px;
    font-weight: 300;
    width: 300px;
    text-align: center;
  }
`;

export default StyledWelcome;

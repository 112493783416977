import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WithStore } from 'pure-react-carousel';
import Slider from 'rc-slider';
import StyledPagination from './Pagination.Style';
import 'rc-slider/assets/index.css';

const { Handle } = Slider;

const handle = props => {
  const { value, ...restProps } = props;
  delete restProps.dragging;
  return (
    <Handle value={value} {...restProps}>
      <div className="pagination-handle">{value + 1}</div>
    </Handle>
  );
};

handle.propTypes = {
  value: PropTypes.number.isRequired,
};
/* eslint-disable-next-line react/prefer-stateless-function */
class Pagination extends Component {
  static propTypes = {
    pages: PropTypes.number.isRequired,
    carouselStore: PropTypes.shape({
      getStoreState: PropTypes.func.isRequired,
      masterSpinnerError: PropTypes.func.isRequired,
      masterSpinnerSuccess: PropTypes.func.isRequired,
      setStoreState: PropTypes.func.isRequired,
      subscribeMasterSpinner: PropTypes.func.isRequired,
      unsubscribeAllMasterSpinner: PropTypes.func.isRequired,
      unsubscribeMasterSpinner: PropTypes.func.isRequired,
    }).isRequired,
    currentSlide: PropTypes.number.isRequired,
    backButton: PropTypes.func.isRequired,
    nextButton: PropTypes.func.isRequired,
  };

  render() {
    const {
      pages,
      carouselStore,
      currentSlide,
      backButton,
      nextButton,
    } = this.props;
    return (
      <StyledPagination>
        <div className="slider-container">
          {backButton()}
          <Slider
            min={0}
            max={pages - 1}
            value={currentSlide}
            handle={handle}
            trackStyle={[{ backgroundColor: 'transparent' }]}
            onChange={newValue => {
              carouselStore.setStoreState({
                currentSlide: newValue,
              });
            }}
            onAfterChange={() => {
              window.dataLayer.push({
                event: 'interaction',
                eventCategory: 'Home List',
                eventAction: 'Set Page',
                eventLabel: 'Slider',
              });
            }}
          />
          {nextButton()}
        </div>
        <p className="viewing">
          {currentSlide + 1} of {pages}
        </p>
      </StyledPagination>
    );
  }
}

export default WithStore(Pagination, state => ({
  currentSlide: state.currentSlide,
}));

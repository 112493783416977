import styled from 'styled-components';

const StyledBorderlessSelect = styled.div`
  position: relative;
  label {
    .label-wrapper {
      padding: 12px 0px 9px;
      font-size: 16px;
      line-height: 23px;
      font-family: source-sans-pro, sans-serif;
      font-weight: 600;
      color: #414042;
      display: inline-block;
    }

    svg {
      position: relative;
      /* right: 25px; */
      top: -2px;
    }
  }
  .select-wrapper {
    display: inline-block;
  }
  select {
    border-radius: 0;
    -webkit-appearance: none;
    background: transparent;
    border: none;
    outline: 0;

    padding: 10px 10px 9px;
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 27px;
    font-family: source-sans-pro, sans-serif;
    font-weight: 400;
    color: #6d6e71;
  }
`;

export default StyledBorderlessSelect;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  .st0 {
    fill: none;
    stroke: ${props => (props.white ? '#ffffff' : '#004b8a')};
    stroke-width: 2;
  }
  .st1 {
    fill: ${props => (props.white ? '#ffffff' : '#f61936')};
  }
  .st2 {
    fill: ${props => (props.white ? '#ffffff' : '#004b8a')};
  }
`;

const InternationalLogo = ({ width, height, white }) => (
  <LogoWrapper white={white}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 112 33"
      xmlSpace="preserve"
    >
      <path
        className="st0"
        d="M110.3,27.7c0,2.8-1.7,3.6-3.9,3.6H5.6c-2.4,0-4.3-1.2-4.3-4.2V5.7c0-3.3,2.4-4.6,4.3-4.6h100.8
	c1.6,0,3.8,1.6,3.8,3.4L110.3,27.7L110.3,27.7z"
      />
      <g>
        <path
          className="st1"
          d="M57.3,21.2L57.3,21.2c-0.1,0-1,0-1,0l0,0c-0.4,0-0.7,0.1-1,0.3c0,0-0.1,0.1-0.1,0.1c-0.2-0.2-0.4-0.4-0.7-0.4
		c-0.1,0-1,0-1,0l0,0c-0.4,0-0.7,0.1-1,0.3v-0.1c0-0.1-0.1-0.2-0.3-0.2h-1.6c0,0-0.2,0.1-0.2,0.2v7.1c0,0,0,0.2,0.2,0.2h1.8
		c0.1,0,0.2-0.1,0.2-0.3v-5.8c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.3,0.1,0.4,0.2v5.9c0,0,0,0.2,0.1,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3v-5.9
		c0.4-0.4,0.7-0.1,0.8,0l0,5.9c0,0,0,0.2,0.2,0.2H58c0.1,0,0.2-0.1,0.2-0.3V22C58.2,21.8,58,21.3,57.3,21.2z"
        />
        <path
          className="st1"
          d="M59.6,9.1v2.5c0,0,0,0.2,0.1,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3v-3c0-0.1-0.3-0.9-0.9-1c-0.1,0-1,0-1,0l0,0
		c-0.5,0-0.8,0.5-1,0.6V8c0-0.1-0.2-0.2-0.3-0.2h-1.6c0,0-0.2,0.1-0.2,0.2V15c0,0,0,0.2,0.2,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3V9.1
		c0.1-0.1,0.3-0.2,0.4-0.2C59.4,8.9,59.5,9,59.6,9.1z"
        />
        <path
          className="st1"
          d="M47.6,21.3h-1.8c-1,0-1.7,0.8-1.7,1.7v4.1c0,0.9,0.8,1.7,1.7,1.7h1.8c1,0,1.7-0.8,1.7-1.7V23
		C49.3,22.1,48.6,21.3,47.6,21.3z M47.2,27.3c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4v-4.6c0-0.2,0.2-0.4,0.4-0.4
		c0.2,0,0.4,0.2,0.4,0.4V27.3z"
        />
        <path
          className="st1"
          d="M50.2,14L50.2,14c-0.6,0-0.6-0.2-0.6-0.4V8.8h0.3c0.2,0,0.3-0.1,0.3-0.2V7.9c0-0.1-0.2-0.2-0.4-0.2h-0.1V6.6
		c0-0.1-0.2-0.2-0.3-0.2h-1.6c0,0-0.2,0.1-0.2,0.2l0,1.1h-0.2c0,0-0.4,0-0.4,0.2v0.7c0,0,0,0.1,0.2,0.1h0.3c0,2,0,4.7,0,4.9
		c0,0.5,0.3,1.5,1,1.5c0.1,0,1.2,0,1.7,0C50.4,15.1,50.2,14,50.2,14z"
        />
        <path
          className="st1"
          d="M38.3,7.4h1.8c0.1,0,0.2-0.1,0.2-0.3V6.3C40.3,6.2,40.2,6,40,6h-1.6c0,0-0.2,0.1-0.2,0.2v1
		C38.2,7.2,38.2,7.4,38.3,7.4z"
        />
        <path
          className="st1"
          d="M52.5,15.2h1.6c1,0,1.7-0.8,1.7-1.7v-1.3H54c0,0-0.2,0-0.3,0.3c0,0.2,0,1,0,1.3c-0.1,0.5-0.8,0.3-0.8,0.1
		c0-0.3,0-2.2,0-2.2l3,0V9.3c0-1-0.8-1.7-1.7-1.7h-1.6c-1,0-1.7,0.8-1.7,1.7v4.2C50.8,14.4,51.6,15.2,52.5,15.2z M52.9,9.1
		c0,0,0-0.4,0.4-0.4c0.4,0,0.4,0.3,0.4,0.4v1.4h-0.8L52.9,9.1z"
        />
        <path
          className="st1"
          d="M44.3,15c0,0,0,0.2,0.2,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3V8.5c0-0.1-0.2-0.6-0.8-0.8l0,0c-0.1,0-1,0-1,0l0,0
		c-0.4,0-0.7,0.1-1,0.3V8c0-0.1-0.2-0.2-0.3-0.2h-1.6c0,0-0.2,0.1-0.2,0.2V15c0,0,0,0.2,0.2,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3V9.1
		C43.6,9,43.8,8.9,44,8.9c0.1,0,0.3,0.1,0.4,0.2V15z"
        />
        <path
          className="st1"
          d="M38.3,15.2h1.8c0.1,0,0.2-0.1,0.2-0.3V8c0-0.1-0.1-0.2-0.3-0.2h-1.6c0,0-0.2,0.1-0.2,0.2V15
		C38.2,15,38.2,15.2,38.3,15.2z"
        />
        <path
          className="st1"
          d="M42.4,21.3c-0.1,0-1,0-1,0l0,0c-0.4,0-0.7,0.1-1,0.3v-1.9c0-0.1-0.2-0.2-0.3-0.2h-1.6c0,0-0.2,0.1-0.2,0.2v8.8
		c0,0,0,0.2,0.1,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3v-5.8c0.1-0.1,0.3-0.2,0.4-0.2c0.1,0,0.3,0.1,0.4,0.2v5.9c0,0,0,0.2,0.2,0.2h1.8
		c0.1,0,0.2-0.1,0.2-0.3v-6.5C43.3,21.9,43.1,21.4,42.4,21.3z"
        />
        <path
          className="st1"
          d="M90.8,7.7L90.8,7.7c-0.1,0-1,0-1,0l0,0c-0.4,0-0.7,0.1-1,0.3V8c0-0.1-0.2-0.2-0.3-0.2h-1.6
		c0,0-0.2,0.1-0.2,0.2V15c0,0,0,0.2,0.1,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3V9.1C88.8,9,89,8.9,89.1,8.9c0.1,0,0.3,0.1,0.4,0.2V15
		c0,0,0,0.2,0.2,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3V8.5C91.7,8.4,91.5,7.8,90.8,7.7z"
        />
        <path
          className="st1"
          d="M62.4,21.1h-1.6c-1,0-1.7,0.8-1.7,1.7v4.2c0,1,0.8,1.7,1.7,1.7h1.6c1,0,1.7-0.8,1.7-1.7v-1.3h-1.9
		c0,0-0.2,0-0.3,0.3c0,0.2,0,1,0,1.3c-0.1,0.5-0.8,0.3-0.8,0.1c0-0.3,0-2.2,0-2.2l3,0v-2.4C64.2,21.9,63.4,21.1,62.4,21.1z
		 M61.9,24.1h-0.8l0-1.4c0,0,0-0.4,0.4-0.4c0.4,0,0.4,0.3,0.4,0.4V24.1z"
        />
        <path
          className="st1"
          d="M79.8,6h-1.6c0,0-0.2,0.1-0.2,0.2v1c0,0,0,0.2,0.2,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3V6.3C80.1,6.2,79.9,6,79.8,6z
		"
        />
        <path
          className="st1"
          d="M97.4,11.2L97.4,11.2L97.4,11.2l0-2c0-1-0.8-1.7-1.7-1.7h-1.6c-1,0-1.7,0.8-1.7,1.7l0,0.9h2.1
		c0,0-0.2-1.2,0.4-1.2c0.4,0,0.3,0.9,0.1,1.1c-0.2,0.3-2.2,1-2.6,1.5c-0.1,0.2-0.1,1.9-0.1,1.9c0,1.9,0.9,1.9,1.8,1.8s1-0.4,1.2-0.4
		c0.2,0,0.1,0.3,0.3,0.4c0.2,0,1.2,0,1.2,0c0.5,0,0.9,0.3,0.6-1.8L97.4,11.2z M95.2,12.4c0,0.2,0,0.9,0,1.2c-0.3,0.3-0.7,0-0.7-0.2
		c0-0.3-0.1-1.4,0.1-1.7c0.1-0.2,0.4-0.3,0.6-0.4C95.2,11.8,95.3,12.3,95.2,12.4z"
        />
        <path
          className="st1"
          d="M100.5,5.9h-1.6c0,0-0.2,0.1-0.2,0.3V15c0,0,0,0.2,0.1,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3V6.2
		C100.8,6.1,100.6,5.9,100.5,5.9z"
        />
        <path
          className="st1"
          d="M84.1,7.9L84.1,7.9l-1.8,0c-1,0-1.7,0.8-1.7,1.7v4.1c0,0.9,0.8,1.7,1.7,1.7h1.8c1,0,1.7-0.8,1.7-1.7V9.6
		C85.8,8.7,85,7.9,84.1,7.9z M83.6,13.9c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.4-0.2-0.4-0.4V9.3c0-0.2,0.2-0.4,0.4-0.4
		c0.2,0,0.4,0.2,0.4,0.4V13.9z"
        />
        <path
          className="st1"
          d="M67.1,23c0-0.5,0.1-0.8,0.4-0.8c0.6,0,0.4,1,0.4,1.4h2c0.4,0,0.4-0.6,0.4-0.6c0-1-0.8-1.7-1.7-1.7h-1.9
		c-1,0-1.7,0.8-1.7,1.7c0,1.4,1.7,2.4,1.3,2.1c-0.4-0.2,1.6,1.2,1.7,1.6c0.1,0.4-0.1,1-0.4,0.9C66.9,27.6,67,26,67,26l-2.1,0v1
		c0,1,0.8,1.7,1.7,1.7h1.9c1,0,1.8-1.2,1.8-1.8c0,0,0-0.8-0.1-1.2C69.9,24.9,67.2,23.6,67.1,23z"
        />
        <path
          className="st1"
          d="M65.4,9.1V15c0,0,0,0.2,0.1,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3V8.5c0-0.1-0.2-0.6-0.8-0.8v0c-0.1,0-1,0-1,0l0,0
		c-0.4,0-0.7,0.1-1,0.3V8c0-0.1-0.1-0.2-0.3-0.2h-1.6c0,0-0.2,0.1-0.2,0.2V15c0,0,0,0.2,0.1,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3V9.1
		C64.6,9,64.8,8.9,65,8.9C65.1,8.9,65.2,9,65.4,9.1z"
        />
        <path
          className="st1"
          d="M79.8,7.7h-1.6c0,0-0.2,0.1-0.2,0.2V15c0,0,0,0.2,0.1,0.2h1.8c0.1,0,0.2-0.1,0.2-0.3V8
		C80,7.8,79.9,7.7,79.8,7.7z"
        />
        <path
          className="st1"
          d="M77.1,14c-0.6,0-0.6-0.2-0.6-0.4V8.8h0.3c0.2,0,0.3-0.1,0.3-0.2V7.9c0-0.1-0.2-0.2-0.4-0.2h-0.1V6.6
		c0-0.1-0.2-0.2-0.3-0.2h-1.6c0,0-0.2,0.1-0.2,0.2l0,1.1h-0.2c0,0-0.4,0-0.4,0.2v0.7c0,0,0,0.1,0.2,0.1h0.3c0,2,0,4.7,0,4.9
		c0,0.5,0.3,1.5,1,1.5c0.1,0,1.2,0,1.7,0C77.3,15.1,77.2,14,77.1,14L77.1,14z"
        />
        <path
          className="st1"
          d="M73.3,11.2L73.3,11.2L73.3,11.2l0-2c0-1-0.8-1.7-1.7-1.7H70c-1,0-1.7,0.8-1.7,1.7l0,0.9h2.1
		c0,0-0.2-1.2,0.4-1.2c0.4,0,0.3,0.9,0.1,1.1c-0.2,0.3-2.2,1-2.6,1.5c-0.1,0.2-0.1,1.9-0.1,1.9c0,1.9,0.9,1.9,1.8,1.8
		c0.9-0.1,1-0.4,1.2-0.4c0.2,0,0.1,0.3,0.3,0.4c0.2,0,1.2,0,1.2,0c0.5,0,0.9,0.3,0.6-1.8L73.3,11.2z M71.1,12.4L71.1,12.4
		c0,0.2,0,0.9,0,1.2c-0.3,0.3-0.7,0-0.7-0.2c0-0.3-0.1-1.4,0.1-1.7c0.1-0.2,0.4-0.3,0.6-0.4C71.1,11.8,71.1,12.3,71.1,12.4z"
        />
      </g>
      <path
        className="st2"
        d="M100.9,17c0-0.5-0.4-0.3-0.6-0.3l-64.5-0.1c0,0-0.4-2-1.9-3.9c-1.5-1.9-3.3-2.8-3.3-2.8s0.9-5.5,0.9-5.8
	c0.1-0.3,0.2-1.2-0.5-0.5c-0.6,0.7-2.3,1.5-4,1.6c-1.7,0.1-3-0.8-3.1-0.9c-0.2-0.2-0.8-1.2-1-0.2c-0.2,1-0.9,2.2-2.5,3.1
	c-1.6,0.9-3.7,0.2-3.9,0c-0.2-0.1-0.8-0.6-0.9-0.2c-0.3,1.4-0.9,2.6-1.9,3.6c-1.4,1.4-3.1,1.5-3.6,1.5c-0.6,0-0.4,0.2-0.2,0.4
	c0.1,0.2,5,3.7,5,3.7c-0.1,2,0.9,10.1,10.5,10.1c9.6,0,10.3-7.8,10.3-7.8l64.6,0.1c0.5,0,0.6-0.3,0.6-0.3S100.9,17.5,100.9,17z
	 M31.6,12.6c-1.5,0-1.5,0-1.5,0l-0.3-1C29.8,11.6,31.1,11.4,31.6,12.6z M17.7,20.6c-0.8-0.7-0.8-2-0.8-2s2.4,0.1,2.8,0
	c0,0.2,0.3,2,0.3,2H17.7z M17,16.6c0.1-0.9,1.2-2.5,1.2-2.5l2.2,0c0,0-0.2,0.6-0.3,1.3c-0.2,0.7-0.2,1.3-0.2,1.3H17z M19.2,22.6h1.5
	c0,0,0,0.6,0.5,1.5C20.1,23.8,19.2,22.6,19.2,22.6z M24.5,24.7c0,0-1.9-0.5-2.2-2.2h2.2V24.7z M24.5,20.7H22c0,0-0.3-0.4-0.3-2.2
	h2.8V20.7z M21.8,16.6L21.8,16.6c0-1.4,0.6-2.5,0.6-2.5h2.3l0,2.5C24.6,16.7,22.8,16.7,21.8,16.6z M24.6,12.6c0,0-0.3,0-1.8,0
	c0.5-1.2,1.8-1.3,1.8-1.3L24.6,12.6z M21.8,11.3c-4.5,1.8-6.5,4.1-6.5,4.1l-3-2.3c0.9-0.1,2.2-1.1,2.9-1.8c0.6-0.7,1.3-1.9,1.5-2.6
	c0.3,0.1,2.5,0.6,4.5-0.3c2-0.9,2.6-2.5,2.6-2.5s1.5,0.8,3.3,0.8c1.8,0,3.5-1.2,3.5-1.2S30.1,9.2,30,9.2
	C29.9,9.2,26.2,9.4,21.8,11.3z M28.2,12.7c-1.5,0-1.8,0-1.8,0l0-1.3C26.4,11.3,27.8,11.5,28.2,12.7z M26.4,24.8v-2.2h2.2
	C28.2,24.3,26.4,24.8,26.4,24.8z M28.9,20.7h-2.5v-2.2h2.8C29.2,20.3,28.9,20.7,28.9,20.7z M26.4,16.7l0-2.5h2.3
	c0,0,0.6,1.1,0.6,2.5C28.2,16.8,26.4,16.7,26.4,16.7z M29.8,24.1c0.4-0.9,0.5-1.5,0.5-1.5h1.5C31.7,22.6,30.8,23.8,29.8,24.1z
	 M33.3,20.6H31c0,0,0.3-1.8,0.3-2c0.4,0.1,2.8,0,2.8,0S34.1,19.9,33.3,20.6z M31.4,16.7c0,0,0-0.6-0.2-1.3c-0.2-0.7-0.3-1.3-0.3-1.3
	l2.2,0c0,0,1.1,1.6,1.2,2.5H31.4z"
      />
    </svg>
  </LogoWrapper>
);

InternationalLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  white: PropTypes.bool,
};

InternationalLogo.defaultProps = {
  height: 33,
  width: 100,
  white: false,
};

export default InternationalLogo;

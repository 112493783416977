export const SHORT_PRICING_DISCLAIMER = `*Base model starting prices do not include available options, required delivery & installation or taxes. Installed price will be higher. See home details for pricing details and home information.`;

export const FULL_PRICING_DISCLAIMER = `*Advertised starting sales prices are for the home only. 
Delivery and installation costs are not included unless otherwise stated. Starting prices shown on 
this website are subject to change, see your local Home Center for current and specific home and 
pricing information. Sales price does not include other costs such as taxes, title fees, insurance 
premiums, filing or recording fees, land or improvements to the land, optional home features, 
optional delivery or installation services, wheels and axles, community or homeowner association 
fees, or any other items not shown on your Sales Agreement, Retailer Closing Agreement and related 
documents (your SA/RCA). If you purchase a home, your SA/RCA will show the details of your purchase. 
Homes available at the advertised sales price will vary by retailer and state. Artists' renderings of 
homes are only representations and actual home may vary. Floor plan dimensions are approximate and 
based on length and width measurements from exterior wall to exterior wall. We invest in continuous 
product and process improvement. All home series, floor plans, specifications, dimensions, features, 
materials, and availability shown on this website are subject to change.`;

export default {};

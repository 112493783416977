import React from 'react';
import PropTypes from 'prop-types';
import SVGCloseIcon from '../../images/CloseIconWithCircle';

import { ModalStyles } from './styles/ModalStyles';

let lastModalOpened = null;

class Modal extends React.Component {
  constructor(props) {
    super(props);

    lastModalOpened = this;
  }

  componentWillMount() {
    document.body.classList.add('modal-lock');
  }

  componentWillUnmount() {
    if (lastModalOpened === this) {
      document.body.classList.remove('modal-lock');
    }
  }

  render() {
    let modalSizeClassName;
    const {
      modalSize,
      children,
      isVisible,
      onCloseClicked,
      showCloseButton,
      closeButtonColor,
    } = this.props;

    switch (modalSize) {
      case 'medium':
        modalSizeClassName = 'modal-content-md';
        break;
      case 'large':
        modalSizeClassName = 'modal-content-lg';
        break;
      case 'account':
        modalSizeClassName = 'modal-content-account';
        break;
      case 'account-no-fullscreen':
        modalSizeClassName = 'modal-content-account no-fullscreen';
        break;
      case 'full':
        modalSizeClassName = 'modal-content-full';
        break;
      case 'small':
      default:
        modalSizeClassName = 'modal-content-sm';
    }

    if (!children) {
      throw new Error('Children Must Be Provided To The Modal');
    }

    return isVisible ? (
      <ModalStyles className="main-modal-container">
        {/* TODO handle this better */}
        {/* eslint-disable-next-line */}
        <div
          role="button"
          className="backdrop"
          onClick={ev => {
            onCloseClicked(ev);
          }}
          onKeyDown={ev => {
            if (ev.keyCode === 27) {
              onCloseClicked(ev);
            }
          }}
        />
        <div className={`modal-contents ${modalSizeClassName}`}>
          {showCloseButton ? (
            // TODO handle this better
            // eslint-disable-next-line
            <button
              className="modal-close-button"
              onClick={ev => {
                onCloseClicked(ev);
              }}
              onKeyDown={ev => {
                if (ev.keyCode === 27) {
                  onCloseClicked();
                }
              }}
            >
              <SVGCloseIcon color={closeButtonColor} size="12" />
            </button>
          ) : null}
          {children}
        </div>
      </ModalStyles>
    ) : null;
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  closeButtonColor: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  modalSize: PropTypes.oneOf([
    'small',
    'medium',
    'large',
    'account',
    'account-no-fullscreen',
    'full',
  ]),
};

Modal.defaultProps = {
  closeButtonColor: '#414042',
  showCloseButton: true,
  modalSize: 'medium',
};

export default Modal;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  .st0 {
    fill: ${props => (props.white ? '#ffffff' : '#00447C')};
  }
  .st1 {
    fill: ${props => (props.white ? '#ffffff' : '#E31837')};
  }
`;

const FreedomLogo = ({ width, height, white }) => (
  <LogoWrapper white={white}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      height={height}
      width={width}
      viewBox="0 0 107 35"
      xmlSpace="preserve"
    >
      <g>
        <polygon
          className="st0"
          points="12.6,8.9 13.3,5.5 20.8,5.5 21.9,0.4 8,0.4 3.1,22.6 9.5,22.6 11.5,13.7 18.4,13.7 19.5,8.9 	"
        />
        <path
          className="st0"
          d="M32.3,13.5c0.2-1,0.5-2.2,0.2-2.9c-0.3-0.8-1.1-1.3-2.1-1.4l0,0c1.8-0.2,3.3-1.4,3.9-4.1
		c0.6-2.9-0.5-4.5-4-4.5h-7.5L19,17.7h5l1.5-6.8h0.9c0.9,0,1.3,0.3,1,1.7c-0.2,0.8-0.3,1.7-0.5,2.6c-0.2,0.8-0.4,2.1-0.2,2.5H32
		l0.1-0.2c-0.2-0.1-0.3-0.3-0.4-0.5s0-1.1,0.2-2L32.3,13.5z M29.1,5.7c-0.4,1.7-1.3,2-2.2,2h-0.7l0,0L27,3.9h0.6
		C28.8,3.9,29.4,4.3,29.1,5.7z"
        />
        <polygon
          className="st0"
          points="39,10.8 44.4,10.8 45.2,7 39.8,7 40.4,4.4 46.2,4.4 47,0.4 36.2,0.4 32.4,17.7 43.4,17.7 44.3,13.8
		38.3,13.8 	"
        />
        <polygon
          className="st0"
          points="50.9,10.8 56.3,10.8 57.1,7 51.7,7 52.3,4.4 58.1,4.4 59,0.4 48.2,0.4 44.3,17.7 55.4,17.7
		56.2,13.8 50.2,13.8 	"
        />
        <polygon
          className="st0"
          points="97.5,0.4 93.5,11 93.5,11 94.3,0.4 87,0.4 83.2,17.7 87.9,17.7 90.7,5.1 90.8,5.1 90.2,17.7
		93.8,17.7 98.8,5.1 98.8,5.1 96.1,17.7 100.8,17.7 104.6,0.4 	"
        />
        <path
          className="st0"
          d="M79.9,0.1c-6.5,0-7.3,3.6-8.4,9c-1.2,5.5-2,9,4.5,9s7.2-3.5,8.4-9C85.5,3.7,86.3,0.1,79.9,0.1z M79.2,9.1
		c-1.1,5-1.5,5.8-2.6,5.8s-1.1-0.7,0-5.8c1.1-5,1.5-5.8,2.6-5.8C80.2,3.3,80.3,4,79.2,9.1z"
        />
        <path
          className="st0"
          d="M71.4,2.7c-0.5-1.4-1.8-2.2-4-2.2h-7.2l-3.8,17.2h7.3c5.2,0,6.5-4.9,7.3-8.6C71.8,5,71.8,4,71.4,2.7z
		 M66.3,6.6l-1.1,5c-0.3,1.3-0.8,2.6-2,2.6h-1l2.3-10.3h0.7C66.7,3.9,66.7,4.8,66.3,6.6z"
        />
      </g>
      <path
        className="st0"
        d="M23.7,21.3l0.2,1.1l-1.3-0.7l-1.3,0.7l0.2-0.9c-2,0.3-3.9,0.6-5.9,1L15,23l0.3,1.4L14,23.7l-1.3,0.7l0.2-1.3
	c-2.1,0.5-4.1,1.1-5.9,1.8l0.5,1L9,26.1l-1.1,1L8,28.6l-1.3-0.7l-1.3,0.7l0.3-1.4l-1.1-1L6,26l0.4-0.8c-1.5,0.6-2.9,1.3-4.2,2
	l-2.1,7.6h3c1.9-2.4,4.4-4.5,7.4-6.2l0.1-0.7l0,0l-1.1-1l1.5-0.2l0.7-1.3l0.7,1.3l1.4,0.2c1.3-0.6,2.7-1.2,4.1-1.7l-1-0.9l1.5-0.2
	l0.7-1.3l0.7,1.3l1.1,0.1c8.2-2.6,17-3.7,22.2-3.7C39,20.4,31.5,20.4,23.7,21.3z M4.8,31.2L5,32.6l-1.3-0.7l-1.3,0.7l0.3-1.4l-1.1-1
	L3,30l0.7-1.3L4.4,30l1.5,0.2L4.8,31.2z"
      />
      <path
        className="st1"
        d="M50.3,20.4c-1.4,0-3.4,0.4-5.5,1.5h0.4l-0.9,4.3H49l0.9-4.3h2.6L50,33h-2.6l1.1-4.8h-4.7L42.7,33h-2.6
	l2.1-9.7c-3.2,2.2-6.5,5.9-8.4,11.5l63.3-0.1l3.2-14.4L50.3,20.4L50.3,20.4z M57,33.3c-3.2,0-5.2-1.8-5.2-4.9c0-3.6,2.4-6.7,6.3-6.7
	c3.1,0,5.1,1.7,5.1,4.8C63.4,30.1,61.1,33.3,57,33.3z M73,33l2.1-8.9l0,0l-5,8.9h-2L67,24.1l0,0L65.3,33h-2.4l2.5-11.1h3.5l0.9,8.1
	l0,0l4.5-8.1h3.6L75.3,33H73z M86.4,28.2L86.4,28.2h-5.8L80,31h6.4l-0.5,2h-9l2.4-11.1h8.9L87.8,24h-6.3L81,26.3h5.8L86.4,28.2z
	 M93.7,26.5c1.8,0.5,3.1,1.1,3.1,3.1c0,2.7-2.5,3.7-4.7,3.7c-3.2,0-4.8-1.3-4.8-4h2.4c-0.1,1.6,1,2.1,2.5,2.1c1,0,2.2-0.5,2.2-1.6
	s-1.5-1.3-2.9-1.7c-1.5-0.4-2.9-1.1-2.9-3c0-2.5,2.4-3.5,4.6-3.5c2.4,0,4.4,1,4.4,3.6h-2.4c0-1.2-0.9-1.7-2.1-1.7c-1,0-2,0.4-2,1.4
	C91,26,92.9,26.3,93.7,26.5z"
      />
      <path
        className="st1"
        d="M58.2,23.7c-2.6,0-3.8,2.5-3.8,4.7c0,1.5,0.8,2.9,2.6,2.9c2.5,0,3.8-2.6,3.8-4.7C60.8,25,60.1,23.7,58.2,23.7
	"
      />
      <g>
        <path
          className="st1"
          d="M9.2,34.8h6.5c6-9.5,21.4-14.1,30.1-14.4C33.9,20.6,17.4,23.9,9.2,34.8z"
        />
        <path
          className="st1"
          d="M45.8,20.4c0.3,0,0.6,0,0.8,0C46.3,20.4,46,20.4,45.8,20.4z"
        />
        <path
          className="st1"
          d="M21.4,34.8h7.1c2.8-8.1,12.6-14.4,21-14.4C37.6,20.4,28,25,21.4,34.8z"
        />
      </g>
    </svg>
  </LogoWrapper>
);

FreedomLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  white: PropTypes.bool,
};

FreedomLogo.defaultProps = {
  height: 35,
  width: 107,
  white: false,
};

export default FreedomLogo;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from './CloseIcon';

const ChipStyled = styled.div`
  border-radius: 16.3px;
  border: 1px solid #f5f5f5;
  background-color: white;
  width: fit-content;
  box-sizing: border-box;
  height: 33px;

  @media only screen and (min-width: 1400px) {
    height: 40px !important;
    border-radius: 24px;
  }
  p {
    padding: 0px 5px 0px 10px;
    line-height: 30px;
    font-family: source-sans-pro;
    font-size: 14px;
    color: #8a8b8b;
    @media only screen and (min-width: 1400px) {
      line-height: 37px;
    }

    span {
      color: #414042;
      font-size: 14px;
      @media only screen and (min-width: 1400px) {
        font-size: 18px;
      }
    }
  }

  button {
    background: none;
    border: none;
    display: inline-block;
    @media only screen and (min-width: 1400px) {
      padding: 0px 7px;
    }

    &:focus {
      background: none;
    }
  }
`;

function Chip({ label, labelValue, onClose }) {
  return (
    <ChipStyled>
      <p>
        {`${label}\u00A0\u00A0\u00A0\u00A0`}
        <span>{labelValue}</span>
        <button
          onClick={onClose}
          type="button"
          className="close-filter-button"
          data-filter={label}
        >
          <CloseIcon />
        </button>
      </p>
    </ChipStyled>
  );
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  labelValue: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Chip;

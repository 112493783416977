import styled from 'styled-components';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'util';
import posed, { PoseGroup } from 'react-pose';
import { isIe } from '../../utils';
import MultiSelect from '../Inputs/MultiSelect';
import Toggle from '../Inputs/Toggle';
import RangeSlider from '../Inputs/RangeSlider';
import Select from '../Inputs/Select';

export const defaultFilters = {
  availability: 'On Display',
  isMultiSection: null,
  beds: {
    min: 0,
    max: 0,
    lowerLimit: 0,
    higherLimit: 0,
    isActive: false,
    label: 'Beds',
  },
  squareFeet: {
    min: 0,
    max: 0,
    higherLimit: 0,
    lowerLimit: 0,
    isActive: false,
    label: 'Sq. Ft.',
  },
  priceSort: {
    min: 0,
    max: 0,
    higherLimit: 0,
    lowerLimit: 0,
    isActive: false,
    label: 'Price',
  },
  homeBuilder: {
    selected: 'All Manufacturers',
    options: ['All Manufacturers'],
    isActive: false,
    label: 'Builder',
  },
};

export const filterHomes = (homes, filters) => {
  if (!homes || !homes.length) return null;
  return homes.filter(home => {
    const filtered = Object.entries(filters).map(([key, value]) => {
      // Ranges
      if (['beds', 'priceSort', 'squareFeet'].includes(key)) {
        return home[key] >= value.min && home[key] <= value.max;
      }
      // Availability
      if (key === 'availability') {
        return value === 'On Display'
          ? home.serialNumber !== null
          : home.serialNumber === null;
      }
      // Home Type
      if (key === 'isMultiSection') {
        return value === null ? true : home.isMultiSection === value;
      }
      // Home Builder
      if (key === 'homeBuilder') {
        if (value.selected === null || value.selected === 'All Manufacturers')
          return true;
        return home[key] === value.selected;
      }
      // anything else
      return (
        value &&
        (Array.isArray(value) ? value.includes(home[key]) : home[key] === value)
      );
    });

    if (filtered.indexOf(false) < 0) {
      return home;
    }
    return false;
  });
};

export const setStoredfilters = filters => {
  localStorage.setItem('filterValues', JSON.stringify(filters));
};

export const resetStoredfilters = () => {
  localStorage.removeItem('filterValues');
};

export const getFilterLimits = homes =>
  homes.reduce(
    (acc, prev) => {
      // Square Feet
      if (prev.squareFeet) {
        if (acc.squareFeet.min > prev.squareFeet) {
          acc.squareFeet.min = prev.squareFeet;
        }
        if (acc.squareFeet.max < prev.squareFeet) {
          acc.squareFeet.max = prev.squareFeet;
        }
      }
      // Price
      if (prev.priceSort) {
        if (prev.priceSort && acc.priceSort.min > prev.priceSort) {
          acc.priceSort.min = prev.priceSort < 20000 ? 20000 : 20000;
        }
        if (prev.priceSort && acc.priceSort.max < prev.priceSort) {
          acc.priceSort.max = prev.priceSort;
        }
      }
      // Beds
      if (prev.beds) {
        if (acc.beds.min > prev.beds) {
          acc.beds.min = prev.beds;
        }
        if (acc.beds.max < prev.beds) {
          acc.beds.max = prev.beds;
        }
      }
      // Not a limit but it seemed like a good place to put this
      if (prev.homeBuilder) {
        if (!acc.homeBuilders.includes(prev.homeBuilder)) {
          acc.homeBuilders.push(prev.homeBuilder);
        }
      }
      return acc;
    },
    {
      // Start with obsurdly high values
      squareFeet: { min: 100000000, max: 0 },
      priceSort: { min: 100000000, max: 0 },
      beds: { min: 100000000, max: 0 },
      homeBuilders: ['All Manufacturers'],
    }
  );

export const getInitialFilters = homes => {
  const limits = getFilterLimits(homes);
  const {
    squareFeet: { min: minSquareFeet, max: maxSquareFeet },
    priceSort: { min: minPriceSort, max: maxPriceSort },
    beds: { min: minBeds, max: maxBeds },
  } = defaultFilters;

  return {
    ...defaultFilters,
    priceSort: {
      higherLimit: limits.priceSort.max,
      lowerLimit: limits.priceSort.min || 20000,
      max: maxPriceSort || limits.priceSort.max,
      min: minPriceSort || limits.priceSort.min,
      isActive: false,
    },
    beds: {
      higherLimit: limits.beds.max,
      lowerLimit: limits.beds.min,
      max: maxBeds || limits.beds.max,
      min: minBeds || limits.beds.min,
      isActive: false,
    },
    squareFeet: {
      higherLimit: Math.ceil(limits.squareFeet.max / 100) * 100,
      lowerLimit: Math.floor(limits.squareFeet.min / 100) * 100,
      max: maxSquareFeet || Math.ceil(limits.squareFeet.max / 100) * 100,
      min: minSquareFeet || Math.floor(limits.squareFeet.min / 100) * 100,
      isActive: false,
    },
    homeBuilder: {
      selected: 'All Manufacturers',
      options: limits.homeBuilders.sort((a, b) => a.localeCompare(b)),
      isActive: false,
    },
  };
};

export const FilterStyled = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 0px 50px;
  margin-top: 40px;

  .filter-wrapper {
    margin-bottom: 50px;
  }
  @media all and (-ms-high-contrast: none) {
    *::-ms-backdrop,
    [data-aos^='fade'][data-aos^='fade'],
    [data-aos^='zoom'][data-aos^='zoom'] {
      opacity: 1 !important;
      transform: none !important;
    }
  }
`;

const FilterListPosed = posed.div({
  enter: {
    opacity: 1,
    delayChildren: 600,
  },
  exit: {
    opacity: 0,
  },
});

export const FilterWrapper = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: { duration: 500 },
  },
  exit: {
    y: isIe ? 0 : 50,
    opacity: isIe ? 1 : 0,
  },
});

class Filters extends PureComponent {
  static propTypes = {
    carouselStore: PropTypes.shape().isRequired,
    filterChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({
      availability: PropTypes.string.isRequired,
      isMultiSection: PropTypes.bool,
      priceSort: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        lowerLimit: PropTypes.number.isRequired,
        higherLimit: PropTypes.number.isRequired,
      }),
      squareFeet: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        lowerLimit: PropTypes.number.isRequired,
        higherLimit: PropTypes.number.isRequired,
      }),
      beds: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        lowerLimit: PropTypes.number.isRequired,
        higherLimit: PropTypes.number.isRequired,
      }),
      homeBuilder: PropTypes.shape({
        selected: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
      }).isRequired,
    }),
  };

  static defaultProps = {
    filters: null,
  };

  render() {
    const { filters, filterChange, carouselStore } = this.props;
    return (
      <FilterStyled>
        <PoseGroup animateOnMount>
          <FilterListPosed key={0}>
            <FilterWrapper className="filter-wrapper" key={0}>
              <Toggle
                label="Availability"
                id="availability"
                options={['On Display', 'Available Floor Plans']}
                selected={filters.availability}
                onChange={event => {
                  window.dataLayer.push({
                    event: 'interaction',
                    eventCategory: 'Filters',
                    eventAction: 'Availability',
                    eventLabel: event.target.value,
                  });
                  filterChange('availability', event.target.value);
                  carouselStore.setStoreState({
                    currentSlide: 0,
                  });
                }}
              />
            </FilterWrapper>
            <FilterWrapper className="filter-wrapper" key={1}>
              <MultiSelect
                options={['Single', 'Multi']}
                label="Home Type"
                id="isMultiSection"
                name="isMultiSection.value"
                selected={(() => {
                  if (filters) {
                    if (
                      isNull(filters.isMultiSection) ||
                      filters.isMultiSection === []
                    ) {
                      return ['Single', 'Multi'];
                    }
                    return filters.isMultiSection ? ['Multi'] : ['Single'];
                  }
                  return null;
                })()}
                onChange={event => {
                  const isMultiSection =
                    event.target.value.length === 1
                      ? event.target.value[0] === 'Multi'
                      : null;
                  let label;
                  if (isNull(isMultiSection)) {
                    label = 'Both';
                  } else {
                    label = isMultiSection ? 'Multi' : 'Single';
                  }
                  window.dataLayer.push({
                    event: 'interaction',
                    eventCategory: 'Filters',
                    eventAction: 'Home Type',
                    eventLabel: label,
                  });
                  filterChange('isMultiSection', isMultiSection);
                  carouselStore.setStoreState({
                    currentSlide: 0,
                  });
                }}
              />
            </FilterWrapper>
            <FilterWrapper className="filter-wrapper" key={2}>
              <RangeSlider
                label="Price"
                min={parseFloat(filters.priceSort.lowerLimit)}
                max={parseFloat(filters.priceSort.higherLimit)}
                initialMin={filters.priceSort.min}
                initialMax={filters.priceSort.max}
                step={10000}
                tipFormatter={value => `$${value.toLocaleString()}`}
                onChange={([min, max]) => {
                  if (
                    filters.priceSort.min !== min ||
                    filters.priceSort.max !== max
                  ) {
                    if (filters.priceSort.min !== min) {
                      window.dataLayer.push({
                        event: 'interaction',
                        eventCategory: 'Filters',
                        eventAction: 'Price',
                        eventLabel: 'Set Minimum',
                      });
                    }
                    if (filters.priceSort.max !== max) {
                      window.dataLayer.push({
                        event: 'interaction',
                        eventCategory: 'Filters',
                        eventAction: 'Price',
                        eventLabel: 'Set Maximum',
                      });
                    }
                    if (
                      max === filters.priceSort.higherLimit &&
                      min === filters.priceSort.lowerLimit
                    ) {
                      filterChange('priceSort', {
                        ...filters.priceSort,
                        min: filters.priceSort.lowerLimit,
                        max: filters.priceSort.higherLimit,
                        isActive: false,
                        label: 'Price',
                      });
                    } else {
                      filterChange('priceSort', {
                        ...filters.priceSort,
                        min,
                        max,
                        isActive: true,
                        label: 'Price',
                      });
                      carouselStore.setStoreState({
                        currentSlide: 0,
                      });
                    }
                  }
                }}
              />
            </FilterWrapper>
            <FilterWrapper className="filter-wrapper" key={3}>
              <RangeSlider
                label="Square Feet"
                min={parseFloat(filters.squareFeet.lowerLimit) || 1}
                max={parseFloat(filters.squareFeet.higherLimit) || 100001}
                initialMin={filters.squareFeet.min}
                initialMax={filters.squareFeet.max}
                step={100}
                tipFormatter={value => value.toLocaleString()}
                onChange={([min, max]) => {
                  if (
                    filters.squareFeet.min !== min ||
                    filters.squareFeet.max !== max
                  ) {
                    if (filters.squareFeet.min !== min) {
                      window.dataLayer.push({
                        event: 'interaction',
                        eventCategory: 'Filters',
                        eventAction: 'Square Feet',
                        eventLabel: 'Set Minimum',
                      });
                    }
                    if (filters.squareFeet.max !== max) {
                      window.dataLayer.push({
                        event: 'interaction',
                        eventCategory: 'Filters',
                        eventAction: 'Square Feet',
                        eventLabel: 'Set Maximum',
                      });
                    }
                    if (
                      min === filters.squareFeet.lowerLimit &&
                      max === filters.squareFeet.higherLimit
                    ) {
                      filterChange('squareFeet', {
                        ...filters.squareFeet,
                        min: filters.squareFeet.lowerLimit,
                        max: filters.squareFeet.higherLimit,
                        isActive: false,
                        label: 'Sq. Ft.',
                      });
                    } else {
                      filterChange('squareFeet', {
                        ...filters.squareFeet,
                        min,
                        max,
                        isActive: true,
                        label: 'Sq. Ft.',
                      });
                      carouselStore.setStoreState({
                        currentSlide: 0,
                      });
                    }
                  }
                }}
              />
            </FilterWrapper>
            <FilterWrapper className="filter-wrapper" key={4}>
              <RangeSlider
                label="Beds"
                min={parseFloat(filters.beds.lowerLimit) || 1}
                max={parseFloat(filters.beds.higherLimit) || 100001}
                initialMin={filters.beds.min}
                initialMax={filters.beds.max}
                onChange={([min, max]) => {
                  if (filters.beds.min !== min || filters.beds.max !== max) {
                    if (filters.beds.min !== min) {
                      window.dataLayer.push({
                        event: 'interaction',
                        eventCategory: 'Filters',
                        eventAction: 'Beds',
                        eventLabel: 'Set Minimum',
                      });
                    }
                    if (filters.beds.max !== max) {
                      window.dataLayer.push({
                        event: 'interaction',
                        eventCategory: 'Filters',
                        eventAction: 'Beds',
                        eventLabel: 'Set Maximum',
                      });
                    }
                    if (
                      min === filters.beds.lowerLimit &&
                      max === filters.beds.higherLimit
                    ) {
                      filterChange('beds', {
                        ...filters.beds,
                        min: filters.beds.lowerLimit,
                        max: filters.beds.higherLimit,
                        isActive: false,
                        label: 'Beds',
                      });
                    } else {
                      filterChange('beds', {
                        ...filters.beds,
                        min,
                        max,
                        isActive: true,
                        label: 'Beds',
                      });
                      carouselStore.setStoreState({
                        currentSlide: 0,
                      });
                    }
                  }
                }}
              />
            </FilterWrapper>
            <FilterWrapper className="filter-wrapper" key={5}>
              <Select
                label="Home Builder"
                id="builder"
                options={filters.homeBuilder.options}
                selected={filters.homeBuilder.selected}
                onChange={event => {
                  window.dataLayer.push({
                    event: 'interaction',
                    eventCategory: 'Filters',
                    eventAction: 'Builder',
                    eventLabel: event.target.value,
                  });
                  if (event.target.value === 'All Manufacturers') {
                    filterChange('homeBuilder', {
                      options: filters.homeBuilder.options,
                      selected: event.target.value,
                      isActive: false,
                      label: 'Builder',
                    });
                    carouselStore.setStoreState({
                      currentSlide: 0,
                    });
                  } else {
                    filterChange('homeBuilder', {
                      options: filters.homeBuilder.options,
                      selected: event.target.value,
                      isActive: true,
                      label: 'Builder',
                    });
                    carouselStore.setStoreState({
                      currentSlide: 0,
                    });
                  }
                }}
              />
            </FilterWrapper>
          </FilterListPosed>
        </PoseGroup>
      </FilterStyled>
    );
  }
}

export default Filters;

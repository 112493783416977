import styled from 'styled-components';

const StyledMultiSelect = styled.div`
  width: 100%;
  height: auto;
  display: block;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 10px 0 0 0;
    height: auto;
    transition: max-height 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    max-height: 150px;
    overflow: hidden;
    float: left;
    clear: both;
    li {
      float: left;
      padding-right: 8px;
      color: #6d6e71;
      div {
        border-radius: 20px;
      }
      button {
        border-radius: 19px;
        border: none;
        background-color: white;
        border: 1px #d6d6d6 solid;
        padding: 8px 18px;
        transition: background-color cubic-bezier(0, 0.64, 0.34, 1.08) 0.75s;
      }
    }
  }
  .selected {
    color: white;
    background-color: #0075c9;
  }

  .accordion-open {
    max-height: 0px;
  }
  .collapse-button {
    background: none;
    border: none;
    padding: 3px 8px;

    img {
      transition: transform 0.2s linear;
    }
  }

  .open {
    transform: rotate(-180deg);
  }
`;

export default StyledMultiSelect;

import styled from 'styled-components';

const StyledDetails = styled.div`
  display: flex;
  height: 100vh;
  min-height: 750px;
  aside {
    display: flex;
    :first-child {
      flex: 0;
    }
  }
  .details-contain {
    margin-left: 8%;
    display: flex;
    flex: 1;
    flex-direction: column;
    .home-listing-link {
      text-decoration: none;
      color: #0075c9;
      font-family: source-sans-pro;
      font-size: 14px;
      margin-bottom: 25px;
      display: block;
      svg {
        margin-right: 10px;
      }
    }
    .sticker-wrapper {
      display: inline-block;
    }
    .building-facility {
      padding-top: 40px;
      border-top: 1px solid #ececec;
      flex: 1;
      @media only screen and (min-width: 1400px) {
        padding-top: 55px;
      }

      img {
        padding-top: 12px;
        height: 45px;
        padding-bottom: 12px;
        @media only screen and (max-width: 1400px) {
          width: 120px;
          height: auto;
        }
      }

      a {
        float: left;
        clear: both;
      }

      .home-builder-name {
        margin-top: 10px;
      }
    }
    .energy-smart-disclaimer {
      .disclaimer-button {
        background: transparent;
        border: none;
        padding: 0;
        overflow: hidden;

        .disclaimer-button-text {
          font-family: source-sans-pro;
          font-size: 12px;
          color: #959595;
          text-decoration: underline;
        }
      }

      @media only screen and (max-width: 1400px) {
        img {
          width: 133px;
          height: auto;
        }
      }

      .light-disclaimer {
        color: #959595;
        font-family: acumin pro;
        font-size: 12px;
        padding-top: 20px;
      }
    }
  }
  div[role='main'] {
    flex: 1;
    background: #fcfcfc;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    .carousel-wrapper {
      width: 75%;
      margin: 20px auto;
      flex: 1 0 55%;
      max-height: 55%;
      overflow: hidden;
    }
    .model_items {
      flex: 0 1 40%;
      max-height: 40%;
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      button {
        background: transparent;
        border: none;
        padding: 0;
        flex: 1 1 50%;
        max-width: 580px;
        margin: 20px;
        overflow: hidden;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.11);
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
      .model-item {
        background: #fff;
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        /* box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.11); */
        img {
          max-height: 100%;
          max-width: 100%;
          flex: 0;
        }
      }
    }
  }
  .modal-contents {
    background: none;
  }

  .full-disclaimer {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    letter-spacing: -0.29px;
    padding: 50px;
    max-width: 900px;
    background: #ffffff;
  }

  .model-item {
    img {
      width: 100%;
      max-width: 1000px;
    }
  }

  iframe {
    position: relative;
    width: 80vw;
    height: 90vh;
  }

  .vt-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .vt-button {
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.11);
    margin: 20px;
    max-width: 580px;
    position: relative;
    flex: 1;
    #vt {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      z-index: 99;
    }
  }

  .vt-button,
  .fp-button {
    * {
      pointer-events: none;
    }
  }
`;

export default StyledDetails;

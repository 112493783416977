import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import posed from 'react-pose';
import Modal from '../../Modal/Modal';
import StyledCarousel from './DetailsCarousel.Style';
import ArrowLeft from '../../../images/ArrowLeft';
import ArrowRight from '../../../images/ArrowRight';

import 'pure-react-carousel/dist/react-carousel.es.css';

const ButtonBackPosed = posed.div({
  pressable: true,
  init: { left: 0 },
  press: { left: -20, duration: 25 },
  transition: { left: 'spring' },
});

const ButtonNextPosed = posed.div({
  pressable: true,
  init: { right: 0 },
  press: { right: -20, duration: 25 },
  transition: { right: 'spring' },
});

class DetailsCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibility: false,
    };

    this.showVisibility = this.showVisibility.bind(this);
    this.hideVisibility = this.hideVisibility.bind(this);
  }

  showVisibility() {
    this.setState({ visibility: true });
  }

  hideVisibility() {
    this.setState({ visibility: false });
  }

  render() {
    const { visibility } = this.state;
    const { images } = this.props;
    return (
      <StyledCarousel>
        <CarouselProvider
          totalSlides={images ? images.length : 1}
          naturalSlideHeight={550}
          naturalSlideWidth={1000}
        >
          <Slider className="slider home-image-slider">
            {images.map((image, i) => (
              <Slide
                className="slide"
                index={i}
                key={image}
                onClick={this.showVisibility}
              >
                <img src={`${image}?width=850&flatten=true`} alt="home" />
              </Slide>
            ))}
          </Slider>
          <Modal
            isVisible={visibility}
            modalSize="full"
            onCloseClicked={this.hideVisibility}
            showCloseButton
          >
            <div className="carousel-modal">
              <Slider className="slider modal-slider">
                {images.map((imageInner, n) => (
                  <Slide key={imageInner} index={n} className="slide">
                    <img src={`${imageInner}?flatten=true`} alt="home" />
                  </Slide>
                ))}
              </Slider>
              <div className="modal-buttons">
                <ButtonBack className="button-back home-image-modal-back">
                  <ArrowLeft width={21} height={18} />
                </ButtonBack>
                <ButtonNext className="button-next home-image-modal-next">
                  <ArrowRight width={21} height={18} />
                </ButtonNext>
              </div>
            </div>
          </Modal>
          <ButtonBackPosed className="position-button-back">
            <ButtonBack className="button-back home-image-carousel-back">
              <ArrowLeft width={21} height={18} />
            </ButtonBack>
          </ButtonBackPosed>
          <ButtonNextPosed className="position-button-next">
            <ButtonNext className="button-next home-image-carousel-next">
              <ArrowRight width={21} height={18} />
            </ButtonNext>
          </ButtonNextPosed>
        </CarouselProvider>
      </StyledCarousel>
    );
  }
}

DetailsCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DetailsCarousel;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StyledWelcome from './Welcome.Style';
import TitleBar from '../TitleBar';
import HometouchLogo from './HometouchLogo';
import splashImage from '../../images/hometouch-splashpage.jpg';

export default class Welcome extends PureComponent {
  static propTypes = {
    dealer: PropTypes.shape(),
  };

  static defaultProps = {
    dealer: null,
  };

  render() {
    const { dealer } = this.props;
    return (
      <StyledWelcome background={splashImage}>
        <header>
          {dealer &&
            dealer.brandAcronym &&
            dealer.brandAcronym !== 'IND' && (
              <TitleBar
                white
                dealerId={dealer.dealerId}
                brand={dealer.brandAcronym}
                name={dealer.dealerName}
              />
            )}
        </header>
        <div role="main">
          <HometouchLogo />
          {dealer &&
            dealer.brandAcronym &&
            dealer.brandAcronym !== 'IND' && (
              <Link to={`/${dealer.dealerId}/listing`} id="get-started-link">
                <span className="cta">Get Started</span>
              </Link>
            )}
        </div>
      </StyledWelcome>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledSidebar from './Sidebar.Style';

const Overlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  transition: all 0.2s;
  z-index: ${({ zIndex }) => zIndex};
  background: ${({ background }) => background};
  visibility: ${({ visibility }) => visibility};
`;

class Sidebar extends Component {
  state = {
    collapsed: true,
  };

  static propTypes = {
    isCollapsible: PropTypes.bool,
    children: PropTypes.node,
  };

  static defaultProps = {
    isCollapsible: false,
    children: '',
  };

  toggleSidebar = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  };

  render() {
    const { isCollapsible, children } = this.props;
    const { collapsed: collapsedState } = this.state;
    const collapsed = isCollapsible && collapsedState;

    return (
      <>
        <Overlay
          onClick={this.toggleSidebar}
          zIndex={!isCollapsible || collapsed ? '-50' : '50'}
          background={
            !isCollapsible || collapsed
              ? 'rgba(0, 0, 0, 0.0)'
              : 'rgba(0, 0, 0, 0.5)'
          }
        />
        <StyledSidebar
          position={isCollapsible ? 'absolute' : 'relative'}
          transform={collapsed ? 'translateX(-100%)' : 'none'}
        >
          {children}
          {isCollapsible && (
            <div className={`sidebar-toggle${collapsed ? ' collapsed' : ''}`}>
              <button type="button" onClick={this.toggleSidebar}>
                Filter <span className="arrow" />
              </button>
            </div>
          )}
        </StyledSidebar>
      </>
    );
  }
}

export default Sidebar;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  .st0 {
    fill: ${props => (props.white ? '#ffffff' : '#1A1919')};
  }
  .st1 {
    fill: ${props => (props.white ? '#ffffff' : '#FFFFFF')};
  }
  .st2 {
    fill: ${props => (props.white ? '#ffffff' : '#004b8a')};
  }
  .st3 {
    fill: ${props => (props.white ? '#ffffff' : '#1A1919')};
  }
  .st4 {
    fill: ${props => (props.white ? '#ffffff' : '#952738')};
  }
`;

const CrosslandLogo = ({ width, height, white }) => (
  <LogoWrapper white={white}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      heigh={height}
      viewBox="0 0 109 32"
      xmlSpace="preserve"
    >
      <path
        className="st0"
        d="M58.8,18.1H55l2,4.6L58.8,18.1 M48.1,19c-0.4,0.6-0.7,1.4-0.7,2.4c0,0.9,0.2,1.7,0.5,2.2
	c0.4,0.6,0.9,0.9,1.6,0.9c0.4,0,0.7-0.1,1.1-0.3c0.6-0.5,1-1.4,1-2.8c0-0.8-0.1-1.5-0.4-2c-0.3-0.7-0.9-1-1.6-1.1
	C48.9,18.3,48.4,18.6,48.1,19 M44.1,18.1h-2.9v3h2.9V18.1"
      />
      <path
        className="st3"
        d="M69.1,18.2c0.3,0.2,0.6,0.5,0.8,0.9l-0.4,0.3c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.3-0.6-0.4-0.9-0.4
	c-0.4,0-0.7,0.1-0.9,0.4C67.1,19,67,19.3,67,19.6s0.1,0.6,0.4,0.8c0.2,0.1,0.4,0.3,0.8,0.4c0.6,0.2,1,0.5,1.3,0.7
	c0.4,0.4,0.7,0.8,0.7,1.5c0,0.6-0.2,1.1-0.6,1.5s-1,0.6-1.7,0.6c-0.9,0-1.6-0.4-2.1-1.1l0.5-0.4c0.4,0.6,0.9,1,1.4,1
	c0.4,0,0.7-0.2,1-0.5c0.2-0.2,0.3-0.5,0.3-0.8c0-0.5-0.3-0.9-0.8-1.2l-1-0.4c-0.4-0.2-0.7-0.4-0.9-0.6c-0.3-0.3-0.4-0.7-0.4-1.2
	c0-0.7,0.2-1.2,0.7-1.6c0.1-0.1,0.2-0.1,0.3-0.2h-1.5v0.4h-2.8v2.6H65v0.5h-2.7v2.8h2.9v0.5h-4.1v-6.7h-0.9v6.7H59v-5.6l-2.3,5.9
	l-2.5-6v5.6h-0.6v-6.7h-2.8c0.8,0.3,1.5,1,1.8,1.8c0.2,0.5,0.3,1,0.3,1.6c0,1-0.3,1.9-1,2.5c-0.7,0.7-1.5,1-2.5,1
	c-0.6,0-1.3-0.2-1.8-0.5c-0.6-0.3-1-0.8-1.3-1.4C46.1,22.6,46,22,46,21.4c0-0.7,0.2-1.3,0.5-1.9c0.4-0.7,1-1.2,1.7-1.4h-2.9v6.8H44
	v-3.3h-2.9v3.3H40V18h-9.6c-0.2,0,8.3,11.2,24.4,10.8c16.1-0.3,23.9-10.5,23.9-10.6L69.1,18.2"
      />
      <g>
        <path
          className="st4"
          d="M99.1,0.1H9.2C4.2,0.1,0,4.3,0,9.4v1.2c0,5.1,4.2,9.2,9.2,9.2h17.6c6.3,7.3,16.2,12.1,27.4,12.1
		s21.1-4.8,27.2-12.1H99c5.1,0,9.2-4.1,9.2-9.2V9.4C108.4,4.3,104.2,0.1,99.1,0.1z M106.6,10.6c0,4.1-3.4,7.5-7.5,7.5H79.7v0.1
		C74,25.1,64.8,29.7,54.4,29.7c-14,0-25.9-8.2-29.9-19.7l0,0c0.2,0,0.8-0.3,1.2-0.7c0.7-0.6,1-1.3,1-2.3c0-0.6-0.1-1.1-0.4-1.6
		c-0.7-1.3-2.1-1.9-4.2-1.9h-4.6V16h2.3V4.1c0.3,0,0.6,0,0.9,0c0.8,0,1.5,0.1,1.9,0.2c0.6,0.2,1,0.5,1.3,1s0.4,1,0.4,1.6
		c0,0.8-0.2,1.4-0.7,1.9s-1.2,0.7-2,0.8c0,0-0.2,0-0.5,0l0,0l0,0c0.9,3.1,2.4,5.9,4.3,8.5H9.2c-4.1,0-7.5-3.4-7.5-7.5V9.4
		c0-4.1,3.4-7.5,7.5-7.5h89.9c4.1,0,7.5,3.4,7.5,7.5V10.6z"
        />
        <path
          className="st4"
          d="M11.4,15.4c-0.7,0-1.4-0.2-2-0.5C7.7,14,6.9,12.3,6.9,9.8c0-2.3,0.7-3.9,2-4.9C9.7,4.3,10.6,4,11.6,4
		c1.6,0,2.9,0.7,3.8,2.2l0.8-0.5c-1.3-1.8-3-2.6-5.1-2.6C9.5,3.1,8,3.6,6.7,4.7C5.2,6,4.4,7.7,4.4,9.8c0,1.1,0.2,2,0.6,2.9
		c0.5,1.1,1.4,2,2.4,2.7c1,0.6,2.3,1,3.5,1c1.5,0,2.8-0.5,4-1.4c0.6-0.5,1.1-1.1,1.5-1.9l-0.8-0.5C14.7,14.5,13.3,15.4,11.4,15.4z"
        />
        <path
          className="st4"
          d="M69.1,16h1.1l1.3-3.3H76l1.4,3.3h2.4L74.3,2.9L69.1,16z M71.9,11.8l1.9-4.7l2,4.7H71.9z"
        />
        <path
          className="st4"
          d="M106.7,2.2c0.6,0,1-0.5,1-1c0-0.6-0.4-1-1-1s-1,0.5-1,1S106,2.2,106.7,2.2z M106.7,0.3
		c0.5,0,0.8,0.4,0.8,0.8c0,0.5-0.3,0.8-0.8,0.8l0,0c-0.5,0-0.8-0.4-0.8-0.8C105.9,0.6,106.2,0.3,106.7,0.3z"
        />
        <polygon
          className="st4"
          points="89.4,12.1 82.7,3.4 80.5,3.4 80.5,16 81.6,16 81.6,5.9 89.4,16 90.5,16 90.5,3.4 89.4,3.4 	"
        />
        <polygon
          className="st4"
          points="63.5,3.4 61.2,3.4 61.2,16 68.7,16 68.7,15.1 63.5,15.1 	"
        />
        <path
          className="st4"
          d="M96.8,3.4h-4V16h4.8c2.4,0,4.2-0.9,5.3-2.6c0.7-1,1-2.1,1-3.4c0-2-0.7-3.6-2.2-5
		C100.5,3.9,98.9,3.4,96.8,3.4z M99.9,14.2c-0.7,0.6-1.8,1-3.3,1c-0.4,0-0.9,0-1.5-0.1V4.2c0.5,0,0.9,0,1.2,0c1.8,0,3.2,0.6,4,1.8
		c0.7,1.1,1.1,2.4,1.1,4C101.5,11.9,101,13.3,99.9,14.2z"
        />
        <path
          className="st4"
          d="M37.9,4.1c-1-0.7-2.2-1-3.4-1c-1,0-1.9,0.2-2.8,0.6c-1.1,0.5-2,1.4-2.7,2.5s-1,2.3-1,3.6
		c0,1.1,0.2,2.1,0.7,3c0.5,1.1,1.3,2,2.4,2.6c1,0.6,2.2,0.9,3.4,0.9c1.8,0,3.3-0.6,4.6-1.9c1.2-1.2,1.8-2.8,1.8-4.7
		c0-1.1-0.2-2-0.6-2.9C39.7,5.7,38.9,4.7,37.9,4.1z M36.4,14.8L36.4,14.8c-0.6,0.4-1.2,0.6-2,0.6c-1.2,0-2.2-0.5-2.9-1.6
		c-0.6-1-1-2.4-1-4.1c0-1.9,0.4-3.4,1.2-4.5c0.6-0.9,1.5-1.3,2.6-1.3c1.4,0,2.4,0.7,3,2c0.5,1,0.8,2.2,0.8,3.7
		C38.2,12.2,37.6,13.9,36.4,14.8z"
        />
        <path
          className="st4"
          d="M58.3,9.7L58.3,9.7c-0.5-0.4-1.4-0.9-2.5-1.3c-0.7-0.3-1.2-0.6-1.5-0.8c-0.5-0.4-0.7-0.9-0.7-1.5
		s0.2-1,0.5-1.4c0.4-0.5,1-0.7,1.7-0.7s1.3,0.2,1.8,0.7c0.3,0.3,0.5,0.7,0.8,1.2l0.8-0.5c-0.8-1.5-2.1-2.2-3.9-2.2
		c-1.1,0-2,0.3-2.7,0.9c-0.9,0.7-1.3,1.7-1.3,2.9c0,0.9,0.3,1.6,0.8,2.2c0.4,0.4,0.9,0.7,1.6,1l1.8,0.8c1,0.6,1.6,1.3,1.6,2.2
		c0,0.5-0.2,1-0.5,1.4c-0.4,0.6-1.1,0.9-1.8,0.9c-1.1,0-2-0.6-2.7-1.8l-0.9,0.7c1,1.3,2.3,2,3.9,2c1.3,0,2.4-0.4,3.2-1.2
		c0.8-0.7,1.2-1.6,1.2-2.8C59.5,11.3,59.1,10.4,58.3,9.7z"
        />
        <path
          className="st4"
          d="M48.7,9.7c-0.5-0.4-1.4-0.9-2.5-1.3c-0.7-0.3-1.2-0.6-1.5-0.8C44.2,7.2,44,6.7,44,6.1s0.2-1,0.5-1.4
		C45,4.2,45.6,4,46.3,4s1.3,0.2,1.7,0.7c0.3,0.3,0.5,0.7,0.8,1.2l0.8-0.5c-0.8-1.5-2.1-2.2-3.9-2.2c-1.1,0-2,0.3-2.7,0.9
		c-0.9,0.7-1.3,1.7-1.3,2.9c0,0.9,0.3,1.6,0.8,2.2c0.4,0.4,0.9,0.7,1.6,1c1.2,0.5,1.7,0.8,1.8,0.8c1,0.6,1.6,1.3,1.6,2.2
		c0,0.5-0.2,1-0.5,1.4c-0.4,0.6-1.1,0.9-1.8,0.9c-1.1,0-2-0.6-2.7-1.8l-0.9,0.7c1,1.3,2.3,2,3.9,2c1.3,0,2.4-0.4,3.2-1.2
		c0.8-0.7,1.2-1.6,1.2-2.8C50,11.3,49.6,10.4,48.7,9.7z"
        />
        <path
          className="st4"
          d="M106.5,1.3h0.2l0.2,0.3h0.2l0,0l-0.2-0.3l0,0l0,0c0.1,0,0.2-0.2,0.2-0.3c0-0.2-0.1-0.3-0.4-0.3h-0.4v0.9h0.2
		L106.5,1.3L106.5,1.3z M106.5,0.8h0.2c0.1,0,0.2,0.1,0.2,0.2s-0.1,0.2-0.2,0.2h-0.2V0.8z"
        />
      </g>
    </svg>
  </LogoWrapper>
);

CrosslandLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  white: PropTypes.bool,
};

CrosslandLogo.defaultProps = {
  height: 32,
  width: 109,
  white: false,
};

export default CrosslandLogo;

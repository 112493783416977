import React from 'react';
import PropTypes from 'prop-types';

const ArrowRight = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 13 13"
  >
    <path
      fill="#0075C9"
      fillRule="evenodd"
      d="M6.788 4.76V.679l6.294 5.958-6.294 5.958V8.51H.39V4.76h6.398z"
    />
  </svg>
);

ArrowRight.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowRight.defaultProps = {
  width: 13,
  height: 13,
};

export default ArrowRight;

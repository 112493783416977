import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const StyledRange = styled.div`
  h3 {
    font-family: source-sans-pro;
    font-size: 16px;
    color: #6d6e71;
    font-weight: bold;
    margin-bottom: 9px;
  }
  .values {
    display: block;
    margin-bottom: 5px;
    color: #6d6e71;
    font-size: 14px;
    letter-spacing: -0.3px;
    font-family: source-sans-pro;
  }
  .rc-slider-handle {
    padding: 4px;
    margin-top: -8px;
  }
`;

const sliderStyles = {
  railStyle: {
    backgroundColor: '#afd0e7',
  },
  trackStyle: [
    {
      backgroundColor: '#0075c9',
      height: '4px',
    },
  ],
  handleStyle: [
    {
      height: '16px',
      width: '16px',
      border: 'solid 6px #003f8c',
    },
  ],
};

class RangeSlider extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    initialMin: PropTypes.number,
    initialMax: PropTypes.number,
    onChange: PropTypes.func,
    tipFormatter: PropTypes.func,
  };

  static defaultProps = {
    step: 1,
    initialMin: 1,
    initialMax: 10000000,
    tipFormatter: value => value,
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      min: props.min > props.initialMin ? props.min : props.initialMin,
      max: props.max < props.initialMax ? props.max : props.initialMax,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      min: props.min > props.initialMin ? props.min : props.initialMin,
      max: props.max < props.initialMax ? props.max : props.initialMax,
    });
  }

  render() {
    const {
      label,
      min,
      max,
      initialMin,
      initialMax,
      step,
      onChange,
      tipFormatter,
    } = this.props;
    const { min: currentMin, max: currentMax } = this.state;
    return (
      <StyledRange>
        <h3>{label}</h3>
        <span className="values">
          {`${tipFormatter(currentMin)} - ${tipFormatter(currentMax)}`}
        </span>
        <Range
          min={min}
          max={max}
          value={[currentMin, currentMax]}
          defaultValue={[initialMin, initialMax]}
          step={step}
          tipFormatter={value => tipFormatter(value)}
          tipProps={{ placement: 'bottom' }}
          onChange={([newMin, newMax]) => {
            this.setState({
              min: newMin,
              max: newMax,
            });
          }}
          onAfterChange={values => {
            // fires on mouseup/touchend
            onChange(values);
          }}
          {...sliderStyles}
        />
      </StyledRange>
    );
  }
}

export default RangeSlider;

import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeft = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 13 13"
  >
    <path
      fill="#0075C9"
      fillRule="evenodd"
      d="M6.655 8.24v4.082L.361 6.364 6.655.406V4.49h6.398v3.75H6.655z"
    />
  </svg>
);

ArrowLeft.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowLeft.defaultProps = {
  width: 13,
  height: 13,
};

export default ArrowLeft;

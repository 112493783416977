import React from 'react';
import PropTypes from 'prop-types';

function MinimalArrowIcon({ color, width, height, rotate }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 9 6"
      transform={`rotate(${rotate})`}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.698 4.046L1.588.779a.191.191 0 0 0-.282.004l-.547.584a.226.226 0 0 0-.003.303l3.798 3.99c.039.043.093.062.144.058a.188.188 0 0 0 .145-.058l3.8-3.99a.226.226 0 0 0-.004-.303L8.093.783A.191.191 0 0 0 7.81.78L4.698 4.046z"
      />
    </svg>
  );
}

MinimalArrowIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  rotate: PropTypes.number,
};

MinimalArrowIcon.defaultProps = {
  color: '#0075C9',
  height: 5,
  width: 9,
  rotate: 0,
};

export default MinimalArrowIcon;

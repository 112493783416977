import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Ripples from 'react-ripples';
import StyledMultiSelect from './MultiSelect.Style';
import Header from '../Typography/Header';

export default class MultiSelect extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    isCollapsed: PropTypes.bool,
  };

  static defaultProps = {
    isCollapsed: false,
  };

  constructor(props) {
    super(props);

    const { selected, isCollapsed } = this.props;

    this.state = {
      selected,
      isCollapsed,
    };

    this.setSelected = this.setSelected.bind(this);
    this.collapse = this.collapse.bind(this);
  }

  componentWillReceiveProps(props) {
    const { selected } = props;
    this.setState({
      selected,
    });
  }

  setSelected(event) {
    const { onChange, name } = this.props;
    const { selected } = this.state;

    let newSelect = [];
    if (selected.includes(event.target.value)) {
      newSelect = selected.filter(item => event.target.value !== item);
    } else {
      selected.push(event.target.value);
      newSelect = selected;
    }

    this.setState({
      selected: newSelect,
    });
    // return list of items?
    const multiEvent = {
      target: {
        id: event.target.id,
        value: newSelect,
        name,
      },
    };
    onChange(multiEvent);
  }

  collapse() {
    const { isCollapsed } = this.state;
    this.setState({
      isCollapsed: !isCollapsed,
    });
  }

  render() {
    const { selected, isCollapsed } = this.state;
    const { options, label, id, name } = this.props;

    const optionsToSelect = options.map(item => (
      <li key={item}>
        <Ripples color="rgba(0, 104, 179, .5)" during={750}>
          <button
            className={` ${selected.includes(item) ? 'selected' : ''}`}
            onClick={this.setSelected}
            type="button"
            id={id}
            name={name}
            value={item}
          >
            {item}
          </button>
        </Ripples>
      </li>
    ));

    return (
      <StyledMultiSelect>
        <div className="input-header">
          <Header headerType="h3" isBold>
            {label}
          </Header>
        </div>
        <button
          className="collapse-button"
          type="button"
          onClick={this.collapse}
        >
          {/* <img
            src={arrow}
            alt="collapse arrow"
            className={isCollapsed ? 'close' : 'open'}
          /> */}
        </button>
        <ul className={isCollapsed ? 'accordion-open' : ''}>
          {optionsToSelect}
        </ul>
      </StyledMultiSelect>
    );
  }
}

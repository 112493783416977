import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import posed from 'react-pose';
import ClaytonHomesLogo from '../images/ClaytonHomesLogo';
import CrosslandLogo from '../images/FOBLogos/CrosslandLogo';
import FreedomLogo from '../images/FOBLogos/FreedomLogo';
import GILogo from '../images/FOBLogos/G&ILogo';
import InternationalLogo from '../images/FOBLogos/InternationalLogo';
import LuvLogo from '../images/FOBLogos/LuvLogo';
import OakwoodLogo from '../images/FOBLogos/OakwoodLogo';
import ScotbiltLogo from '../images/FOBLogos/ScotbiltLogo';
import TruValueLogo from '../images/FOBLogos/TruValueLogo';
import RefreshIcon from '../images/ResfreshIcon';

export const RefreshPosed = posed.button({
  pressable: true,
  init: { rotate: 0 },
  press: {
    rotate: 360,
    transition: { duration: 300, ease: 'linear' },
  },
});

const getDealerBrandLogo = (brandAcronym, white) => {
  switch (brandAcronym) {
    case 'CLA':
      return <ClaytonHomesLogo width={90} height={40} white={white} />;
    case 'CSL':
      return <CrosslandLogo width={100} height={40} white={white} />;
    case 'FRE':
      return <FreedomLogo white={white} />;
    case 'GNI':
      return <GILogo width={70} height={70} white={white} />;
    case 'INT':
      return <InternationalLogo width={150} height={50} white={white} />;
    case 'LUV':
      return <LuvLogo white={white} />;
    case 'OAK':
      return <OakwoodLogo white={white} />;
    case 'SCO':
      return <ScotbiltLogo white={white} />;
    case 'TRU':
      return <TruValueLogo white={white} />;
    default:
      return false;
  }
};

const titleCase = str =>
  str.replace(
    /\b\w+/g,
    string => string.charAt(0).toUpperCase() + string.substr(1).toLowerCase()
  );

const formatDealerName = name =>
  titleCase(name.toLowerCase()).replace('-', ' of ');

const StyledTitleBar = styled.div`
  position: relative;
  a {
    display: flex;
    flex-flow: row wrap;
    padding: 25px;
    align-items: center;
    min-height: 50px;
    border-bottom: ${props => (props.white ? 'none' : '1px solid #ececec')};
    margin-bottom: ${props => (props.white ? '0' : '30px')};
    text-decoration: none;
  }
  .separator {
    align-self: stretch;
    padding-right: 25px;
    margin-right: 25px;
    border-right: 1px solid ${props => (props.white ? '#fff' : '#ececec')};
  }
  p {
    color: ${props => (props.white ? '#fff' : '#6d6e71')};
    font-family: 'source-sans-pro';
  }

  .refresh-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 25px;
    margin-top: -15px;
    height: 100%;
    &:focus {
      border: none;
      outline: none;
    }
  }
`;

function TitleBar(props) {
  const { dealerId, brand, name, white, className, refreshCache } = props;
  return (
    <StyledTitleBar white={white} className={className}>
      <Link to={`/${dealerId}`} className="title-bar-link">
        {getDealerBrandLogo(brand, white)}
        <div className="separator" />
        <p>{formatDealerName(name)}</p>
      </Link>
      {refreshCache && (
        <RefreshPosed
          type="button"
          onClick={refreshCache}
          className="refresh-button"
          loading
        >
          <RefreshIcon />
        </RefreshPosed>
      )}
    </StyledTitleBar>
  );
}

TitleBar.propTypes = {
  dealerId: PropTypes.number,
  brand: PropTypes.string,
  name: PropTypes.string,
  white: PropTypes.bool,
  className: PropTypes.string,
  refreshCache: PropTypes.func,
};

TitleBar.defaultProps = {
  dealerId: 0,
  brand: '',
  name: '',
  white: false,
  className: '',
  refreshCache: null,
};

export default TitleBar;

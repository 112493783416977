import React from 'react';

export default function PlaceholderHome() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="353"
      height="199"
      viewBox="0 0 353 199"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          d="M275.427 90.215c0 42.7-40.037 77.315-89.425 77.315-49.387 0-89.424-34.615-89.424-77.315 0-42.7 40.037-77.315 89.424-77.315 49.388 0 89.425 34.615 89.425 77.315"
        />
        <path
          fill="#E6E6E6"
          d="M136.942 93.01C101.1 114.805 61.898 128.216 22.05 132.314c-4.852.5-9.867.912-14.212 3.62-4.343 2.707-7.873 8.398-7.162 14.322 1.184 9.84 11.575 12.48 19.66 12.766l122.064 4.341c3.909.14 4.796 7.641 1.124 9.492-1.918.966-4.005 1.658-5.913 2.187-5.528 1.531-12.321 6.35-10.732 13.038 1.31 5.518 7.198 6.572 11.872 6.578l57.817.075c15.007.02 30.981-.297 43.535-10.408 6.656-5.36 12.02-13.266 19.394-16.937 7.56-3.764 16.054-2.513 24.196-1.889a145.3 145.3 0 0 0 42.945-3.12c5.853-1.314 11.788-3.057 16.785-7.03 4.997-3.974 8.952-10.584 9.05-17.899.106-7.766-4.149-14.84-9.486-18.994-5.338-4.153-11.65-5.895-17.844-7.423-52.75-13.013-106.79-15.532-160.554-18.004-21.427-.985-43.07-1.95-64.138 2.951"
        />
        <path
          fill="#666"
          d="M34.482 78.221c-1.39 3.546-6.113 5.115-7.014 8.816-.841 3.461 2.128 6.695 2.486 10.24.445 4.411-3.096 8.062-5.254 11.935-3.426 6.145-3.138 14.636 1.918 19.528 3.112 3.011 7.517 4.318 11.824 4.77 3.895.408 8.018.155 11.424-1.78 4.323-2.456 6.878-7.383 7.314-12.336.436-4.954-1.017-9.915-3.204-14.38-1.381-2.82-3.098-5.644-3.165-8.784-.057-2.65 1.083-5.184 1.317-7.825.439-4.988-2.343-9.625-5.021-13.856-1.685-2.662-5.013-8.74-8.986-7.197-4.37 1.698-2.35 7.577-3.64 10.87"
        />
        <path
          fill="gray"
          d="M50.81 96.23c-.056-2.65 1.084-5.185 1.317-7.826.44-4.987-2.342-9.625-5.02-13.855-1.05-1.658-2.735-4.64-4.818-6.282-2.211 2.394-.94 7.02-2.028 9.8-1.388 3.545-6.113 5.114-7.013 8.815-.842 3.461 2.127 6.695 2.485 10.24.445 4.411-3.096 8.062-5.255 11.935-3.425 6.145-3.137 14.636 1.919 19.528 3.112 3.011 7.517 4.318 11.823 4.77.22.022.44.042.662.062 1.749-.282 3.44-.81 4.984-1.687 4.323-2.456 6.878-7.383 7.314-12.337.436-4.953-1.017-9.914-3.204-14.38-1.381-2.82-3.098-5.643-3.165-8.783"
        />
        <path
          stroke="#666"
          d="M39.897 71.887s6.417 21.367-1.455 61.623M47.61 82.754s-1.62 5.206-5.4 6.54M34.938 94.158c1.563 1.735 3.913 3.297 7.41 3.749M31.97 88.34s.534 3.117 2.968 5.817M49.371 104.853s-1.923 7.657-7.953 8.814M29.985 115.215s-.535 6.595 9.672 11.555"
        />
        <path
          fill="#666"
          d="M303.151 28.525c-1.95 4.985-8.591 7.19-9.856 12.391-1.185 4.865 2.99 9.41 3.493 14.391.625 6.201-4.351 11.332-7.387 16.776-4.813 8.636-4.409 20.571 2.697 27.446 4.373 4.232 10.565 6.07 16.618 6.704 5.476.574 11.27.218 16.056-2.5 6.077-3.453 9.668-10.378 10.28-17.34.614-6.962-1.428-13.935-4.502-20.21-1.94-3.965-4.355-7.933-4.45-12.346-.079-3.726 1.524-7.287 1.851-10.999.618-7.009-3.293-13.528-7.055-19.474-2.369-3.742-7.046-12.285-12.63-10.115-6.144 2.387-3.304 10.65-5.115 15.276"
        />
        <path
          fill="gray"
          d="M326.1 53.837c-.078-3.726 1.524-7.287 1.852-10.999.618-7.009-3.293-13.528-7.056-19.474-1.475-2.33-3.845-6.52-6.773-8.829-3.107 3.365-1.32 9.865-2.85 13.772-1.95 4.985-8.591 7.19-9.857 12.391-1.183 4.865 2.99 9.41 3.494 14.391.626 6.201-4.351 11.332-7.385 16.776-4.815 8.636-4.41 20.572 2.696 27.446 4.374 4.232 10.566 6.07 16.618 6.704.309.032.619.061.93.087 2.459-.395 4.835-1.138 7.004-2.37 6.077-3.452 9.668-10.377 10.28-17.339.613-6.962-1.43-13.935-4.502-20.21-1.941-3.965-4.355-7.933-4.45-12.346"
        />
        <path
          stroke="#666"
          d="M310.762 19.623s9.02 30.032-2.046 86.61M321.603 34.896s-2.276 7.316-7.59 9.192M303.792 50.924c2.198 2.438 5.5 4.634 10.415 5.269M299.622 42.748s.75 4.38 4.17 8.176M324.078 65.956s-2.702 10.761-11.178 12.388"
        />
        <path
          stroke="#27CCC3"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M296.83 80.52s-.752 9.27 13.596 16.24"
        />
        <path fill="#999" d="M293.894 54.4h-18.467l32.809 29.005 18.467.25z" />
        <path fill="#999" d="M326.788 83.61L293.98 54.356 263.22 83.61z" />
        <path fill="#CCC" d="M81.65 83.361h195.493V54.356H81.65z" />
        <path
          fill="#CCC"
          d="M310.683 83.61l-32.81-29.254-30.758 29.254zM114.344 83.61l-32.81-29.254-30.76 29.254z"
        />
        <path fill="#B3B3B3" d="M51.076 138.15h259.385V88.616H51.076z" />
        <path fill="#CCC" d="M156.01 138.218h46.379V93.636H156.01z" />
        <path fill="#F2F2F2" d="M50.774 151.757h259.687v-13.652H50.774z" />
        <path fill="#B3B3B3" d="M156.261 95.174h45.864v-1.65h-45.864z" />
        <path fill="#E6E6E6" d="M156.234 138.105h2.194V93.49h-2.194z" />
        <path
          fill="#FFF"
          d="M206.843 88.571c.137 0 .247.111.247.247v4.706h-.012v44.581h-4.953V93.771a.246.246 0 0 0-.247-.247h-45.37a.246.246 0 0 0-.247.247v44.334h-4.952V88.818c0-.136.11-.247.247-.247h55.287z"
        />
        <path fill="#CCC" d="M310.461 151.757h16.089v-13.652h-16.089z" />
        <path fill="#999" d="M310.461 138.056h16.089V88.571h-16.089z" />
        <path fill="#BCBEC0" d="M306.814 88.571H328.7v-5.21h-21.886z" />
        <path fill="#E6E6E6" d="M48.038 88.571h266.71v-5.21H48.038z" />
        <path fill="#FFF" d="M50.775 142.116h96.361v-4.055H50.775z" />
        <path fill="#E6E6E6" d="M207.078 138.105h2.194V88.571h-2.194z" />
        <path
          fill="#E6E6E6"
          d="M203.763 109.322h5.203c.44 0 .796-.356.796-.795v-1.07a.795.795 0 0 0-.796-.795h-5.203a.796.796 0 0 0-.795.795v1.07c0 .439.357.795.795.795"
        />
        <path
          fill="#FFF"
          d="M201.933 109.322h5.203c.44 0 .795-.356.795-.795v-1.07a.794.794 0 0 0-.795-.795h-5.203a.796.796 0 0 0-.796.795v1.07c0 .439.357.795.796.795"
        />
        <path
          fill="#E6E6E6"
          d="M152.92 109.322h5.203c.44 0 .795-.356.795-.795v-1.07a.794.794 0 0 0-.795-.795h-5.203a.796.796 0 0 0-.796.795v1.07c0 .439.357.795.796.795"
        />
        <path
          fill="#FFF"
          d="M151.089 109.322h5.203c.44 0 .796-.356.796-.795v-1.07a.795.795 0 0 0-.796-.795h-5.203a.795.795 0 0 0-.795.795v1.07c0 .439.356.795.795.795M146.182 143.283h57.913v-5.178h-57.117a.796.796 0 0 0-.796.796v4.382z"
        />
        <path fill="#CCC" d="M204.095 143.283h5.178v-5.178h-5.178z" />
        <path
          fill="#CCC"
          d="M199.251 148.46h10.021v-5.178h-10.021zM195.298 153.638h13.975v-5.178h-13.975z"
        />
        <path fill="#E6E6E6" d="M313.101 142.116h15.403v-4.055h-15.403z" />
        <path fill="#FFF" d="M209.273 142.116h103.828v-4.01H209.273z" />
        <path fill="#CCC" d="M68.62 138.105h58.177V85.966H68.62z" />
        <path
          fill="#F2F2F2"
          d="M76.701 126.391H87.57V97.08H76.701a.796.796 0 0 0-.796.795v27.722c0 .44.357.795.796.795"
        />
        <path
          fill="#E6E6E6"
          d="M78.376 110.795h6.547v-11.23h-6.547zM78.376 123.907h6.547v-11.23h-6.547zM118.118 126.38H107.25V97.068h10.87c.438 0 .794.356.794.795v27.722a.795.795 0 0 1-.795.795"
        />
        <path
          fill="#F2F2F2"
          d="M116.74 126.38H105.87V97.068h10.868c.44 0 .796.356.796.795v27.722a.796.796 0 0 1-.796.795"
        />
        <path
          fill="#E6E6E6"
          d="M108.518 110.784h6.547v-11.23h-6.547zM108.518 123.895h6.547v-11.232h-6.547z"
        />
        <path fill="#FFF" d="M87.569 126.38h18.302V97.068H87.569z" />
        <path fill="#B3B3B3" d="M89.762 123.895h13.625V99.552H89.762z" />
        <path fill="#FFF" d="M87.569 125.928h2.484v-28.86h-2.484z" />
        <path fill="#E6E6E6" d="M87.901 129.302h16.005v-5.406H85.416z" />
        <path fill="#BCBEC0" d="M102.927 129.302h3.162v-5.406h-5.872z" />
        <path fill="#E6E6E6" d="M100.217 123.895h5.872v-1.37h-5.872z" />
        <path
          fill="#FFF"
          d="M85.417 123.895h14.8v-1.37h-14.8zM88.562 131.494h.792a.7.7 0 0 0 .7-.7v-1.493h-2.192v1.493a.7.7 0 0 0 .7.7M103.509 131.494h.793a.7.7 0 0 0 .7-.7v-1.493h-2.193v1.493a.7.7 0 0 0 .7.7M65.815 85.966h63.568v-5.21H65.815z"
        />
        <path fill="#EFEFEF" d="M129.383 85.966h5.788v-5.21h-5.788z" />
        <path
          fill="#999"
          d="M126.489 138.105h5.788V85.966h-5.788zM134.937 80.756l-32.81-29.254L71.37 80.756z"
        />
        <path fill="#FFF" d="M129.383 80.756l-32.81-29.254-30.758 29.254z" />
        <path fill="#E6E6E6" d="M123.072 80.756L96.666 57.211 71.91 80.756z" />
        <path
          fill="#999"
          d="M96.574 51.502h5.554l2.351 2.096-4.56.885zM100.821 114.353h2.537V99.654H96.34v10.217a4.48 4.48 0 0 0 4.481 4.482"
        />
        <path
          fill="#999"
          d="M92.62 114.353h-2.538V99.654h7.02v10.217a4.482 4.482 0 0 1-4.483 4.482"
        />
        <path fill="#FFF" d="M93.144 77.84h8.328V64.5h-8.328z" />
        <path fill="#B3B3B3" d="M94.208 76.708h6.2V65.631h-6.2z" />
        <path fill="#FFF" d="M96.955 76.708h.535V65.631h-.535z" />
        <path
          fill="#FFF"
          d="M93.943 71.704h6.559v-.535h-6.559zM96.133 122.537h1.175V99.563h-1.175z"
        />
        <path fill="#FFF" d="M89.514 111.638h14.413v-1.175H89.514z" />
        <path
          fill="#F2F2F2"
          d="M182.827 93.49c.054.22.085.447.085.683a2.896 2.896 0 0 1-5.79 0c0-.236.03-.464.083-.683h5.622z"
        />
        <path
          fill="#999"
          d="M287.474 61.172h7.888a.796.796 0 0 0 .796-.795v-1.385a.796.796 0 0 0-.796-.795h-7.888a.796.796 0 0 0-.796.795v1.385c0 .439.357.795.796.795"
        />
        <path
          fill="#FFF"
          d="M185.997 93.524c0 .038.005.075.005.113a5.986 5.986 0 0 1-11.972 0c0-.038.005-.075.006-.113h11.961z"
        />
        <path
          fill="#024391"
          d="M258.899 79.327h7.888a.796.796 0 0 0 .796-.795v-1.385a.796.796 0 0 0-.796-.795h-7.888a.796.796 0 0 0-.796.795v1.385c0 .439.357.795.796.795M248.524 72.925h7.888a.796.796 0 0 0 .796-.795v-1.385a.796.796 0 0 0-.796-.795h-7.888a.796.796 0 0 0-.796.795v1.385c0 .44.357.795.796.795"
        />
        <path
          fill="#FFF"
          d="M178.237 93.73a.956.956 0 1 0 1.912 0 .956.956 0 0 0-1.912 0"
        />
        <path
          fill="#024391"
          d="M231.394 83.361h1.92a.795.795 0 0 0 .796-.795v-1.385a.795.795 0 0 0-.795-.795h-1.921a.795.795 0 0 0-.795.795v1.385c0 .44.356.795.795.795M242.608 72.83h1.922a.796.796 0 0 0 .796-.795V70.65a.796.796 0 0 0-.796-.795h-1.922a.796.796 0 0 0-.794.795v1.385c0 .44.357.795.794.795"
        />
        <path
          fill="#00244F"
          d="M251.934 91.556h13.691v-2.683h-13.691zM283.071 109.332h8.146v-2.683h-8.146zM280.651 116.933h5.551v-2.683h-5.551zM280.651 101.731h5.551v-2.683h-5.551zM235.454 94.831h8.146v-2.683h-8.146zM283.071 131.832h8.146v-2.684h-8.146zM253.349 137.785h8.146v-2.683h-8.146zM237.688 135.787h8.146v-2.683h-8.146z"
        />
        <path fill="#CCC" d="M228.21 138.105h58.178V85.966H228.21z" />
        <path
          fill="#F2F2F2"
          d="M236.291 126.391h10.868V97.08h-10.868a.795.795 0 0 0-.795.795v27.722c0 .44.356.795.795.795"
        />
        <path
          fill="#E6E6E6"
          d="M237.966 110.795h6.547v-11.23h-6.547zM237.966 123.907h6.547v-11.23h-6.547zM277.708 126.38H266.84V97.068h10.868c.44 0 .796.356.796.795v27.722a.796.796 0 0 1-.796.795"
        />
        <path
          fill="#F2F2F2"
          d="M276.33 126.38h-10.868V97.068h10.868c.44 0 .795.356.795.795v27.722a.795.795 0 0 1-.795.795"
        />
        <path
          fill="#E6E6E6"
          d="M268.108 110.784h6.547v-11.23h-6.547zM268.108 123.895h6.547v-11.232h-6.547z"
        />
        <path fill="#FFF" d="M247.159 126.38h18.303V97.068h-18.303z" />
        <path fill="#B3B3B3" d="M249.353 123.895h13.625V99.552h-13.625z" />
        <path fill="#FFF" d="M247.159 125.928h2.484v-28.86h-2.484z" />
        <path fill="#E6E6E6" d="M247.491 129.302h16.005v-5.406h-18.489z" />
        <path fill="#BCBEC0" d="M262.517 129.302h3.162v-5.406h-5.872z" />
        <path fill="#E6E6E6" d="M259.807 123.895h5.872v-1.37h-5.872z" />
        <path
          fill="#FFF"
          d="M245.007 123.895h14.8v-1.37h-14.8zM248.151 131.494h.793a.7.7 0 0 0 .7-.7v-1.493h-2.192v1.493a.7.7 0 0 0 .7.7M263.1 131.494h.792a.7.7 0 0 0 .7-.7v-1.493H262.4v1.493a.7.7 0 0 0 .7.7M225.405 85.966h63.568v-5.21h-63.568z"
        />
        <path fill="#EFEFEF" d="M288.974 85.966h5.788v-5.21h-5.788z" />
        <path
          fill="#999"
          d="M286.079 138.105h5.788V85.966h-5.788zM294.527 80.756L261.72 51.502l-30.76 29.254z"
        />
        <path fill="#FFF" d="M288.974 80.756l-32.81-29.254-30.758 29.254z" />
        <path fill="#E6E6E6" d="M282.663 80.756l-26.406-23.545L231.5 80.756z" />
        <path
          fill="#999"
          d="M256.165 51.502h5.554l2.35 2.096-4.56.885zM260.44 114.25h2.538V99.554h-7.02v10.216a4.482 4.482 0 0 0 4.483 4.482"
        />
        <path
          fill="#999"
          d="M252.238 114.25h-2.537V99.554h7.018v10.216a4.482 4.482 0 0 1-4.48 4.482"
        />
        <path fill="#FFF" d="M255.724 122.526h1.175V99.552h-1.175z" />
        <path
          fill="#FFF"
          d="M249.105 111.626h14.413v-1.175h-14.413zM251.559 77.84h8.329V64.5h-8.329z"
        />
        <path fill="#B3B3B3" d="M252.623 76.708h6.2V65.631h-6.2z" />
        <path fill="#FFF" d="M255.371 76.708h.534V65.631h-.534z" />
        <path
          fill="#FFF"
          d="M252.359 71.704h6.56v-.535h-6.56zM142.417 148.46h56.834v-5.177h-56.039a.795.795 0 0 0-.795.796v4.382z"
        />
        <path
          fill="#FFF"
          d="M139.612 153.269h56.834v-5.178h-56.038a.796.796 0 0 0-.796.796v4.382zM170.834 138.06h19.568v-31.41h-19.568z"
        />
        <path
          fill="#999"
          d="M181.991 118.872h6.219v-8.849h-6.219zM173.08 118.872h6.219v-8.849h-6.219z"
        />
        <path
          fill="#CCC"
          d="M174.11 129.776a1.03 1.03 0 0 0 1.028-1.03v-3.821a1.03 1.03 0 0 0-2.058 0v3.822a1.03 1.03 0 0 0 1.03 1.029"
        />
        <path
          fill="#999"
          d="M172.76 126.836a1.35 1.35 0 1 0 2.698 0 1.35 1.35 0 0 0-2.698 0M163.734 117.182h4.294V106.65h-4.294zM193.151 117.182h4.295V106.65h-4.295z"
        />
        <path
          fill="#E6E6E6"
          d="M256.4 180.569c-.305.972-.184 2.126.516 2.866.98 1.035 2.63.928 4.04.727a187.75 187.75 0 0 0 13.418-2.412c8.258-1.796.975-7.506-3.796-8.294-4.613-.762-12.653 2.243-14.178 7.113"
        />
        <path
          fill="#CCC"
          d="M118.076 14.535c0 7.51-6.088 13.597-13.597 13.597-7.508 0-13.596-6.088-13.596-13.597C90.883 7.026 96.971.94 104.48.94c7.51 0 13.597 6.087 13.597 13.596"
        />
        <path
          fill="#E6E6E6"
          d="M166.34 37.396c-.046 0-.089.011-.134.014a4.289 4.289 0 0 0-4.162-3.282c-.638 0-1.237.148-1.782.397-.732-2.816-3.283-4.899-6.329-4.899a6.547 6.547 0 0 0-6.547 6.547c0 .046.006.091.007.137a3.45 3.45 0 0 0-1.87-.553 3.482 3.482 0 0 0 0 6.963h20.817a2.662 2.662 0 0 0 0-5.324M254.578 26.721c-.063 0-.123.016-.187.02-.636-2.619-2.98-4.568-5.792-4.568-.888 0-1.723.205-2.48.552-1.02-3.92-4.572-6.819-8.812-6.819a9.113 9.113 0 0 0-9.113 9.113c0 .064.01.126.01.19a4.811 4.811 0 0 0-2.603-.77 4.846 4.846 0 0 0 0 9.693h28.977a3.705 3.705 0 0 0 0-7.41"
        />
      </g>
    </svg>
  );
}

import React from 'react';
import styled from 'styled-components';

const SquareFeetIcon = () => {
  const SquareFeetIconStyled = styled.svg`
    max-width: 46px;
    height: auto;

    .st0 {
      fill: #b7e0f9;
    }
    .st1 {
      fill: none;
      stroke: #414042;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  `;

  return (
    <SquareFeetIconStyled
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 46 30"
      xmlSpace="preserve"
      style={{ enableBackground: 'new 0 0 46 30' }}
    >
      <g>
        <path
          className="st0"
          d="M22.1,26.2h3.8v-9h-3.8V26.2z M33.5,26.2h2V9.7h-2V26.2z"
        />
        <path
          className="st1"
          d="M10.8,26.2h24.8V9.7H10.8V26.2z M23.1,1.9L8.7,10.7 M23.1,1.9l14.4,8.8"
        />
        <path
          className="st1"
          d="M20.4,26.2h5.6v-9h-5.6L20.4,26.2z M14.2,19.9h3.5v-5.7h-3.5V19.9z M28.6,19.9h3.5v-5.7h-3.5L28.6,19.9z"
        />
      </g>
    </SquareFeetIconStyled>
  );
};

export default SquareFeetIcon;

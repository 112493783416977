import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import BathIcon from '../../images/Details/svg/BathIcon';
import BedIcon from '../../images/Details/svg/BedIcon';
import FloorplanIcon from '../../images/Details/svg/FloorplanIcon';
import SquareFeetIcon from '../../images/Details/svg/SquareFeetIcon';
import { isIe } from '../../utils';

const IconListPosed = posed.ul({
  enter: {
    opacity: 1,
    delayChildren: 200,
    staggerChildren: 100,
  },
  exit: {
    opacity: isIe ? 1 : 0,
  },
});

const IconPosed = posed.li({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 },
    },
  },
  exit: {
    y: isIe ? 0 : 20,
    opacity: isIe ? 1 : 0,
    transition: { duration: 150 },
  },
});

const FadePosed = posed.div({
  enter: {
    opacity: 1,
    transition: {
      duration: 500,
    },
  },
  exit: {
    opacity: isIe ? 1 : 0,
  },
});

const StyledHomeInfo = styled.div`
  .home-description {
    color: #414042;
    margin: 12px 0 15px;
    @media only screen and (min-width: 768px) {
      font: 800 26px/114% 'acumin-pro';
    }
    @media only screen and (min-width: 1025px) {
      font: 800 36px/114% 'acumin-pro';
    }
  }
  .price {
    font: 500 18px/128% 'acumin-pro';
    color: #414042;
    margin: 20px 0 37px;
    @media only screen and (max-width: 1400px) {
      margin: 10px 0 20px;
    }
  }
  .home-info {
    display: flex;
    justify-content: start;
    max-width: 400px;
    ul {
      flex: 1;
      max-width: 200px;
      padding: 0;
      margin: 0 42px 0 0;
      @media only screen and (max-width: 1400px) {
        margin: 0 12px 0 0;
      }

      li {
        list-style: none;
        font: 400 16px/169% source-sans-pro;
        color: #6d6e71;
        margin-bottom: 18px;
        position: relative;

        svg {
          max-width: 50px;
          max-height: 40px;
          position: relative;
          top: 5px;
          padding-right: 3px;
        }
      }
    }
  }
`;

const HomeInfo = ({
  flagType,
  homeDescription,
  priceString,
  sqFeet,
  isMulti,
  beds,
  bath,
}) => {
  const getPriceString = sourcePrice => {
    if (sourcePrice.includes('Starting')) {
      // destructure 'Starting in the $000,000s'
      const [, , , startingPrice] = sourcePrice.split(' ');
      return <span>BEFORE OPTIONS {startingPrice}*</span>;
    }

    if (sourcePrice.startsWith('$')) {
      return <span>{sourcePrice}*</span>;
    }
    return <span>{sourcePrice}</span>;
  };

  const price = getPriceString(priceString);

  return (
    <StyledHomeInfo>
      <PoseGroup animateOnMount>
        <FadePosed key={0}>
          <div className="sticker-wrapper">{flagType}</div>

          <h1 className="home-description">{homeDescription}</h1>

          <h2 className="price">{price}</h2>
        </FadePosed>
      </PoseGroup>
      <div className="home-info">
        <PoseGroup animateOnMount>
          <IconListPosed key={0}>
            <IconPosed>
              <SquareFeetIcon />
              {sqFeet.toLocaleString('en-US')} sq. feet
            </IconPosed>
            <IconPosed>
              <BedIcon /> {beds} beds
            </IconPosed>
          </IconListPosed>
          <IconListPosed key={1}>
            {isMulti ? (
              <IconPosed>
                <FloorplanIcon /> Multi Section
              </IconPosed>
            ) : (
              <IconPosed>
                {' '}
                <FloorplanIcon isSingle />
                Single Section
              </IconPosed>
            )}
            <IconPosed>
              <BathIcon />
              {bath} baths
            </IconPosed>
          </IconListPosed>
        </PoseGroup>
      </div>
    </StyledHomeInfo>
  );
};

HomeInfo.propTypes = {
  flagType: PropTypes.node,
  homeDescription: PropTypes.string,
  priceString: PropTypes.string,
  sqFeet: PropTypes.number,
  isMulti: PropTypes.bool,
  beds: PropTypes.number,
  bath: PropTypes.number,
};

HomeInfo.defaultProps = {
  flagType: 'Sale',
  homeDescription: 'test',
  priceString: 'Starting in the $XX,XXXs',
  sqFeet: 1200,
  isMulti: true,
  beds: 2,
  bath: 2,
};

export default HomeInfo;

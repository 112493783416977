import React from 'react';
import PropTypes from 'prop-types';
import StyledSelect from './Select.Style';
import BorderlessSelect from './BorderlessSelect.Style';
import MinimalArrowIcon from './MinimalArrowIcon';

const Select = props => {
  const { label, id, options, selected, onChange, borderless } = props;
  return borderless ? (
    <BorderlessSelect>
      {/* details: https://github.com/evcohen/eslint-plugin-jsx-a11y/issues/477 */}
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label htmlFor={id}>
        <span className="label-wrapper">{label}</span>
        <div className="select-wrapper">
          <select
            name={`${label} select`}
            id={id}
            onChange={event => onChange(event)}
            defaultValue={selected}
          >
            {options.map(option => (
              <option key={`${id}-${option}`} value={option}>
                {option}
              </option>
            ))}
          </select>
          <MinimalArrowIcon />
        </div>
      </label>
    </BorderlessSelect>
  ) : (
    <StyledSelect>
      <h3>{label}</h3>
      <div className="select-wrapper">
        <select
          name={`${label} select`}
          id={id}
          value={selected}
          onChange={event => onChange(event)}
        >
          {options.map(option => (
            <option key={`${id}-${option}`} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </StyledSelect>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  borderless: PropTypes.bool,
};

Select.defaultProps = {
  selected: '',
  onChange: () => {},
  borderless: false,
};

export default Select;

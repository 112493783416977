import React from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';
import Chip from './Chip';

const hasOwnProperty = (obj, prop) => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }
  const proto = obj.prototype || obj.constructor.prototype;
  return prop in obj && (!(prop in proto) || proto[prop] !== obj[prop]);
};

const ActiveFiltersStyled = styled.div`
  ul {
    list-style-type: none;
    float: left;
    padding: 0;
    margin: 0;
    li {
      float: left;
      margin: 5px 10px 0 0;
    }
  }

  .clear-button {
    font-family: source-sans-pro;
    background-color: transparent;
    border: none;
    color: #b21f28;
    float: left;
    text-align: center;
    padding-top: 7px;
    font-size: 14px;
    @media only screen and (min-width: 1400px) {
      font-size: 16px;
      padding-top: 10px;
    }
  }
`;

const ChipListPosed = posed.ul({
  enter: {
    opacity: 1,
    delayChildren: 200,
    staggerChildren: 100,
  },
  exit: {
    opacity: 0,
  },
});

const ChipItemPosed = posed.li({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 },
    },
  },
  exit: {
    y: 10,
    opacity: 0,
    transition: { duration: 150 },
  },
});

const getLabel = (key, value) => {
  switch (key) {
    case 'priceSort':
      return `$${value.min.toLocaleString()}-$${value.max.toLocaleString()}`;
    case 'squareFeet':
      return `${value.min.toLocaleString()}-${value.max.toLocaleString()}`;
    default:
      return `${value.min}-${value.max}`;
  }
};

function ActiveFilterChips({ filters, reset, filterChange, className }) {
  const chips = Object.entries(filters)
    .filter(value => {
      if (hasOwnProperty(value[1], 'isActive') && value[1].isActive) {
        return true;
      }
      return false;
    })
    .map(
      ([key, value]) =>
        key === 'homeBuilder' ? (
          <PoseGroup animateOnMount key={key + value}>
            <ChipItemPosed key={key}>
              <Chip
                label={value.label}
                labelValue={value.selected}
                onClose={() => {
                  window.dataLayer.push({
                    event: 'interaction',
                    eventCategory: 'Filters',
                    eventAction: 'Clear',
                    eventLabel: value.label,
                  });
                  filterChange(key, {
                    options: value.options,
                    selected: 'All Manufacturers',
                    isActive: false,
                    label: 'Builder',
                  });
                }}
              />
            </ChipItemPosed>
          </PoseGroup>
        ) : (
          <PoseGroup animateOnMount key={key + value}>
            <ChipItemPosed key={key}>
              <Chip
                label={value.label}
                labelValue={getLabel(key, value)}
                onClose={() => {
                  window.dataLayer.push({
                    event: 'interaction',
                    eventCategory: 'Filters',
                    eventAction: 'Clear',
                    eventLabel: value.label,
                  });
                  filterChange(key, {
                    ...value,
                    min: value.lowerLimit,
                    max: value.higherLimit,
                    isActive: false,
                    label: value.label,
                  });
                }}
              />
            </ChipItemPosed>
          </PoseGroup>
        )
    );
  return (
    <ActiveFiltersStyled className={className}>
      <PoseGroup animateOnMount>
        <ChipListPosed animateOnMount key={0}>
          {chips}
        </ChipListPosed>
      </PoseGroup>
      {chips.length > 0 && (
        <button onClick={reset} type="button" className="clear-button">
          Clear All
        </button>
      )}
    </ActiveFiltersStyled>
  );
}

ActiveFilterChips.propTypes = {
  filters: PropTypes.shape({
    availability: PropTypes.string.isRequired,
    isMultiSection: PropTypes.bool,
    priceSort: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      lowerLimit: PropTypes.number.isRequired,
      higherLimit: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
    }),
    squareFeet: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      lowerLimit: PropTypes.number.isRequired,
      higherLimit: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
    }),
    beds: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      lowerLimit: PropTypes.number.isRequired,
      higherLimit: PropTypes.number.isRequired,
      isActive: PropTypes.bool.isRequired,
    }),
    homeBuilder: PropTypes.shape({
      selected: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
      isActive: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  reset: PropTypes.func.isRequired,
  filterChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ActiveFilterChips.defaultProps = {
  className: '',
};

export default ActiveFilterChips;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  .st0 {
    fill: ${props => (props.white ? '#ffffff' : '#4A6C39')};
  }
`;

const ScotbiltLogo = ({ width, height, white }) => (
  <LogoWrapper white={white}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 367.9 139.7"
    >
      <path
        className="st0"
        d="M367.9,0c0,46.6,0,93.1,0,139.7c-122.6,0-245.1,0-367.7,0C0,139-0.1,1.3,0.1,0.2c0,0,0-0.1,0.1-0.1
	c0,0,0-0.1,0.1-0.1c122.2,0,244.4,0,366.7,0C367.2,0,367.5,0,367.9,0z M362.9,4.9c-119.4,0-238.6,0-358,0c0,0.5-0.1,1-0.1,1.5
	c0,42.3,0,84.6,0,126.8c0,0.3,0,0.5,0,0.8c0,0.2,0.1,0.4,0.1,0.7c119,0,237.9,0,356.9,0c0.3,0,0.6,0,0.9-0.1c0,0,0,0,0.1-0.1
	c0,0,0,0,0.1-0.1C362.9,91.3,362.9,48.1,362.9,4.9z"
      />
      <g>
        <path
          className="st0"
          d="M242.4,63.8c-0.4-2.4-1.6-4.3-3.8-5.5c-1.2-0.6-2.5-1-3.9-1c-3.6,0-7.3,0-10.9,0c-0.2,0-0.4,0-0.7,0.1
		c0,5.7,0,11.3,0,17c0.4,0,0.7,0.1,1,0.1c2.1,0,4.2,0,6.3,0c1.6,0,3.2,0,4.8-0.1c3.1-0.2,5.3-1.7,6.5-4.6
		C242.6,67.9,242.7,65.9,242.4,63.8z"
        />
        <path
          className="st0"
          d="M234,48.6c2.3-0.1,4.2-1.2,5.3-3.4c1.3-2.7,1.3-5.5,0-8.2c-0.7-1.4-1.8-2.4-3.4-2.9c-1-0.3-2.1-0.5-3.1-0.5
		c-3,0-6,0-9,0c-0.2,0-0.5,0-0.8,0.1c0,5,0,9.9,0,14.9C226.8,48.7,230.4,48.7,234,48.6z"
        />
        <g>
          <path
            className="st0"
            d="M162.1,103.3c-0.1-1-0.7-1.6-1.6-1.9c-1.4-0.4-2.8-0.3-4.3-0.2c0,1.9,0,3.8,0,5.7c1.3,0,2.6,0.1,3.8,0
			c1.1-0.1,1.8-0.8,2-1.9C162.2,104.4,162.2,103.8,162.1,103.3z"
          />
          <path
            className="st0"
            d="M144.5,49.4c-4,0.5-7.1,2.5-9.2,6c-1.6,2.8-2.1,5.8-1.7,9c0.8,6.2,5.6,10.3,10.9,10.8
			c5,0.4,8.9-1.4,11.7-5.6c1.4-2.2,2-4.6,2-7.2c-0.1-0.8-0.1-1.6-0.2-2.4C156.9,53,150.8,48.6,144.5,49.4z"
          />
        </g>
        <path
          className="st0"
          d="M135.8,101.3c-2.5,0.8-4.1,3.2-4.1,5.9c0,1.7,0.6,3.3,1.7,4.5c2.2,2.5,6,2.6,8.3,0.2
		c1.6-1.6,2.1-3.6,1.7-5.8C142.9,102.5,139.2,100.2,135.8,101.3z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M358.2,8C251,8,143.9,8,36.8,8C27.6,8,18.5,8,9.4,8C9,8,8.6,8.1,8.2,8.1c0,0.3-0.1,0.4-0.1,0.5
		c0,40.8,0,81.5,0,122.3c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0,0,0,0.1,0.1c117.1,0,234.2,0,351.4,0c0-41.1,0-82.1,0-123.3
		C359.2,8.1,358.7,8,358.2,8z M265.6,24.9c0.2,0,0.5-0.1,0.8-0.1c2.9,0,5.9,0,8.8,0c0.9,0,0.9,0,0.9,0.9c0,2.6,0,5.1,0,7.7
		c0,0.2,0,0.5-0.1,0.8c-3.5,0-7,0-10.5,0C265.5,33.5,265.4,25.9,265.6,24.9z M65.1,103.1c-0.7,0-1.3,0-2,0c0-0.6-0.1-1.1-0.1-1.7
		c-1.5,0-3.1,0-4.6,0c-0.2,0.6-0.2,3.9-0.1,5.1c0.9,0.2,1.8,0,2.7,0.1c0.9,0,1.8,0,2.8,0c0,0.7,0,1.3,0,2c-1.9,0-3.7,0-5.5,0
		c0,1.7,0,3.3,0,5.1c0.7,0,1.4,0,2.1,0c0,0.7,0,1.2,0,1.8c-2.2,0-4.3,0-6.6,0c0-0.6,0-1.2,0-1.8c0.6,0,1.2-0.1,1.8-0.1
		c0.2-0.7,0.3-11.3,0.1-12.5c-0.6,0-1.2-0.1-1.8-0.1c0-0.6,0-1.2,0-1.7c0.6-0.2,10.3-0.2,11.3-0.1C65.1,100.5,65.1,101.7,65.1,103.1
		z M63.6,81.5c-2.6,1.4-5.5,2.1-8.4,2.5c-2.1,0.3-4.1,0.3-6.2,0.1c-3.9-0.3-7.7-1.2-11.1-3.1c-2-1.1-3.7-2.5-4.9-4.4
		c-1-1.5-1.6-3.2-1.6-5c0-2.1,0-4.3,0-6.4c0-0.3,0-0.5,0.1-0.8c3.3,0,6.5,0,9.8,0c0,0.3,0.1,0.7,0.1,1c0,1,0,2,0,2.9
		c-0.1,1.9,0.8,3.3,2.3,4.4c1.3,0.9,2.7,1.4,4.2,1.6c2.7,0.5,5.4,0.5,8.1-0.3c1-0.3,1.9-0.7,2.7-1.3c3.1-2,3.7-6.2,1.5-9.2
		c-0.7-1-1.7-1.7-2.7-2.4c-1.9-1.2-4-2-6.1-2.8c-2.5-1-5-1.9-7.5-3c-2-0.8-3.8-1.9-5.5-3.2c-3.4-2.6-5.2-6-5.6-10.2
		c-0.2-2.5,0-5,0.8-7.4c1.2-3.8,3.8-6.5,7.3-8.3c2.5-1.3,5.2-2,8-2.3c4.1-0.4,8.1-0.2,12.1,0.9c2.1,0.6,4.1,1.3,5.9,2.6
		c0.8,0.6,1.6,1.3,2.3,2c1.1,1.2,1.7,2.7,1.7,4.4c0,2.4,0,4.9,0,7.3c0,0.2,0,0.3-0.1,0.6c-3.2,0-6.5,0-9.8,0c0-0.3-0.1-0.6-0.1-0.9
		c0-0.9,0-1.8,0-2.8c0.1-1.3-0.5-2.3-1.6-3c-1.1-0.8-2.4-1.2-3.7-1.5c-2.4-0.4-4.9-0.5-7.3,0.2c-0.4,0.1-0.7,0.2-1.1,0.4
		c-4.3,1.9-4.3,6.6-2.4,9.2c0.9,1.1,1.9,2,3.2,2.6c1.8,0.9,3.7,1.7,5.5,2.4c2.6,1.1,5.3,2,7.9,3.1c2.2,0.9,4.3,2.1,6.1,3.7
		c3.1,2.7,4.7,6.1,4.9,10.2c0.1,2.4-0.1,4.8-1,7.1C70.1,76.8,67.3,79.6,63.6,81.5z M84.7,115.5c-1.9,0-3.8,0-5.7,0
		c0-0.6,0-1.2,0-1.8c0.5,0,1-0.1,1.6-0.1c-0.4-1.2-0.8-2.3-1.2-3.5c-1.7,0-3.4,0-5.1,0c-0.2,0-0.3,0-0.6,0c-0.4,1.1-0.8,2.2-1.2,3.4
		c0.6,0,1.1,0.1,1.7,0.1c0,0.6,0,1.1,0,1.7c-0.7,0.2-4.7,0.3-5.7,0c0-0.5,0-1.1,0-1.7c0.3,0,0.6-0.1,0.8,0c0.5,0.1,0.8-0.2,0.9-0.7
		c0.9-2.6,1.8-5.1,2.7-7.6c0.7-1.9,1.3-3.7,2-5.6c0.1-0.2,0.2-0.4,0.3-0.6c0.8,0,1.5,0,2.2,0c0.4,0,0.5,0.3,0.6,0.6
		c0.6,1.5,1.1,3.1,1.7,4.6c1,2.8,2,5.5,3,8.3c0.4,1,0.2,1,1.3,1c0.2,0,0.3,0,0.5,0.1C84.7,114.3,84.7,114.8,84.7,115.5z
		 M103.9,113.1c-0.1,0.6-0.5,1-1,1.3c-0.8,0.6-1.7,0.9-2.7,1.1c-1.6,0.3-3.2,0.4-4.8,0c-3.9-1-6.4-4.5-6.3-8.6
		c0.1-4,2.6-7.1,6.5-7.9c2.2-0.5,4.4-0.2,6.5,0.8c1.2,0.5,1.9,1.4,1.7,2.8c0,0.3,0,0.6,0,0.9c0,0.2,0,0.3-0.1,0.6
		c-0.7,0.1-1.4,0.1-2.2,0c0-0.3-0.1-0.6-0.1-0.9c0.1-0.9-0.4-1.4-1.1-1.7c-1.1-0.5-2.3-0.7-3.5-0.5c-3.3,0.5-5.2,2.8-5.2,6.1
		c0,1.1,0.2,2.1,0.6,3c1.1,2.5,3.8,4,6.5,3.5c0.6-0.1,1.3-0.3,1.9-0.5c0.7-0.3,1.1-0.8,1.1-1.6c0-0.3,0-0.6,0.1-0.9
		c0.8,0,1.4,0,2.2,0C104,111.4,104.1,112.3,103.9,113.1z M90.9,82c-6.9-3.2-11.3-8.6-12.3-16.2c-1.2-9,1.7-16.4,9.3-21.7
		c2.9-2.1,6.3-3.2,9.9-3.6c4.7-0.5,9.4,0,13.8,1.8c1.5,0.6,2.9,1.5,4.1,2.6c1.5,1.4,2.3,3.1,2.4,5.2c0.1,1.9,0,3.7,0,5.6
		c0,0.1,0,0.2-0.1,0.4c-3,0-6,0-9,0c0-0.2-0.1-0.4-0.1-0.7c0-0.6,0-1.2,0-1.8c0.1-1.2-0.5-2.1-1.5-2.8c-0.9-0.6-1.8-1-2.8-1.2
		c-2.6-0.6-5.3-0.6-7.8,0.3c-3.5,1.1-5.8,3.5-7,6.9c-1.2,3.3-1.2,6.7-0.1,10c1.4,4.3,4.5,7,8.9,8c3.4,0.8,6.8,0.4,10.1-0.9
		c2-0.8,3.9-1.9,5.6-3.2c0.2-0.2,0.5-0.4,0.8-0.6c1.3,2.5,2.5,4.9,3.7,7.4c-0.3,0.3-0.5,0.5-0.8,0.8c-3.4,2.8-7.4,4.5-11.7,5.3
		C101,84.8,95.8,84.3,90.9,82z M123.7,103.6c-0.6,0.2-1.3,0.1-2.1,0.1c0-0.8,0-1.6,0-2.5c-1.3,0-2.5,0-3.6,0
		c-0.2,0.7-0.2,11.1-0.1,12.4c0.6,0,1.2,0.1,1.9,0.1c0,0.6,0,1.1,0,1.7c-0.6,0.2-5.2,0.3-6.4,0.1c0-0.5,0-1.1,0-1.8
		c0.6,0,1.2-0.1,1.9-0.1c0-4.2,0-8.2,0-12.4c-1.2,0-2.3,0-3.6,0c0,0.8-0.1,1.6-0.1,2.4c-0.7,0-1.3,0-1.9,0c-0.2-0.6-0.3-3-0.1-4.4
		c0.6-0.2,13-0.2,14,0C123.7,100.7,123.7,102.1,123.7,103.6z M137.6,115.8c-5,0-8.5-4.1-8.4-8.6c-0.1-4,2.9-8.3,8.4-8.4
		c4.9,0,8.5,3.7,8.5,8.4C146.1,112,142.4,115.8,137.6,115.8z M165.9,115.4c-0.9,0.1-1.7,0.1-2.6-0.1c-0.6-0.1-0.9-0.6-1.2-1.1
		c-0.8-1.4-1.6-2.7-2.3-4.1c-0.5-1-1.2-1.3-2.2-1.3c-0.5,0-1,0-1.5,0c0,1.6,0,3,0,4.6c0.6,0,1.2,0.1,1.9,0.1c0,0.6,0,1.2,0,1.8
		c-2.1,0-4.2,0-6.4,0c0-0.6,0-1.2,0-1.8c0.6,0,1.2-0.1,1.9-0.1c0-4.2,0-8.3,0-12.5c-0.6,0-1.2-0.1-1.8-0.1c-0.2-0.6-0.1-1.2-0.1-1.8
		c0.2,0,0.4-0.1,0.5-0.1c2.5,0,4.9,0,7.4,0c0.6,0,1.2,0.1,1.7,0.2c2.1,0.5,3.3,2.1,3.3,4.4c0,2.1-0.9,3.7-2.9,4.5
		c-0.1,0-0.1,0.1-0.2,0.1c0.8,1.3,1.6,2.6,2.3,3.9c0.4,0.8,0.9,1.5,2,1.3C165.9,114.2,165.9,114.8,165.9,115.4z M152.2,83.5
		c-2.1,0.5-4.2,0.8-6.1,0.8c-4.4,0-8.3-0.9-12-2.9c-6.2-3.4-10-8.6-11-15.6c-1-6.7,0.6-12.7,5.2-17.9c3.1-3.5,7.1-5.8,11.7-6.8
		c5.9-1.3,11.7-0.9,17.1,1.9c6.6,3.4,10.7,8.8,11.7,16.2C170.3,70.7,163.3,80.6,152.2,83.5z M184.9,101c-0.2,0-0.5,0.1-0.8,0.1
		c-0.5,0-0.8,0.2-1.1,0.6c-1.2,2.1-2.5,4.2-3.8,6.3c-0.2,0.4-0.4,0.8-0.4,1.2c0,1.2,0,2.4,0,3.6c0,0.2,0,0.4,0.1,0.8
		c0.6,0,1.3,0,1.9,0c0,0.7,0,1.2,0,1.8c-2.2,0-4.3,0-6.5,0c0-0.6,0-1.1,0-1.8c0.6,0,1.3-0.1,2-0.1c0-0.4,0-0.8,0-1.2
		c0-0.5,0-1,0-1.6c0.2-1.5-0.3-2.8-1.1-4.1c-1.1-1.6-2-3.2-2.9-4.9c-0.3-0.5-0.6-0.8-1.3-0.8c-0.2,0-0.4-0.1-0.7-0.1
		c0-0.6,0-1.1,0-1.7c0.6-0.2,4.6-0.2,5.6-0.1c0,0.1,0.1,0.2,0.1,0.3c0.1,1.4,0.1,1.4-1.3,1.6c-0.1,0-0.1,0-0.3,0.1
		c0.4,0.9,1,1.7,1.5,2.6c0.5,0.9,1,1.7,1.5,2.6c0.5-0.4,2.6-4,3.2-5.2c-0.5,0-0.9-0.1-1.4-0.1c0-0.6,0-1.2,0-1.8c1.8,0,3.7,0,5.5,0
		C184.9,99.7,184.9,100.3,184.9,101z M198.2,83.4c-0.4,0.1-0.9,0.2-1.3,0.2c-3.5,0.2-6.9-0.1-10.2-1.3c-5.1-1.9-8-5.6-8.9-11
		c-0.2-1.4-0.3-2.8-0.3-4.2c0-5.6,0-11.2,0-16.8c0-0.3,0-0.7,0-1.1c-2.1,0-4.2,0-6.2,0c-0.3-0.7-0.3-7-0.1-8c2.1,0,4.3,0,6.6,0
		c0-3.8,0-7.5,0-11.2c0.7-0.3,9.2-0.3,10.2-0.1c0,3.7,0,7.4,0,11.2c0.4,0,0.7,0.1,1,0.1c2.5,0,5,0,7.4,0c0.3,0,0.7,0,1.1,0.1
		c0,2.6,0,5.2,0,7.9c-3.1,0-6.2,0-9.4,0c0,0.4-0.1,0.6-0.1,0.9c0,5.7,0,11.3,0,17c0,0.7,0.1,1.4,0.2,2.1c0.7,3,2.6,4.8,5.6,5.4
		c1,0.2,2.1,0.2,3.2,0.2c0.4,0,0.7,0,1.1,0C198.4,75.6,198.4,82.6,198.2,83.4z M208.1,115.8c-4.7-0.1-8.4-3.9-8.3-8.7
		c0.1-4.7,3.6-8.3,8.5-8.2c5.1,0,8.5,4.1,8.3,8.5C216.6,112.2,212.9,115.8,208.1,115.8z M238.7,101c-0.6,0-1.2,0.1-2,0.2
		c0,0.4,0,0.7,0,1c0,2.4,0,4.8,0,7.2c0,0.8-0.1,1.6-0.3,2.3c-0.5,1.9-1.7,3.2-3.5,3.8c-1.7,0.6-3.5,0.6-5.3,0
		c-2.5-0.8-3.8-2.8-3.9-5.5c0-2.6,0-5.1,0-7.7c0-0.3,0-0.6,0-1c-0.7,0-1.3-0.1-2-0.1c0-0.6,0-1.2,0-1.7c0.6-0.2,5.5-0.2,6.5,0
		c0,0.5,0,1.1,0,1.7c-0.6,0-1.2,0.1-1.9,0.1c0,0.3-0.1,0.5-0.1,0.7c0,2.6,0,5.1,0,7.7c0,0.5,0.1,1,0.2,1.4c0.3,1.2,1,1.9,2.1,2.3
		c1.1,0.4,2.2,0.4,3.3,0c1.5-0.5,2.4-1.7,2.5-3.5c0.1-1.1,0-2.3,0-3.5c0-1.7,0-3.5,0-5.3c-0.7,0-1.3-0.1-2-0.1c0-0.6,0-1.2,0-1.7
		c0.6-0.2,5.1-0.3,6.4-0.1C238.7,99.8,238.7,100.3,238.7,101z M233.8,83.3c-9.1,0-18.3,0-27.4,0c-0.4,0-0.7,0-1.2-0.1
		c0-2.7,0-5.3,0-8.1c2.3-0.1,4.6,0,6.8-0.1c0.2-0.7,0.3-40.7,0.1-41.9c-2.2,0-4.5,0-6.7,0c-0.2-0.7-0.2-7-0.1-8.2
		c0.2,0,0.5-0.1,0.8-0.1c9,0,17.9,0,26.9,0c2.8,0,5.6,0.2,8.3,1.1c3.7,1.2,6.6,3.4,8.4,6.9c1.4,2.8,1.9,5.7,1.6,8.8
		c-0.2,1.7-0.6,3.4-1.4,5c-1.1,2.2-2.7,4-4.8,5.3c-0.2,0.2-0.5,0.3-0.8,0.5c0.4,0.1,0.6,0.2,0.8,0.3c3.5,1.3,6.1,3.7,7.4,7.2
		c2.2,5.9,1.6,11.5-2.2,16.6c-2.6,3.5-6.1,5.4-10.3,6.2C237.8,83.1,235.8,83.3,233.8,83.3z M258.4,103.6c-0.6,0.2-1.3,0.1-2,0
		c0-0.8,0-1.6,0-2.4c-1.3,0-2.4,0-3.5,0c-0.2,0.7-0.3,11.1-0.1,12.4c0.6,0,1.2,0.1,1.9,0.1c0,0.6,0,1.2,0,1.7
		c-0.8,0.2-5.6,0.2-6.4,0c0-0.5,0-1.1,0-1.7c0.7,0,1.3-0.1,2-0.1c0-4.2,0-8.2,0-12.4c-1.2,0-2.4,0-3.6,0c0,0.8,0,1.6,0,2.3
		c-0.7,0.2-1.4,0.1-2.1,0.1c-0.1-1.5,0-2.9,0-4.5c4.7,0,9.3,0,13.9,0C258.5,99.8,258.5,102.3,258.4,103.6z M276.4,115.3
		c-0.7,0.2-10.9,0.3-12.1,0.1c0-0.5,0-1.1,0-1.7c0.6,0,1.2-0.1,1.9-0.1c0-4.1,0-8.2,0-12.4c-0.6,0-1.2-0.1-1.9-0.1
		c0-0.6,0-1.2,0-1.9c2.1,0,4.2,0,6.4,0c0,0.6,0,1.2,0,1.9c-0.7,0-1.3,0.1-1.9,0.1c0,4.1,0,8.1,0,12.3c1.8,0,3.6,0,5.4,0
		c0-0.8,0-1.6,0-2.5c0.7,0,1.4,0,2.1,0C276.5,112.4,276.4,113.9,276.4,115.3z M282,83.1c-0.6,0.2-21.3,0.2-22.4,0c0-2.6,0-5.1,0-7.8
		c2-0.1,4,0,6-0.1c0-8.7,0-17.3,0-26c-1-0.1-2.1,0-3.1,0c-1,0-2.1,0-3.2,0c-0.2-0.9-0.1-1.8-0.1-2.6c0-0.9,0-1.8,0-2.7
		c0-0.9,0-1.7,0-2.5c0.8-0.2,16.1-0.2,17,0c0,0.4,0.1,0.8,0.1,1.1c0,10.5,0,21,0,31.4c0,0.4,0,0.8,0,1.3c2,0,3.9,0,5.8,0
		C282.2,76.1,282.2,82.3,282,83.1z M294.5,115.5c-4.1,0-8.1,0-12.2,0c0-0.6,0-1.1,0-1.8c0.6,0,1.2-0.1,1.8-0.1
		c0.2-0.6,0.3-11.4,0.1-12.5c-0.6,0-1.2-0.1-1.9-0.1c0-0.6,0-1.2,0-1.8c3.9,0,7.8,0,11.9,0c0.1,1.3,0.1,2.5,0,3.8
		c-0.7,0-1.4,0-2.1,0c0-0.6-0.1-1.1-0.1-1.8c-1.7-0.1-3.4,0-5.1,0c0,1.7,0,3.3,0,5.1c1.9,0,3.7,0,5.5,0c0.3,0.7,0.2,1.3,0.1,2
		c-1.8,0-3.7,0-5.5,0c-0.2,0.8-0.3,4.3-0.1,5.2c1.8,0,3.6,0,5.5,0c0-0.6,0.1-1.2,0.1-1.8c0.7,0,1.3,0,2.1,0
		C294.5,112.9,294.5,114.1,294.5,115.5z M290.9,76.7c-0.7-1.9-0.9-3.9-1-5.9c0-0.6,0-1.2,0-1.8c0-11.6,0-23.2,0-34.8
		c0-0.4,0-0.8,0-1.3c-2.2,0-4.2,0-6.3,0c-0.2-0.7-0.3-6.8-0.1-7.9c0.3,0,0.6-0.1,0.9-0.1c5,0,10,0,14.9,0c0.1,0,0.3,0,0.4,0
		c0.5,0,0.6,0.2,0.6,0.6c0,0.3,0,0.6,0,0.9c0,14.2,0,28.5,0,42.7c0,0.9,0,1.8,0.2,2.7c0.4,2.1,1.5,3,3.6,3.1c0.6,0,1.2,0,1.7,0
		c0.2,0,0.4,0,0.6,0c0,2.8,0,5.6,0,8.3c-0.2,0.1-0.4,0.2-0.6,0.2c-2.8,0.3-5.6,0.3-8.3-0.4C294.3,82.1,292,79.9,290.9,76.7z
		 M314.1,103.6c-0.7,0-1.3,0-2,0c0-0.8,0-1.6,0-2.3c-0.7-0.2-2.6-0.3-3.6-0.1c-0.2,0.6-0.2,10.7-0.1,12.3c0.6,0,1.3,0.1,1.9,0.1
		c0,0.6,0,1.1,0,1.7c-0.6,0.2-5.4,0.3-6.5,0.1c0-0.5,0-1.1,0-1.8c0.7,0,1.3,0,1.9,0c0-4.2,0-8.3,0-12.4c-1.2,0-2.3,0-3.5,0
		c0,0.8-0.1,1.6-0.1,2.4c-0.7,0-1.3,0-1.9,0c-0.2-0.7-0.3-3.6,0-4.5c4.6,0,9.2,0,13.9,0C314.3,99.9,314.3,102.7,314.1,103.6z
		 M336.5,83.4c-0.5,0.1-0.9,0.2-1.4,0.2c-3.7,0.2-7.2-0.1-10.7-1.5c-5.1-2.1-7.8-5.9-8.5-11.3c-0.1-1.1-0.2-2.2-0.2-3.4
		c0-5.7,0-11.4,0-17.1c0-0.3,0-0.7,0-1.1c-2.1,0-4.2,0-6.2,0c-0.2-0.7-0.3-6.8-0.1-7.9c1.1-0.1,2.1,0,3.2,0c1.1,0,2.2,0,3.4,0
		c0-1.9,0-3.8,0-5.7c0-1.9,0-3.7,0-5.7c3.4,0,6.8,0,10.2,0c0.1,1.3,0,2.6,0,3.8c0,1.3,0,2.5,0,3.8c0,1.2,0,2.5,0,3.8
		c3.2,0,6.3,0,9.4,0c0.1,2.7,0.1,5.3,0,8c-3.2,0-6.2,0-9.4,0c0,0.4-0.1,0.7-0.1,1c0,5.5,0,11,0,16.5c0,0.9,0.1,1.8,0.3,2.7
		c0.7,2.9,2.6,4.6,5.5,5.2c1.1,0.2,2.2,0.2,3.3,0.2c0.3,0,0.7,0,1.1,0C336.5,77.7,336.5,80.5,336.5,83.4z"
        />
        <path
          className="st0"
          d="M209.6,101.2c-2.2-0.5-4.2,0.1-5.7,1.8c-2.4,2.7-2,7.2,0.9,9.4c2.3,1.8,5.4,1.6,7.4-0.4c1.3-1.3,1.9-2.9,2-5
		c0-0.3-0.1-0.9-0.2-1.4C213.3,103.3,211.9,101.7,209.6,101.2z"
        />
      </g>
      <path
        className="st0"
        d="M78.8,108.1c-1.5,0-2.8,0-4.3,0c0.6-2.1,1.4-4,2.1-6.2C77.4,104,78.1,106,78.8,108.1z"
      />
    </svg>
  </LogoWrapper>
);

ScotbiltLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  white: PropTypes.bool,
};

ScotbiltLogo.defaultProps = {
  height: 36,
  width: 114,
  white: false,
};

export default ScotbiltLogo;

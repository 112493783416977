import React from 'react';
import PropTypes from 'prop-types';
import {} from 'react-router';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  //   componentDidCatch(error, info) {
  //     // You can also log the error to an error reporting service
  //     // console.log(error, info);
  //   }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div role="main">
          <h1>
            Something went wrong. Please maximize your window and refresh.
          </h1>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;

import React from 'react';
import PropTypes from 'prop-types';

function HomeCard({
  image,
  cardDecorator,
  cardTitle,
  subTitle,
  descriptionList,
}) {
  // Generates a bulleted list of items
  const listItems = descriptionList.map((item, i) => (
    <li key={item}>
      <p>
        {item} {i + 1 !== descriptionList.length && '\u2022'}
      </p>
    </li>
  ));

  const getPriceString = sourcePrice => {
    if (sourcePrice.includes('Starting')) {
      // destructure 'Starting in the $000,000s'
      const [, , , startingPrice] = sourcePrice.split(' ');
      return <span>BEFORE OPTIONS {startingPrice}*</span>;
    }

    if (sourcePrice.startsWith('$')) {
      return <span>{sourcePrice}*</span>;
    }
    return <span>{sourcePrice}</span>;
  };

  const price = getPriceString(subTitle);

  return (
    <div className="home-card">
      <div
        style={{ backgroundImage: `url(${image})` }}
        className="card-background"
      />
      <div className="card-text">
        <ul>{listItems}</ul>
        <h2>
          {cardTitle.length > 32 ? `${cardTitle.slice(0, 32)}...` : cardTitle}{' '}
        </h2>
        <p>{price}</p>
      </div>
      <div className="position-decorator">{cardDecorator}</div>
    </div>
  );
}

HomeCard.propTypes = {
  image: PropTypes.string.isRequired,
  cardDecorator: PropTypes.node,
  cardTitle: PropTypes.string,
  subTitle: PropTypes.string,
  descriptionList: PropTypes.arrayOf(PropTypes.string),
};

HomeCard.defaultProps = {
  cardDecorator: 'Test',
  cardTitle: '',
  subTitle: '',
  descriptionList: [],
};

export default HomeCard;

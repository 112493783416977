import styled from 'styled-components';

const StyledToggle = styled.div`
  ul {
    list-style-type: none;
    margin: 0;
    padding: 10px 0 0 0;
    li {
      float: left;
      padding-right: 8px;
      color: #6d6e71;
      div {
        border-radius: 20px;
      }
      button {
        border-radius: 19px;
        border: none;
        background-color: white;
        border: 1px #d6d6d6 solid;
        padding: 8px 18px;
        transition: background-color cubic-bezier(0, 0.64, 0.34, 1.08) 0.75s;
      }
    }
  }
  .selected {
    color: white;
    background-color: #0075c9;
  }
`;

export default StyledToggle;

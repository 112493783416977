import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  .st0 {
    fill: ${props => (props.white ? '#ffffff' : '#004895')};
  }
  .st1 {
    filter: url(#Adobe_OpacityMaskFilter);
  }
  .st2 {
    fill: ${props => (props.white ? '#ffffff' : '#FFFFFF')};
  }
  .st3 {
    mask: url(#b_1_);
    fill: ${props => (props.white ? '#ffffff' : '#004895')};
  }
`;

const OakwoodLogo = ({ width, height, white }) => (
  <LogoWrapper white={white}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 114 36"
      xmlSpace="preserve"
    >
      <g>
        <path
          className="st0"
          d="M47.1,13c0,1.1,0.2,1.9,0.5,2.4c0.3,0.5,0.8,0.7,1.4,0.7c0.6,0,1.1-0.2,1.4-0.7c0.3-0.5,0.5-1.3,0.5-2.6
		c0-1-0.2-1.8-0.5-2.3c-0.3-0.5-0.8-0.7-1.4-0.7c-0.6,0-1,0.2-1.4,0.7C47.3,11.1,47.1,11.9,47.1,13L47.1,13z M44.3,13
		c0-1.8,0.4-3.2,1.2-4.2c0.8-1,2-1.5,3.5-1.5c1.5,0,2.7,0.5,3.5,1.5c0.8,1,1.2,2.4,1.2,4.1c0,1.3-0.2,2.3-0.5,3.2
		c-0.4,0.8-0.9,1.5-1.6,1.9c-0.7,0.5-1.5,0.7-2.5,0.7c-1,0-1.9-0.2-2.6-0.6c-0.7-0.4-1.2-1-1.6-1.9C44.5,15.4,44.3,14.3,44.3,13
		L44.3,13z M58.8,14.3l-1-4l-1,4H58.8z M59.4,16.7h-3.2l-0.5,1.8h-2.9l3.4-11h3.1l3.4,11h-3C59.9,18.5,59.4,16.7,59.4,16.7z"
        />
        <path
          className="st0"
          d="M62.7,7.5h2.8v4.2l3-4.2h3.8L69,11.7l3.5,6.9h-3.5L67,14l-1.5,1.8v2.7h-2.8L62.7,7.5"
        />
        <path
          className="st0"
          d="M71.4,7.5h2.7l1,6.1l1.4-6.1h2.7l1.4,6.2l1-6.2h2.7l-2,11h-2.8l-1.6-6.9l-1.6,6.9h-2.8L71.4,7.5 M86.3,13
		c0,1.1,0.2,1.9,0.5,2.4c0.3,0.5,0.8,0.7,1.4,0.7c0.6,0,1.1-0.2,1.4-0.7c0.3-0.5,0.5-1.3,0.5-2.6c0-1-0.2-1.8-0.5-2.3
		c-0.3-0.5-0.8-0.7-1.4-0.7c-0.6,0-1,0.2-1.4,0.7C86.5,11.1,86.3,11.9,86.3,13L86.3,13z M83.5,13c0-1.8,0.4-3.2,1.2-4.2
		c0.8-1,2-1.5,3.5-1.5c1.5,0,2.7,0.5,3.5,1.5c0.8,1,1.2,2.4,1.2,4.1c0,1.3-0.2,2.3-0.5,3.2c-0.4,0.8-0.9,1.5-1.6,1.9
		c-0.7,0.5-1.5,0.7-2.5,0.7c-1,0-1.9-0.2-2.6-0.6c-0.7-0.4-1.2-1-1.6-1.9C83.7,15.4,83.5,14.3,83.5,13L83.5,13z M96,13
		c0,1.1,0.2,1.9,0.5,2.4c0.3,0.5,0.8,0.7,1.4,0.7c0.6,0,1.1-0.2,1.4-0.7c0.3-0.5,0.5-1.3,0.5-2.6c0-1-0.2-1.8-0.5-2.3
		c-0.3-0.5-0.8-0.7-1.4-0.7c-0.6,0-1,0.2-1.4,0.7C96.1,11.1,96,11.9,96,13L96,13z M93.1,13c0-1.8,0.4-3.2,1.2-4.2
		c0.8-1,2-1.5,3.5-1.5c1.5,0,2.7,0.5,3.5,1.5c0.8,1,1.2,2.4,1.2,4.1c0,1.3-0.2,2.3-0.5,3.2c-0.4,0.8-0.9,1.5-1.6,1.9
		c-0.7,0.5-1.5,0.7-2.5,0.7c-1,0-1.9-0.2-2.6-0.6c-0.7-0.4-1.2-1-1.6-1.9C93.4,15.4,93.1,14.3,93.1,13L93.1,13z M106,10v6h0.7
		c0.6,0,1-0.1,1.3-0.2s0.4-0.4,0.6-0.8c0.1-0.4,0.2-1,0.2-1.9c0-1.2-0.2-2-0.5-2.4c-0.3-0.4-0.8-0.6-1.6-0.6H106z M103.2,7.5h4.2
		c0.8,0,1.5,0.1,2,0.4c0.5,0.3,0.9,0.7,1.3,1.2s0.6,1.1,0.7,1.8c0.2,0.7,0.2,1.4,0.2,2.1c0,1.2-0.1,2.1-0.3,2.7
		c-0.2,0.6-0.5,1.2-0.9,1.6c-0.4,0.4-0.8,0.7-1.3,0.9c-0.6,0.2-1.2,0.3-1.7,0.3h-4.2C103.2,18.5,103.2,7.5,103.2,7.5z M44.6,20.7
		h2.8v3.9h3.1v-3.9h2.8v11h-2.8v-4.5h-3.1v4.5h-2.8V20.7 M56.7,26.2c0,1.1,0.2,1.9,0.5,2.4c0.3,0.5,0.8,0.7,1.4,0.7
		c0.6,0,1.1-0.2,1.4-0.7c0.3-0.5,0.5-1.3,0.5-2.6c0-1-0.2-1.8-0.5-2.3c-0.3-0.5-0.8-0.7-1.4-0.7c-0.6,0-1,0.2-1.4,0.7
		C56.9,24.3,56.7,25.1,56.7,26.2L56.7,26.2z M53.9,26.2c0-1.8,0.4-3.2,1.2-4.2c0.8-1,2-1.5,3.5-1.5c1.5,0,2.7,0.5,3.5,1.5
		c0.8,1,1.2,2.4,1.2,4.1c0,1.3-0.2,2.3-0.5,3.2c-0.4,0.8-0.9,1.5-1.6,1.9c-0.7,0.5-1.5,0.7-2.5,0.7c-1,0-1.9-0.2-2.6-0.6
		c-0.7-0.4-1.2-1-1.6-1.9C54.1,28.6,53.9,27.5,53.9,26.2L53.9,26.2z M63.8,20.7h3.7l1.4,6.7l1.4-6.7h3.7v11h-2.3v-8.4L70,31.7h-2.1
		l-1.8-8.4v8.4h-2.3L63.8,20.7 M74.9,20.7h7.6V23h-4.7v1.8h4.4V27h-4.4v2.2h4.9v2.5h-7.7V20.7"
        />
        <g transform="translate(.84 .52)">
          <defs>
            <filter
              id="Adobe_OpacityMaskFilter"
              filterUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="90.1"
              height="35"
            >
              <feColorMatrix
                type="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
              />
            </filter>
          </defs>
          <mask
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="90.1"
            height="35"
            id="b_1_"
          >
            <g className="st1">
              <path id="a_1_" className="st2" d="M0,0h90v35H0V0z" />
            </g>
          </mask>
          <path
            className="st3"
            d="M81.8,27.5l2.7-0.2c0.1,0.5,0.2,0.9,0.4,1.2c0.3,0.4,0.7,0.7,1.2,0.7c0.4,0,0.7-0.1,0.9-0.3
			c0.4-0.4,0.4-1.1,0-1.6c-0.2-0.2-0.7-0.4-1.4-0.6c-1.2-0.3-2.1-0.8-2.7-1.3c-0.5-0.6-0.8-1.3-0.8-2.1c0-0.6,0.1-1.1,0.4-1.6
			c0.3-0.5,0.7-0.9,1.2-1.2C84.3,20.1,85,20,86,20c1.2,0,2.1,0.3,2.7,0.8c0.6,0.5,1,1.4,1.1,2.5l-2.7,0.2c-0.1-0.5-0.2-0.9-0.4-1.1
			c-0.2-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.2,0.6c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.4,0.3,0.9,0.4
			c1.2,0.3,2.1,0.6,2.7,1c0.5,0.3,0.9,0.7,1.2,1.2c0.2,0.5,0.4,1,0.4,1.6c0,0.7-0.2,1.4-0.5,2c-0.3,0.6-0.8,1.1-1.4,1.4
			c-0.6,0.3-1.3,0.5-2.2,0.5c-1.6,0-2.6-0.4-3.2-1.1C82.2,29.6,81.8,28.7,81.8,27.5 M21.3,0.1c0,0,4.8,0.3,7,0.9
			c2.2,0.6,3.6,1.2,5.2,2.2c1.6,1,2.7,3,3.4,4.3c0.7,1.3,1.6,3.9,1.6,9.7s-0.9,8.7-2.2,11.6c-1.3,2.9-4.2,4.6-7.9,5.5
			c-3.7,0.9-9.1,0.7-9.1,0.7S13,35,9,33.9c-4.5-1.3-6.3-2.7-7.9-7.8C0,22.8,0,19.1,0,17c0-2.1,0.1-4.3,0.9-7.4
			c0.8-3.1,2.2-5.9,6.2-7.7C10.9,0.1,17.2,0,17.2,0v5h4V0.1z M17.2,5c0,0-7.5-0.2-9.3,3.2C6,12,5.6,15,6.1,20.9
			c0.5,5.9,2.8,8.1,6.5,8.9c3.6,0.8,6.2,0.5,6.2,0.5s-0.3-0.3-0.7-1c-0.2-0.5-0.2-1-0.2-1s-4.2-0.8-5.7-4.6
			c-1.2-3.3-1.1-7.9-1.1-7.9h-0.9c0,0-0.1-1.2,0-2.3c0.1-1.1,0.6-3,2.6-4.3c2-1.2,4.5-1.3,4.5-1.3L17.2,5L17.2,5z M21.3,5v2.9
			c0,0,3.5,0.3,5.1,1.9c2.4,2.4,1.8,6.2,1.8,6.2h-0.9c0,0,0.3,4-1,7.2s-2.3,3.8-4.1,4.5c-1.8,0.7-1.8,0.4-1.8,0.4s-0.1,0.7-0.4,1.3
			c-0.3,0.6-0.6,1-0.6,1s3.6,0.2,6-0.4c2.3-0.6,4.4-1.6,5.7-4.3c0.9-1.9,1.4-7,1.4-9.4c0-2-0.4-7.3-3.1-9.2C26.4,5,21.3,5,21.3,5
			L21.3,5z M18.6,10.4c0,0,0.2,0.3,0.2,0.6c0.1,0.3,0,0.5,0,0.5s-1.6-0.1-3,0.7c-2,1.1-1.8,3.6-1.8,3.6h0.7c0,0-0.1,3.5,0.8,5.2
			c1,1.9,2.4,2.8,2.8,3c0.3,0.2,0.5,0.2,0.5,0.2s0,0.2,0.1,0.3c0.1,0.1,0.2,0.3,0.2,0.3s0.2-0.2,0.2-0.4s0.1-0.3,0.1-0.3
			s0.9-0.2,2.4-1.8c1.4-1.6,1.7-4.1,1.7-4.7l0-1.8h0.7c0,0,0.2-1.8-0.9-2.9c-0.9-0.9-1.6-1.3-2.8-1.5c-0.9-0.1-1.1-0.1-1.1-0.1
			s0-0.4,0-0.6c0.1-0.2,0.3-0.6,0.3-0.6L18.6,10.4L18.6,10.4z"
          />
        </g>
        <path
          className="st0"
          d="M112.1,9.3c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.3-0.8,0.8-0.8c0.5,0,0.8,0.3,0.8,0.8
		C112.9,8.9,112.6,9.3,112.1,9.3z M112.1,7.5c-0.6,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.6,0,1-0.5,1-1C113.1,7.9,112.7,7.5,112.1,7.5z"
        />
        <path
          className="st0"
          d="M112.1,8.5h-0.2V8.2h0.2c0.1,0,0.2,0,0.2,0.1C112.3,8.4,112.2,8.5,112.1,8.5z M112.3,8.6
		c0.1,0,0.2-0.1,0.2-0.3c0-0.2-0.1-0.3-0.4-0.3h-0.4v0.9h0.2V8.6h0.2l0.2,0.2h0.2v0L112.3,8.6L112.3,8.6L112.3,8.6z"
        />
      </g>
    </svg>
  </LogoWrapper>
);

OakwoodLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  white: PropTypes.bool,
};

OakwoodLogo.defaultProps = {
  height: 36,
  width: 114,
  white: false,
};

export default OakwoodLogo;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import {
  WithStore,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { isNull } from 'util';
import { PoseGroup } from 'react-pose';
import Modal from '../Modal/Modal';
import Filters from './Filters';
import StyledListing, { SlideUp } from './Listing.Style';
import Sidebar from '../Sidebar/Sidebar';
import Pagination from '../Pagination/Pagination';
import HomeList from './HomeList';
import HomeCardLoading from './HomeCardLoading';
import Select from '../Inputs/Select';
import ActiveFilterChips from './ActiveFilterChips';
import ArrowLeft from '../../images/ArrowLeft';
import ArrowRight from '../../images/ArrowRight';
import TitleBar from '../TitleBar';
import BookLink from './BookLink';
import {
  FULL_PRICING_DISCLAIMER,
  SHORT_PRICING_DISCLAIMER,
} from '../../config';

class Listing extends PureComponent {
  static propTypes = {
    width: PropTypes.number.isRequired,
    homes: PropTypes.arrayOf(PropTypes.object),
    perPage: PropTypes.number.isRequired,
    filterChange: PropTypes.func.isRequired,
    sortChange: PropTypes.func.isRequired,
    filters: PropTypes.shape({
      availability: PropTypes.string.isRequired,
      isMultiSection: PropTypes.bool,
      priceSort: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        lowerLimit: PropTypes.number.isRequired,
        higherLimit: PropTypes.number.isRequired,
      }),
      squareFeet: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        lowerLimit: PropTypes.number.isRequired,
        higherLimit: PropTypes.number.isRequired,
      }),
      beds: PropTypes.shape({
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        lowerLimit: PropTypes.number.isRequired,
        higherLimit: PropTypes.number.isRequired,
      }),
      homeBuilder: PropTypes.shape({
        selected: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
      }).isRequired,
    }),
    dealer: PropTypes.shape({}),
    setPage: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    refreshCache: PropTypes.func.isRequired,
    carouselStore: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    homes: null,
    filters: null,
    dealer: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      visibility: false,
    };

    this.showVisibility = this.showVisibility.bind(this);
    this.hideVisibility = this.hideVisibility.bind(this);
  }

  showVisibility() {
    this.setState({ visibility: true });
  }

  hideVisibility() {
    this.setState({ visibility: false });
  }

  render() {
    const { visibility } = this.state;
    const {
      width,
      filterChange,
      carouselStore,
      dealer,
      homes,
      filters,
      perPage,
      setPage,
      sortChange,
      resetFilters,
      refreshCache,
    } = this.props;

    const pages = homes ? Math.ceil(homes.length / 6) : 1;
    const pagedHomes = homes
      ? Array.from({ length: pages }, (blank, idx) =>
          homes.slice(idx * perPage, (idx + 1) * perPage)
        )
      : null;
    if (dealer) {
      if (!dealer.brandAcronym || dealer.brandAcronym === 'IND') {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { error: 'invalid dealer id' },
            }}
          />
        );
      }
    }

    return (
      <StyledListing>
        <Sidebar isCollapsible={width < 1400}>
          {dealer &&
            width >= 1400 && (
              <TitleBar
                dealerId={dealer.dealerId}
                brand={dealer.brandAcronym}
                name={dealer.dealerName}
                refreshCache={() => {
                  refreshCache();
                  carouselStore.setStoreState({
                    currentSlide: 0,
                  });
                }}
              />
            )}
          {filters &&
            Object.keys(filters).length > 0 && (
              <Filters
                filters={filters}
                filterChange={filterChange}
                carouselStore={carouselStore}
              />
            )}
          {dealer && (
            <PoseGroup animateOnMount>
              <SlideUp key={0}>
                <BookLink brand={dealer.brandAcronym} />
              </SlideUp>
            </PoseGroup>
          )}
        </Sidebar>
        <div role="main">
          <div className="top-main">
            {dealer &&
              width < 1400 && (
                <TitleBar
                  className="title-bar"
                  dealerId={dealer.dealerId}
                  brand={dealer.brandAcronym}
                  name={dealer.dealerName}
                />
              )}
            {filters &&
              width >= 1400 && (
                <ActiveFilterChips
                  filters={filters}
                  reset={resetFilters}
                  filterChange={filterChange}
                  className="chip-position-desktop"
                />
              )}
            <div className="top-main-options">
              <Select
                label="Sort By:"
                id="sort-option"
                options={[
                  'Default View',
                  'Price (High to Low)',
                  'Price (Low to High)',
                  'Square Feet',
                  // 'Building Facility A-Z',
                ]}
                selected="Default View"
                onChange={e => {
                  carouselStore.setStoreState({
                    currentSlide: 0,
                  });
                  sortChange(e);
                }}
                borderless
              />
              {filters &&
                width < 1400 && (
                  <ActiveFilterChips
                    filters={filters}
                    reset={resetFilters}
                    filterChange={filterChange}
                    className="chip-position"
                  />
                )}
            </div>
          </div>
          <div className="carousel-wrapper">
            <Slider>
              {isNull(homes) && (
                <Slide>
                  <div className="home-list grid loading">
                    {Array.from({ length: 6 }).map(() => (
                      <HomeCardLoading key={Math.random()} />
                    ))}
                  </div>
                </Slide>
              )}
              {homes &&
                homes.length === 0 && (
                  <Slide>
                    <h2>No Homes Were Found</h2>
                  </Slide>
                )}
              {!isNull(pagedHomes) &&
                pagedHomes.slice(0, pages).map((pageOfHomes, idx) => (
                  <Slide
                    key={
                      pageOfHomes[0].serialNumber || pageOfHomes[0].modelNumber
                    }
                  >
                    <HomeList
                      index={idx}
                      pageOfHomes={pageOfHomes}
                      dealer={dealer}
                      setPage={setPage}
                    />
                  </Slide>
                ))}
            </Slider>
          </div>
          {!homes ||
            (homes.length !== 0 &&
              pagedHomes.length > 1 && (
                <Pagination
                  pages={pages}
                  backButton={() => (
                    <ButtonBack id="listing-back-button">
                      <ArrowLeft />
                    </ButtonBack>
                  )}
                  nextButton={() => (
                    <ButtonNext id="listing-next-button">
                      <ArrowRight />
                    </ButtonNext>
                  )}
                />
              ))}
          <p className="disclaimer">
            {SHORT_PRICING_DISCLAIMER}
            &nbsp;
            <button
              type="button"
              onClick={this.showVisibility}
              className="listings-disclaimer"
            >
              View full pricing information.
            </button>
          </p>
          <Modal
            isVisible={visibility}
            modalSize="medium"
            onCloseClicked={this.hideVisibility}
            showCloseButton
          >
            <p>{FULL_PRICING_DISCLAIMER}</p>
          </Modal>
        </div>
      </StyledListing>
    );
  }
}

export default WithStore(Listing);

import React from 'react';
import styled from 'styled-components';

const BedIcon = () => {
  const BedIconStyled = styled.svg`
    max-width: 46px;
    height: auto;

    .st0 {
      fill: #b7e0f9;
    }
    .st1 {
      fill: none;
      stroke: #414042;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  `;

  return (
    <BedIconStyled
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 46 30"
      xmlSpace="preserve"
      style={{ enableBackground: 'new 0 0 46 30' }}
    >
      <g>
        <path className="st0" d="M11.5,22.7h27.8v-3.5H11.5L11.5,22.7z" />
        <g>
          <path
            className="st1"
            d="M8.7,21.5v4.1 M35.9,23.1v2.5 M11.4,21.3H6.5V12h14.9"
          />
          <path
            className="st1"
            d="M39.3,22.7H11.4V12h25.4c1.4,0,2.5,1.1,2.5,2.5c0,0,0,0,0,0C39.3,14.5,39.3,22.7,39.3,22.7z M6.5,21.3V5.1
			 M13.2,11.9H6.5V8.8h4.3c1.3,0,2.4,1.1,2.4,2.4c0,0,0,0,0,0V11.9z M6.5,18.6h5"
          />
        </g>
      </g>
    </BedIconStyled>
  );
};

export default BedIcon;

import styled from 'styled-components';

const StyledApp = styled.div`
  margin: 0;
  padding: 0;
  min-height: 100vh;

  * {
    user-select: none;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    font-weight: 500;
    .bold {
      font-weight: 'bold';
    }
  }
  h1 {
    font-family: acumin-pro;
    font-size: 36px;
    color: #414042;

    @media only screen and (max-width: 1024px) {
      font-size: 26px;
    }
  }

  h2 {
    font-family: acumin-pro;
    font-size: 18px;
    color: #414042;
    @media only screen and (max-width: 1024px) {
      font-size: 16px;
    }
  }

  h3 {
    font-family: source-sans-pro;
    font-size: 16px;
    color: #6d6e71;
    @media only screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }

  h4 {
    color: white;
    font-family: acumin-pro;
    font-size: 16px;
    .bold {
      font-weight: 'bold';
    }
  }
  p {
    font-family: source-sans-pro;
    font-size: 16px;
    color: #6d6e71;
    font-weight: 'normal';
    margin: 0;
    @media only screen and (max-width: 1024px) {
      font-size: 14px;
    }
  }
  .rc-slider-tooltip-arrow {
    display: none;
  }
  .rc-slider-tooltip-content .rc-slider-tooltip-inner {
    background-color: white;
    border: none;
    box-shadow: none;
    color: #6d6e71;
    font-size: 14px;
    letter-spacing: -0.3px;
    font-family: 'source-sans-pro';
  }
  .sr-only {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 2000ms ease-in;
  }

  a,
  button {
    svg {
      pointer-events: none;
    }
  }
  .select-wrapper {
    &:after {
      pointer-events: none;
    }
  }
`;

export default StyledApp;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const FloorplanIcon = ({ isSingle }) => {
  const FloorplanIconStyled = styled.svg`
    max-width: 46px;
    height: auto;

    .st0 {
      fill: #b7e0f9;
    }
    .st1 {
      fill: none;
      stroke: #414042;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .st2 {
      fill: none;
      stroke: #414042;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 0.5;
    }
  `;

  return (
    <>
      {isSingle ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="46"
          height="30"
          viewBox="0 0 46 30"
        >
          <g fill="none" fillRule="evenodd">
            <path fill="#B7E0F9" d="M8.5 22.663h7.818V6.5H8.5z" />
            <g stroke="#414042" strokeLinecap="round" strokeLinejoin="round">
              <path d="M8.5 6.5v16.163h20v2.576h9.342V6.5z" />
              <path strokeDasharray="0.5,2" d="M28.5 17.119v7.558" />
              <path d="M20.774 6.5v3.194M28.5 6.5v3.194" />
            </g>
          </g>
        </svg>
      ) : (
        <FloorplanIconStyled
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 46 30"
          xmlSpace="preserve"
          style={{ enableBackground: 'new 0 0 46 30' }}
        >
          <g>
            <path
              className="st0"
              d="M15.6,15.6h3.3V27h-3.3L15.6,15.6z M32.5,4.6h4.9v20.3h-4.9V4.6z"
            />
            <path className="st1" d="M18.9,24.8v-9.3H9.1 M15.6,15.6v-11" />
            <path
              className="st1"
              d="M26.2,4.6V2.8h-5.3v1.8H9.1V27h9.8v-2.2h3.5v-1.6h6v1.6h9V4.6H26.2z M15.8,10.6h1.6 M20.9,4.6v6"
            />
            <path className="st2" d="M28.4,23.3V17" />
          </g>
        </FloorplanIconStyled>
      )}
    </>
  );
};

FloorplanIcon.propTypes = {
  isSingle: PropTypes.bool,
};

FloorplanIcon.defaultProps = {
  isSingle: false,
};

export default FloorplanIcon;

import styled from 'styled-components';

const StyledSelect = styled.div`
  position: relative;
  h3 {
    font-weight: bold;
  }
  .select-wrapper {
    position: relative;
    margin-top: 14px;
  }
  .select-wrapper:after {
    content: '';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    border-top: 7px solid #0075c9;
    width: 0px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  select {
    display: block;
    width: 100%;
    border-radius: 0;
    -webkit-appearance: none;
    background: transparent;
    border: 1px solid #d6d6d6;
    outline: 0;
    padding: 10px 10px 9px;
    font-size: 16px;
    letter-spacing: -0.3px;
    line-height: 27px;
    font-family: source-sans-pro, sans-serif;
    font-weight: 400;
    color: #6d6e71;
  }
`;

export default StyledSelect;

import React from 'react';
import styled from 'styled-components';

const BathIcon = () => {
  const BathIconStyled = styled.svg`
    max-width: 46px;
    height: auto;

    .st0 {
      fill: #b7e0f9;
    }
    .st1 {
      fill: none;
      stroke: #414042;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .st2 {
      filter: url(#Adobe_OpacityMaskFilter);
    }
    .st3 {
      fill: #ffffff;
    }
    .st4 {
      mask: url(#b_1_);
      fill: #b7e0f9;
    }
    .st5 {
      filter: url(#Adobe_OpacityMaskFilter_1_);
    }
    .st6 {
      mask: url(#b_2_);
      fill: none;
      stroke: #414042;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  `;

  return (
    <BathIconStyled
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 46 30"
      xmlSpace="preserve"
      style={{ enableBackground: 'new 0 0 46 30' }}
    >
      <g transform="translate(7.252 1.001)">
        <path
          className="st0"
          d="M27.2,14.2v6.7c0,2-1.6,3.6-3.6,3.6c0,0,0,0,0,0h3.3c2,0,3.6-1.6,3.6-3.6c0,0,0,0,0,0v-6.7
		C30.5,14.2,27.2,14.2,27.2,14.2z"
        />
        <path
          className="st1"
          d="M27,24.5H4.1c-2,0-3.5-1.6-3.5-3.5v-6.8h30V21C30.5,23,28.9,24.5,27,24.5z M0.6,14.2V3.5c0-1.6,1.3-3,3-3
		c1.1,0,2.1,0.6,2.6,1.6L6.6,3 M4.2,24.5v2.4 M26.9,24.5v2.4"
        />
        <path
          className="st1"
          d="M10.7,4.5l0.1-0.1L6.5,2.8l-1,4.5l0.1-0.1C5.6,7.3,5.6,7.3,5.6,7.4C5.7,7.6,7,7.1,8.5,6.3
		c1.5-0.8,2.5-1.6,2.4-1.8C10.9,4.5,10.8,4.5,10.7,4.5L10.7,4.5z"
        />
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter"
            filterUnits="userSpaceOnUse"
            x="10.7"
            y="17.8"
            width="9.6"
            height="2.4"
          >
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
            />
          </filter>
        </defs>
        <mask
          maskUnits="userSpaceOnUse"
          x="10.7"
          y="17.8"
          width="9.6"
          height="2.4"
          id="b_1_"
        >
          <g className="st2">
            <path id="a_1_" className="st3" d="M0,27.5h31.1V0H0V27.5z" />
          </g>
        </mask>
        <path className="st4" d="M10.7,20.2h9.6v-2.4h-9.6V20.2z" />
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_1_"
            filterUnits="userSpaceOnUse"
            x="6.5"
            y="6.9"
            width="14"
            height="14"
          >
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
            />
          </filter>
        </defs>
        <mask
          maskUnits="userSpaceOnUse"
          x="6.5"
          y="6.9"
          width="14"
          height="14"
          id="b_2_"
        >
          <g className="st5">
            <path id="a_2_" className="st3" d="M0,27.5h31.1V0H0V27.5z" />
          </g>
        </mask>
        <path
          className="st6"
          d="M10.9,20.4h9.2v-6.2h-9.2V20.4z M9.6,8.1l0.6,1.2 M11.7,7.4l1.2,0.1 M7.6,8.9L7,10.1"
        />
      </g>
    </BathIconStyled>
  );
};

export default BathIcon;

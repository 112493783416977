import React from 'react';
import buttonImage from '../../images/housesmart-button-bg.jpg';

export default function BookLink() {
  const bookUrl = 'https://online.flippingbook.com/view/407279995/';

  return (
    bookUrl && (
      <a
        className="book-link"
        href={bookUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="image-box"
          style={{ backgroundImage: `url(${buttonImage})` }}
        />
      </a>
    )
  );
}

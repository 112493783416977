import styled from 'styled-components';

const StyledPagination = styled.div`
  display: flex;
  margin: 20px 0 0 20px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  .viewing {
    flex: 1;
    text-align: center;
    padding-top: 10px;
  }

  .slider-container {
    width: 56.25%;
    display: flex;
    flex: 1;
  }
  .rc-slider {
    margin: 0 30px;
    flex: 1;
  }
  .rc-slider-handle {
    height: 40px;
    width: 40px;
    margin-top: -18px;
    border-color: #ececec;
    border-width: 1px;
    .pagination-handle {
      font-size: 16px;
      letter-spacing: -0.3px;
      font-family: 'source-sans-pro';
      color: #6d6e71;
      line-height: 36px;
      width: 38px;
      text-align: center;
    }
  }
  button {
    -webkit-appearance: none;
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background: transparent;
    min-height: 20px;
    &:disabled {
      svg {
        path {
          fill: #959595 !important;
        }
      }
    }
  }
`;

export default StyledPagination;

import styled from 'styled-components';

const StyledCarousel = styled.div`
  position: relative;
  text-align: center;

  .slide:hover {
    cursor: pointer;
  }

  .backdrop {
    background-color: rgba(0, 0, 0, 0.97);
  }

  .modal-button {
    padding: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 21px;
    height: 21px;
    border: none;
    float: right;
    margin: 8px;

    &:hover {
      cursor: pointer;
    }
  }

  .modal-slider {
    height: 100%;
  }
  .modal-contents {
    background: none;
    flex: 0 1 75%;
    flex-flow: row wrap;

    .carousel-modal {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
      }
    }

    .modal-close-button {
      position: fixed;
      right: 0;
      top: 0;
      width: 100px;
      padding: 25px;
      -webkit-appearance: none;
      border: none;
      background: transparent;
    }

    .modal-buttons {
      flex: 1 1 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      margin-top: 30px;
      button {
        margin: 0;
        background-color: unset;
        border: 1px rgba(255, 255, 255, 0.6) solid;
        &:disabled {
          path {
            fill: rgba(255, 255, 255, 0.2);
          }
        }
        path {
          fill: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .home-image-slider {
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .position-button-back {
    display: inline-block;
    position: relative;
    margin-top: 10px;

    @media only screen and (min-width: 1400px) {
      position: absolute;
      bottom: 50%;
      display: block;
    }
  }

  .button-back {
    width: 86px;
    height: 54px;
    background-color: #fcfcfc;
    border: 1px #ebeced solid;
    &:active,
    &:focus {
      outline: none;
    }
    &:disabled {
      svg {
        path {
          fill: #ebeced;
        }
      }
    }
    @media screen and (max-width: 1400px) {
      width: 60px;
      height: 38px;
    }
  }

  .position-button-next {
    display: inline-block;
    position: relative;
    margin-top: 10px;

    @media only screen and (min-width: 1400px) {
      position: absolute;
      bottom: 50%;
      display: block;
    }
  }

  .button-next {
    width: 86px;
    height: 54px;
    background-color: #fcfcfc;
    border: 1px #ebeced solid;
    &:active,
    &:focus {
      outline: none;
    }
    &:disabled {
      svg {
        path {
          fill: #ebeced;
        }
      }
    }
    @media screen and (max-width: 1400px) {
      width: 60px;
      height: 38px;
    }
  }
`;

export default StyledCarousel;

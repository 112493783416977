import React from 'react';

const SVGCloseIconWithCircle = () => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="#414042">
        <path d="M21.1666,10.8333 C21.1666,16.5403 16.5406,21.1663 10.8336,21.1663 C5.1266,21.1663 0.4996,16.5403 0.4996,10.8333 C0.4996,5.1263 5.1266,0.5003 10.8336,0.5003 C16.5406,0.5003 21.1666,5.1263 21.1666,10.8333 Z" />
        <path d="M14.5626,7.1038 L7.1046,14.5628" />
        <path d="M7.1041,7.1038 L14.5621,14.5628" />
      </g>
    </g>
  </svg>
);

export default SVGCloseIconWithCircle;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  .st0 {
    fill: ${props => (props.white ? '#ffffff' : '#FEFEFE')};
  }
  .st1 {
    fill: ${props => (props.white ? '#ffffff' : '#B11F24')};
  }
  .st2 {
    fill: ${props => (props.white ? '#ffffff' : '#000000')};
  }
`;

const GILogo = ({ width, height, white }) => (
  <LogoWrapper white={white}>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 96 91"
      xmlSpace="preserve"
    >
      <path
        className="st2"
        d="M0.1,0.5V66H0v25h95.9V68.7h0V0.5H0.1z M18.2,88L18.2,88l-1.7,0v-8.7H6.1V88H4.5V69.6h1.7v8.6h10.5v-8.6h1.7V88z M38.8,79
	c0,5.7-3.4,9.3-8.1,9.3c-4.8,0-8.1-3.5-8.1-9.3v-0.3c0-5.7,3.4-9.3,8.1-9.3c4.8,0,8.1,3.5,8.1,9.3V79z M58,88v-16l-6.6,10.7h-0.1
	l-6.6-10.7V88h-1.6V69.6h1.8l6.4,10.6l6.5-10.6h1.8V88l0,0H58z M76.4,88H64.7V69.6h11.6v1.1h-9.9v7.4h9v1.1h-9v7.6h10.1V88z
	 M91.8,83.7c0,2.6-2.7,4.6-6.5,4.6c-2.4,0-4.5-0.6-6.6-2.1l1.1-1c1.7,1.2,3.4,2,5.5,2c2.9,0,4.8-1.5,4.8-3.5v-0.1
	c0-1.5-0.8-2.6-4.8-4.3c-4.8-2.1-5.7-3.5-5.7-5.4v-0.1c0-2.4,2.6-4.4,6.3-4.4c1.9,0,3.9,0.5,5.7,1.7l-1,1c-1.5-1.1-3.1-1.6-4.8-1.6
	c-2.8,0-4.6,1.5-4.6,3.2v0.1c0,1.6,1,2.6,4.9,4.4C91.1,80.3,91.8,81.8,91.8,83.7L91.8,83.7z M93.5,66h-91V2.9h91V66z"
      />
      <path
        className="st2"
        d="M82.5,27.8c0,0-0.1,0-0.2,0.1c-1.1,0.8-3.3,1.4-5.1,1.6l-3.3,0c-5.3,0-9.2,3.2-12.5,7.1l0.7,0.8c1.2-1.7,3.4-2.8,5.5-2.8
	c3.7,0,4.9,2.2,4.9,4.7c0,5.2-4.5,7.2-9.3,7.2c-4.7,0-9.4-2.4-9.4-7.7c0-5.2,5-7.4,9.3-8.9l0-0.7c-2.6-0.4-4.6-2.3-4.6-5.1
	c0-3.3,2.3-5.6,5.6-5.6c3.6,0,4.8,1.4,5.8,4.5l2.3-3.8c-2.2-1.2-4.6-1.7-7.1-1.7c-4.6,0-9.9,2.6-9.9,8c0,2.4,1.6,4,3.6,4.9v0.1
	c-4.4,1-9.2,3.5-9.2,8.8c0,6.4,7.8,8.2,12.6,8.2c4.6,0,13.7-2.4,13.7-8.5c0-3.8-3.5-5.3-6.7-5.3c-1.7,0-3.3,0.4-4.7,1l-0.1,0
	c1-1.2,3.1-2.1,4.7-2.1c1.1,0,2.1,0,3.2,0.1c1.1,0,2.1,0.1,3.7,0.1c1.9,0,3.7-0.4,4.5-0.5l1.9-4C82.5,28.1,82.6,28,82.5,27.8
	C82.6,27.8,82.6,27.8,82.5,27.8z"
      />
      <path
        className="st0"
        d="M57,43V33c-1.8,1.2-3,2.8-3,5C54,40.2,55.2,41.9,57,43"
      />
      <path
        className="st1"
        d="M48.4,39.1c0-3.5,2-6.3,5.8-8.1h-20V41h11.6v7.5c-3,2.1-6.6,3.1-10.9,3.1c-2.3,0-4.5-0.4-6.4-1.2
	c-1.9-0.8-3.6-2-5-3.5c-1.4-1.5-2.5-3.2-3.3-5.3c-0.8-2-1.2-4.2-1.2-6.6v-0.2c0-2.2,0.4-4.3,1.2-6.3c0.8-2,1.8-3.7,3.2-5.1
	c1.4-1.5,3-2.6,4.8-3.5c1.8-0.9,3.8-1.3,5.9-1.3c1.5,0,2.9,0.1,4.1,0.4c1.2,0.2,2.4,0.6,3.5,1c1.1,0.4,2.1,1,3.1,1.6
	c1,0.6,2,1.4,3,2.2l7.3-8.7c-1.3-1.1-2.7-2.1-4.2-3.1c-1.5-0.9-3-1.7-4.6-2.2c-1.7-0.6-3.4-1.1-5.4-1.4C39,8.2,36.9,8,34.5,8
	c-4,0-7.7,0.7-11,2.1s-6.2,3.4-8.7,5.8c-2.4,2.5-4.4,5.3-5.7,8.6C7.7,27.8,7,31.3,7,35v0.2c0,3.9,0.7,7.5,2,10.7
	c1.4,3.3,3.2,6.1,5.7,8.5c2.4,2.4,5.3,4.3,8.7,5.6c3.4,1.3,7.1,2,11.2,2c4.8,0,9.1-0.8,12.8-2.4c3.7-1.6,6.9-3.6,9.6-5.9v-6.2
	C52.7,46.5,48.4,44.1,48.4,39.1 M76.3,9v19.6l1,0c1.9-0.1,3.9-0.7,4.8-1.4l0.1-0.1c0.1-0.1,0.3-0.2,0.6-0.2c0.5,0,1,0.4,1,0.9
	c0,0.3-0.1,0.5-0.2,0.7L81.2,33l-0.9,0.1c-0.9,0.2-2.4,0.4-4.1,0.4c-0.1,0-0.2,0-0.3,0c-1.2,0-2.1,0-2.9-0.1l-0.1,0
	c1.4,0.6,2.6,1.6,3.3,3c0.3,0.7,0.5,1.5,0.5,2.4c0,0.9-0.2,1.7-0.5,2.4V61H88V9H76.3z"
      />
      <path
        className="st2"
        d="M30.5,70.3c-3.7,0-6.5,3.2-6.5,8.3V79c0,5.1,2.8,8.3,6.5,8.3S37,84.1,37,79v-0.3C37,73.6,34.2,70.3,30.5,70.3"
      />
    </svg>
  </LogoWrapper>
);

GILogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  white: PropTypes.bool,
};

GILogo.defaultProps = {
  height: 91,
  width: 96,
  white: false,
};

export default GILogo;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  .st0 {
    fill: ${props => (props.white ? '#ffffff' : '#241F20')};
  }
`;

const TruValueLogo = ({ width, height, white }) => (
  <LogoWrapper white={white}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x="0px"
      y="0px"
      viewBox="0 0 115 45"
      xmlSpace="preserve"
    >
      <g>
        <path
          className="st0"
          d="M52.6,37.4v2.7h2.6v-2.7h1.6v7.1h-1.6v-3h-2.6v3H51v-7.1H52.6 M59.6,41c0,1.4,0.7,2.4,1.7,2.4
		c1.1,0,1.7-1,1.7-2.4c0-1.3-0.6-2.4-1.7-2.4C60.3,38.6,59.6,39.6,59.6,41L59.6,41z M64.7,40.9c0,2.3-1.4,3.7-3.5,3.7
		c-2.1,0-3.3-1.6-3.3-3.6c0-2.1,1.4-3.7,3.4-3.7C63.5,37.3,64.7,38.9,64.7,40.9z M71.8,41.8c0-1-0.1-1.9-0.1-2.9h0
		c-0.2,0.9-0.5,1.9-0.8,2.7L70,44.4h-1.2L68,41.6c-0.3-0.9-0.5-1.8-0.6-2.8h0c0,1-0.1,2-0.1,2.9l-0.1,2.7h-1.5l0.5-7.1h2.1l0.7,2.4
		c0.2,0.8,0.4,1.7,0.6,2.5h0c0.2-0.8,0.4-1.8,0.7-2.5l0.8-2.3H73l0.4,7.1h-1.6L71.8,41.8 M78.8,41.5h-2.6v1.7h2.9v1.3h-4.5v-7.1H79
		v1.3h-2.8v1.5h2.6L78.8,41.5 M80.3,42.8c0.4,0.2,1.1,0.4,1.8,0.4c0.7,0,1.1-0.3,1.1-0.8c0-0.4-0.3-0.7-1.2-1
		c-1.2-0.4-1.9-1.1-1.9-2.1c0-1.2,1-2.1,2.7-2.1c0.8,0,1.4,0.2,1.8,0.4l-0.4,1.3c-0.5-0.2-1-0.3-1.5-0.3c-0.7,0-1,0.3-1,0.7
		c0,0.5,0.4,0.7,1.3,1c1.2,0.5,1.8,1.1,1.8,2.1c0,1.2-0.9,2.2-2.9,2.2c-0.8,0-1.6-0.2-2-0.4L80.3,42.8"
        />
        <path
          className="st0"
          d="M114.3,42H87.7v-1h26.6V42z M48.8,42H22.2v-1h26.6V42z"
        />
        <path
          className="st0"
          d="M17.5,18.6v3.3H0.7l15.4-11l0-6.7l6.5,0l0,2.1l8.7-5.8l22.5,16.7L31.3,3.1l-11.2,8l0-4.4l-1.5,0l0.1,5.3
		L9,18.7L17.5,18.6"
        />
        <path
          className="st0"
          d="M23.4,21.6h-4.5v-3.2h12.8v3.2h-4.6v13.5h-3.8L23.4,21.6 M32.2,27c0-1.8,0-2.9-0.1-4h3.2l0.1,2.2h0.1
		c0.6-1.8,2.1-2.5,3.3-2.5c0.3,0,0.5,0,0.8,0v3.5c-0.3-0.1-0.7-0.1-1-0.1c-1.4,0-2.3,0.7-2.6,1.9c0,0.2-0.1,0.5-0.1,0.8v6.1h-3.8
		L32.2,27 M53,31.3c0,1.6,0,2.9,0.1,3.9h-3.3l-0.2-1.7h-0.1c-0.5,0.7-1.6,2-3.8,2c-2.5,0-4.3-1.5-4.3-5.3v-7.1h3.8v6.5
		c0,1.8,0.6,2.8,1.9,2.8c1,0,1.6-0.7,1.9-1.3c0.1-0.2,0.1-0.5,0.1-0.8v-7.2H53V31.3 M58.9,35.2l-5.3-16.7h4.1l2,7.1
		c0.6,2,1.1,3.9,1.5,6h0.1c0.4-2,0.9-4,1.5-5.9l2.1-7.1h4l-5.6,16.7H58.9 M76,29.4c-2,0-3.5,0.4-3.5,1.9c0,1,0.6,1.4,1.5,1.4
		c0.9,0,1.7-0.6,2-1.4C76,31.2,76,31,76,30.7C76,30.7,76,29.4,76,29.4z M76.4,35.2l-0.2-1.2h-0.1c-0.8,1-2,1.5-3.5,1.5
		c-2.4,0-3.9-1.8-3.9-3.7c0-3.1,2.8-4.7,7.1-4.6v-0.2c0-0.6-0.3-1.6-2.2-1.6c-1.2,0-2.5,0.4-3.3,0.9l-0.7-2.4
		c0.8-0.5,2.5-1.1,4.7-1.1c4,0,5.3,2.4,5.3,5.2v4.2c0,1.2,0.1,2.3,0.2,2.9C79.8,35.2,76.4,35.2,76.4,35.2z M82.6,35.2h3.8V17.6h-3.8
		V35.2z M100.8,31.3c0,1.6,0,2.9,0.1,3.9h-3.3l-0.2-1.7h-0.1c-0.5,0.7-1.6,2-3.8,2c-2.4,0-4.3-1.5-4.3-5.3v-7.1h3.8v6.5
		c0,1.8,0.6,2.8,1.9,2.8c1,0,1.6-0.7,1.9-1.3c0.1-0.2,0.1-0.5,0.1-0.8v-7.2h3.8V31.3 M111.2,27.7c0-0.9-0.4-2.4-2.1-2.4
		c-1.6,0-2.2,1.4-2.3,2.4C106.7,27.7,111.2,27.7,111.2,27.7z M106.8,30.3c0.1,1.6,1.7,2.3,3.4,2.3c1.3,0,2.3-0.2,3.3-0.5l0.5,2.5
		c-1.2,0.5-2.7,0.7-4.4,0.7c-4.1,0-6.5-2.4-6.5-6.2c0-3.1,1.9-6.5,6.1-6.5c3.9,0,5.4,3,5.4,6c0,0.6-0.1,1.2-0.1,1.5L106.8,30.3
		L106.8,30.3z"
        />
      </g>
    </svg>
  </LogoWrapper>
);

TruValueLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  white: PropTypes.bool,
};

TruValueLogo.defaultProps = {
  height: 36,
  width: 114,
  white: false,
};

export default TruValueLogo;

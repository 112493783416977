import React from 'react';
import styled from 'styled-components';
import PlaceholderHome from '../../images/PlaceholderHome';

const StyledHomeCardLoading = styled.div`
  position: relative;
  .home-card {
    padding-bottom: 12px;
  }
  svg {
    max-width: 100%;
  }
  .loading-bar {
    background: #f6f7f8;
    background: linear-gradient(to top right, #eeeeee 0%, #ededed 80%);
    &:nth-child(1) {
      padding: 10px 35px;
      margin-bottom: 15px;
    }
    &:nth-child(2) {
      height: 12px;
      width: 75%;
      margin-bottom: 7px;
    }
    &:nth-child(3) {
      height: 20px;
      width: 50%;
      margin-bottom: 7px;
    }
    &:nth-child(4) {
      height: 14px;
      width: 20%;
    }
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -438px 0;
    }
    100% {
      background-position: 438px 0;
    }
  }

  /* .animated-background {
    animation-duration: 1.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
  } */
`;

export default function HomeCardLoading() {
  return (
    <StyledHomeCardLoading>
      <div className="home-card">
        <div className="loading-bar">
          <PlaceholderHome />
        </div>
        <div className="loading-bar animated-background" />
        <div className="loading-bar animated-background" />
        <div className="loading-bar animated-background" />
      </div>
    </StyledHomeCardLoading>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  .st0 {
    filter: url(#Adobe_OpacityMaskFilter);
  }
  .st1 {
    fill: ${props => (props.white ? '#ffffff' : '#ffffff')};
  }
  .st2 {
    mask: url(#b_1_);
    fill: ${props => (props.white ? '#ffffff' : '#FFFFFE')};
  }
  .st3 {
    filter: url(#Adobe_OpacityMaskFilter_1_);
  }
  .st4 {
    mask: url(#b_2_);
    fill: ${props => (props.white ? '#ffffff' : '#E03A3E')};
  }
  .st5 {
    filter: url(#Adobe_OpacityMaskFilter_2_);
  }
  .st6 {
    mask: url(#b_3_);
    fill: ${props => (props.white ? '#ffffff' : '#004B85')};
  }
  .st7 {
    fill: ${props => (props.white ? '#ffffff' : '#E03A3E')};
  }
  .st8 {
    fill: ${props => (props.white ? '#ffffff' : '#004B85')};
  }
`;

const LuvLogo = ({ width, height, white }) => (
  <LogoWrapper white={white}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 113 50"
      xmlSpace="preserve"
    >
      <defs>
        <filter
          id="Adobe_OpacityMaskFilter"
          filterUnits="userSpaceOnUse"
          x="0.1"
          y="30.6"
          width="112.1"
          height="1.5"
        >
          <feColorMatrix
            type="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
          />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x="0.1"
        y="30.6"
        width="112.1"
        height="1.5"
        id="b_1_"
      >
        <g className="st0">
          <path id="a_1_" className="st1" d="M0,0.2h112.2v49.7H0V0.2z" />
        </g>
      </mask>
      <path className="st2" d="M0.1,32.1h112.1v-1.5H0.1V32.1z" />
      <defs>
        <filter
          id="Adobe_OpacityMaskFilter_1_"
          filterUnits="userSpaceOnUse"
          x="0"
          y="30.5"
          width="112.3"
          height="4.2"
        >
          <feColorMatrix
            type="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
          />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x="0"
        y="30.5"
        width="112.3"
        height="4.2"
        id="b_2_"
      >
        <g className="st3">
          <path id="a_2_" className="st1" d="M0,0.2h112.2v49.7H0V0.2z" />
        </g>
      </mask>
      <path
        className="st4"
        d="M0.2,32h111.9v-1.4H0.2V32z M0,32.2h112.3v-1.7H0V32.2z M0.1,34.7h112.1v-1.5H0.1V34.7z"
      />
      <defs>
        <filter
          id="Adobe_OpacityMaskFilter_2_"
          filterUnits="userSpaceOnUse"
          x="0"
          y="33"
          width="112.3"
          height="1.7"
        >
          <feColorMatrix
            type="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
          />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x="0"
        y="33"
        width="112.3"
        height="1.7"
        id="b_3_"
      >
        <g className="st5">
          <path id="a_3_" className="st1" d="M0,0.2h112.2v49.7H0V0.2z" />
        </g>
      </mask>
      <path
        className="st6"
        d="M0.2,34.6h111.9v-1.4H0.2V34.6z M0,34.7h112.3V33H0V34.7L0,34.7z"
      />
      <path
        className="st7"
        d="M51.5,6.7c-1.9-3-4.7-5-8-6C37.9-0.9,32,1.3,29.2,5.9c-2.2-2.7-4.8-4.5-7.7-5.3c-2.8-0.7-5.7-0.5-8.4,0.7
	c-3.2,1.4-5.8,4-7.5,7.4C3.8,12.3,3.3,16.6,4.1,21c1.7,9.2,7.6,16.2,12.2,20.5c5.3,4.9,10.8,7.8,13,8.4l0.1,0l0,0l0.1,0
	c0.1,0,5.5-2.4,11.3-7.2c5.3-4.5,12-12.1,13.6-22.8l0,0C54.7,14.5,53.7,10.1,51.5,6.7z M17.5,10.7L14,25.1h5.5l-1,3.6l-9.6-0.1
	l4.4-18H17.5z M42,22.9l0.1,0.1h0.1c0.3,0,0.4,0,2.1-6.4c0.7-2.6,1.4-5.1,1.6-5.9h4.9l-6.3,18.1l-6-0.1l-0.8-18.1h4.2
	c0,0.9-0.1,3.3-0.1,5.8C41.7,22.6,41.8,22.7,42,22.9z M22,27.4c-0.6-0.7-1-1.5-0.9-2.4c0.6-4,2.3-10.7,3.1-13.5l0.2-0.9
	c1,0,3.5,0,4.5,0c-0.5,2.1-2.8,11.6-3,12.6c-0.2,0.7,0.3,1.5,1.1,1.7c0.6,0.2,1.5,0,1.9-1.3c0.8-2.3,2.8-11.3,3.2-12.9h4.4
	c-0.4,2.3-2.3,10.3-3.8,15.4c-1,2.1-3.3,3.2-6.2,3.1C24.5,29,22.9,28.4,22,27.4z"
      />
      <path
        className="st8"
        d="M56.2,10.4h2.6c0,0,0.4,0.1,0.4,0.5c0,0.4,0,6.2,0.1,6.2c0.1,0,1-0.8,1.4-1.1c0.5-0.3,1.3-0.5,2.1-0.2
	c0.8,0.3,1.5,0.8,1.6,2.5c0.1,1.7,0.1,7.2,0.1,7.9c0,0.6,0,2.6,0,2.8c0,0.3-0.2,0.5-0.5,0.5h-2.5c-0.1,0-0.6,0-0.6-0.5
	s-0.1-8.5-0.1-8.7c0-0.2-0.1-0.7-0.8-0.7c-0.7,0-0.8,0.5-0.8,0.8c0,0.3,0.1,8.2,0.1,8.4c0,0.2-0.1,0.6-0.5,0.6h-2.7
	c0,0-0.4,0.1-0.4-0.4c0-0.6,0-18.1,0-18.1S55.8,10.5,56.2,10.4 M70.1,25.8c-0.6,0-1-1.8-1-3.2s0.2-3.2,0.9-3.2c0.4,0,1,0.4,1,3.1
	C71,24.6,70.7,25.8,70.1,25.8z M70.1,15.5c-0.5,0-4.8,0.1-4.8,7c0,6.9,3.7,7.2,4.8,7.2s4.6-0.6,4.6-7.3S70.1,15.5,70.1,15.5z
	 M75.7,28.8c0,0,0,0.7,0.5,0.7c0.5,0,2.7,0,2.7,0s0.5-0.1,0.5-0.4s-0.1-8-0.1-8.3c0-0.6,0.2-1,0.8-1c0.6,0,0.7,0.4,0.7,1.1
	c0,0.7,0,8.1,0,8.2c0,0.1,0.2,0.5,0.6,0.5c0.3,0,2.7,0,2.8,0c0.1,0,0.4-0.3,0.4-0.6s0-8.1,0-8.4c0-0.4,0.4-0.7,0.7-0.7
	c0.3,0,0.7,0.1,0.7,0.8c0,0.8,0,8.4,0,8.4s0.1,0.5,0.5,0.5s2.3,0,2.5,0c0.1,0,0.6-0.1,0.6-0.4s0.1-9.5,0-10.2
	c-0.1-0.6-0.4-2.9-2.4-2.9c-2,0-2.6,1.6-2.8,1.7c-0.1-0.1-0.8-1.8-2.4-1.7c-1.6,0-2.5,1.2-2.7,1.4c-0.1,0.2-0.1-0.7-0.1-0.8
	c0-0.1,0.1-0.4-0.4-0.5c-0.3-0.1-2.6,0-2.8,0c-0.4,0.1-0.3,0.4-0.3,0.7V28.8 M96,21.4H94c0,0-0.3-2.6,1-2.7
	C96.3,18.7,96,21.4,96,21.4z M94.9,15.6c-4.5,0-4.6,6.2-4.6,6.9c0,0.8,0.1,7.2,4.8,7.1c3.9-0.1,4.1-3.9,4.1-4.1s0.2-0.5-0.6-0.5
	s-1.8,0-2.1,0c-0.3,0-0.4,0.2-0.5,0.6c-0.1,0.4-0.2,1-1.1,0.8c-1-0.1-0.9-1.8-0.9-2.3c0,0-0.1-0.4,0.4-0.4s4.5-0.1,4.5-0.1
	s0.4-0.1,0.4-0.5S100,15.5,94.9,15.6z"
      />
      <path
        className="st8"
        d="M101.2,25.6c0,0-1,2-1.1,2.1c-0.1,0.1-0.2,0.4,0.1,0.6c0.3,0.2,2.8,2,5.1,0.8c2.2-1.1,2.4-3.5,2.4-4.1
	c0-0.6,0.3-2.6-2.9-4.1c-1.6-0.8-1.2-1.6-1-1.8c0.2-0.2,0.4-0.7,1.8,0.3c0.6,0.4,0.5,0.2,0.7-0.1c0.2-0.3,1-1.8,1-2
	c0-0.1,0.1-0.4-0.3-0.7c-0.4-0.3-2.9-1.7-5.1-0.5c-2.2,1.2-2.2,4.1-1.8,5.1s0.8,1.3,3.3,2.9c0.7,0.4,0.9,1.2,0.6,1.5
	c-0.6,0.7-1.9,0-2.2-0.1C101.3,25.3,101.5,25.3,101.2,25.6 M108.8,17.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7
	c0.4,0,0.7,0.3,0.7,0.7C109.5,17.4,109.2,17.7,108.8,17.7z M108.8,16.1c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.3,0.8,0.8,0.8
	c0.5,0,0.8-0.4,0.8-0.8S109.3,16.1,108.8,16.1L108.8,16.1z"
      />
      <path
        className="st8"
        d="M108.9,17h-0.2v-0.2h0.2c0.1,0,0.2,0,0.2,0.1C109,16.9,108.9,17,108.9,17z M109,17.1c0.1,0,0.1-0.1,0.1-0.2
	c0-0.2-0.1-0.3-0.3-0.3h-0.3v0.7h0.1v-0.2h0.2l0.2,0.2h0.1v0L109,17.1C109,17.1,109,17.1,109,17.1z"
      />
    </svg>
  </LogoWrapper>
);

LuvLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  white: PropTypes.bool,
};

LuvLogo.defaultProps = {
  height: 50,
  width: 113,
  white: false,
};

export default LuvLogo;

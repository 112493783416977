import styled from 'styled-components';
import posed from 'react-pose';

const StyledListing = styled.div`
  display: flex;
  min-height: 100vh;
  aside {
    &:last-child {
      flex: 0 0 100px;
    }
    .book-link-wrapper {
      .modal-contents {
        max-width: none;
        .carousel {
          max-width: 1000px;
        }
        button {
          margin-top: 25px;
        }
        .position-button-back {
          left: unset !important;
          bottom: unset !important;
          float: left;
          width: 50%;
          position: static;

          .button-back {
            background-color: unset;
            border: 1px rgba(0, 0, 0, 0.6) solid;
            float: right;
            &:disabled {
              path {
                fill: rgba(0, 0, 0, 0.2);
              }
            }
            path {
              fill: rgba(0, 0, 0, 0.6);
            }
          }
        }

        .position-button-next {
          right: unset !important;
          bottom: unset !important;
          float: right;
          width: 50%;
          position: static;

          .button-next {
            background-color: unset;
            border: 1px rgba(0, 0, 0, 0.6) solid;
            float: left;
            &:disabled {
              path {
                fill: rgba(0, 0, 0, 0.2);
              }
            }
            path {
              fill: rgba(0, 0, 0, 0.6);
            }
          }
        }
      }
    }
    .book-link {
      padding: 0px 50px;
      text-decoration: none;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      .image-box {
        border-radius: 3px;
        overflow: hidden;
        padding-bottom: 15px;
        position: relative;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 100px;
      }
      span {
        position: relative;
        z-index: 2;
        padding: 10px 25px;
        color: white;
        font-size: 14px;
        line-height: 22px;
        font-family: 'source sans pro';
        display: block;
        &.bold {
          font-size: 16px;
          font-family: acumin-pro;
          padding: 0 25px;
          font-weight: 500;
          svg {
            margin-left: 10px;
            path {
              fill: white;
            }
          }
        }
      }
    }
  }
  div[role='main'] {
    flex: 1;
    background: #fcfcfc;
    padding: 0px 30px 0px;
  }

  .top-main {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    position: relative;
    align-items: center;
    min-height: 80px;
    .title-bar {
      @media only screen and (min-width: 1400px) {
        margin-left: -70px;
      }
      flex: 1;
      a {
        border-bottom: none;
        padding: 15px 20px;
        margin-bottom: 15px;
      }
    }

    .chip-position-desktop {
      flex: 1;
    }

    .top-main-options {
      flex: 0 1 auto;
      label,
      .chip-position {
        float: right;
        clear: both;
      }
    }
  }
  .carousel-wrapper {
    width: 85%;
    margin: 0 auto;
    min-width: 1000px;
    min-height: 650px;
  }
  .card-link {
    text-decoration: none;
    color: inherit;
  }
  .home-list:not(.grid) {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    & > div {
      flex: 1 33%;
      width: 400px;
      max-width: 480px;
    }
    .home-card {
      margin: 15px;
    }
  }

  .home-list.grid {
    display: grid;
    display: -ms-grid;
    -ms-grid-columns: minmax(300px, 480px) minmax(300px, 480px)
      minmax(300px, 480px);
    grid-template-columns: minmax(300px, 480px) minmax(300px, 480px) minmax(
        300px,
        480px
      );
    grid-template-rows: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
    & > div {
      max-width: none;
      border: 0 solid transparent;
      border-width: 0 15px 15px 0;
      &:nth-child(1) {
        grid-row: 1;
        -ms-grid-row: 1;
        grid-column: 1;
        -ms-grid-column: 1;
      }
      &:nth-child(2) {
        grid-row: 1;
        -ms-grid-row: 1;
        grid-column: 2;
        -ms-grid-column: 2;
      }
      &:nth-child(3) {
        grid-row: 1;
        -ms-grid-row: 1;
        grid-column: 3;
        -ms-grid-column: 3;
      }
      &:nth-child(4) {
        grid-row: 2;
        -ms-grid-row: 2;
        grid-column: 1;
        -ms-grid-column: 1;
      }
      &:nth-child(5) {
        grid-row: 2;
        -ms-grid-row: 2;
        grid-column: 2;
        -ms-grid-column: 2;
      }
      &:nth-child(6) {
        grid-row: 2;
        -ms-grid-row: 2;
        grid-column: 3;
        -ms-grid-column: 3;
      }
    }
  }

  .home-card {
    position: relative;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;

    .card-background {
      display: block;
      width: 100%;
      padding-bottom: 64%;
      background-size: 100%;
    }

    .position-decorator {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .card-text {
      padding-left: 13px;
      padding-bottom: 16px;
      padding-top: 13px;
    }

    ul {
      list-style-type: none;
      width: 100%;
      float: left;
      margin: 0;
      padding: 0;
      li {
        float: left;
        margin: 0;
        padding: 0;
        padding-right: 3px;
        p {
          font-size: 14px;
        }
      }
      ::after {
        clear: both;
      }
    }
  }
  .disclaimer {
    color: #959595;
    margin-top: 27px;
    letter-spacing: -0.3px;
    font-size: 12px;
    line-height: 100%;
    font-family: acumin-pro;
    display: block;
    text-align: center;

    button {
      color: #959595;
      letter-spacing: -0.3px;
      font-size: 12px;
      line-height: 100%;
      font-family: acumin-pro;
      text-decoration: underline;
      background: none;
      padding: 0;
      margin: 0;
      border-width: 0;
      border-style: none;
      border-color: none;
      border-image: none;

      &:hover {
        cursor: pointer;
      }
      &:active {
        outline: none;
      }
    }
  }
  .modal-contents {
    background: #fff;
    max-width: 870px;
    padding: 63px;
    box-sizing: border-box;

    p {
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      letter-spacing: -0.29px;
    }

    .modal-close-button {
      svg {
        #Symbols {
          g {
            stroke: #fff;
            path:first-of-type {
              display: none;
            }
            path {
              stroke-width: 2px;
            }
          }
        }
      }
    }
  }
`;

export const SlideUp = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 750,
      duration: 600,
    },
  },
  exit: {
    opacity: 0,
    y: 50,
  },
});
export default StyledListing;

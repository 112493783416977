import React from 'react';

export default function ResfreshIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="ic_refresh_24px"
        d="M17.65,6.35A8,8,0,1,0,19.73,14H17.65A6,6,0,1,1,12,6a5.915,5.915,0,0,1,4.22,1.78L13,11h7V4Z"
        transform="translate(-4.01 -4)"
      >
        {/* {loading && (
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="25 12, 12"
            to="360 12 12"
            dur="1s"
            repeatCount="indefinite"
          />
        )} */}
      </path>
    </svg>
  );
}

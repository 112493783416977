import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FlagIcon = ({ text }) => {
  const FlagIconStyled = styled.div`
    position: relative;
    z-index: 1;
    &:after {
      position: absolute;
      content: '';
      right: -10px;
      top: 0;
      width: 0;

      z-index: -1;
      border-left: 10px solid #b21f28;
      border-top: transparent 15px solid;
      border-bottom: transparent 15px solid;
    }
    .sticker {
      box-sizing: border-box;
      display: block;
      background: #b21f28;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      font-family: acumin-pro, sans-serif;
      letter-spacing: 1.7px;
      padding: 6px 6px 7px;
      height: 30px;
    }
  `;

  return (
    <FlagIconStyled>
      <div className="sticker">{text}</div>
    </FlagIconStyled>
  );
};

FlagIcon.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FlagIcon;

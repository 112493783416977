import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

function Header({ children, headerType, isBold, ...props }) {
  const StyledHeader = styled.div`
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
    }
    h1 {
      font-family: acumin-pro;
      font-size: 36px;
      font-weight: ${isBold ? 'bold' : 500};
      color: #414042;
      @media only screen and (max-width: 1024px) {
        font-size: 26px;
      }
    }

    h2 {
      font-family: acumin-pro;
      font-size: 18px;
      font-weight: ${isBold ? 'bold' : 500};
      color: #414042;
      @media only screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }

    h3 {
      font-family: source-sans-pro;
      font-size: 16px;
      color: #6d6e71;
      font-weight: ${isBold ? 'bold' : 500};
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }

    h4 {
      color: white;
      font-family: acumin-pro;
      font-size: 16px;
      font-weight: ${isBold ? 'bold' : 500};
    }
  `;

  return (
    <StyledHeader {...props}>
      {headerType === 'h1' ? <h1>{children}</h1> : null}
      {headerType === 'h2' ? <h2>{children}</h2> : null}
      {headerType === 'h3' ? <h3>{children}</h3> : null}
      {headerType === 'h4' ? <h4>{children}</h4> : null}
      {headerType === 'h5' ? <h5>{children}</h5> : null}
      {headerType === 'h6' ? <h6>{children}</h6> : null}
    </StyledHeader>
  );
}

Header.propTypes = {
  headerType: propTypes.string,
  children: propTypes.string,
  isBold: propTypes.bool,
};

// Same approach for defaultProps too
Header.defaultProps = {
  headerType: 'h2',
  children: '',
  isBold: false,
};

export default Header;

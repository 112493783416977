import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

function Hyperlink({ children, fontSize, href, ...props }) {
  const StyledHyperlink = styled.a`
    font-family: source-sans-pro;
    font-size: ${fontSize}px;
    font-weight: 600;
    color: #0075c9;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  `;

  return (
    <StyledHyperlink href={href} {...props}>
      {children}
    </StyledHyperlink>
  );
}

Hyperlink.propTypes = {
  fontSize: propTypes.number,
  children: propTypes.node,
  href: propTypes.string.isRequired,
};

Hyperlink.defaultProps = { children: null, fontSize: 14 };

export default Hyperlink;

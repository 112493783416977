import React from 'react';
import styled from 'styled-components';
import ReactBodymovin from 'react-bodymovin';
import HomeTouchLogo from './hometouch.json';

const bodymovinOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData: HomeTouchLogo,
};

const Logo = styled.div`
  width: 200px;
  @media screen and (min-width: 1400px) {
    width: 249px;
  }
`;

const HometouchLogo = () => (
  <Logo>
    <ReactBodymovin options={bodymovinOptions} />
  </Logo>
);

export default HometouchLogo;

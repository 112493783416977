/**
 * Gets base URL set in /public/appSettings.json
 * This allows octo to change this variable when deploying
 * @returns {String}
 */
const getBaseUrl = async () => {
  const url = await fetch('/appSettings.json')
    .then(data => data.json())
    .then(response => response.baseApiUrl);

  return url;
};

/**
 * Fetches homes from API
 * @param {dealerId}
 * @returns {Promise}
 */
export const fetchHomes = async dealerId => {
  const baseUrl = await getBaseUrl();
  return fetch(`${baseUrl}/api/inventory/hometouch?dealerId=${dealerId}`);
};

/**
 * Fetches dealer from API
 * @param {dealerId}
 * @returns {Promise}
 */
export const fetchDealer = async dealerId => {
  const baseUrl = await getBaseUrl();
  return fetch(`${baseUrl}/api/dealers/id?lotNumber=${dealerId}`);
};

/**
 * Adds homes and time of last update to local storage
 * @param {response}
 * @returns {Array}
 */
export const cacheHomes = (dealerId, homes) => {
  if (!Array.isArray(homes)) return [];
  try {
    const dealerHomes = {
      dealerId,
      expires: Date.now() + 1000 * 60 * 60 * 2,
      homes,
    };
    localStorage.setItem('dealerHomes', JSON.stringify(dealerHomes));

    return homes;
  } catch (error) {
    // TODO handle js error here
    return { message: error };
  }
};

/**
 * Adds dealer info and time of last update to local storage
 * @param {dealer}
 * @returns {Object}
 */
export const cacheDealer = dealer => {
  if (!dealer) return {};
  // Ignore independents
  if (dealer.brandAcronym === 'IND') return {};
  try {
    const dealerInfo = {
      expires: Date.now() + 1000 * 60 * 60 * 2,
      dealer,
    };
    localStorage.setItem('dealerInfo', JSON.stringify(dealerInfo));

    return dealer;
  } catch (error) {
    // TODO handle js error here
    return { message: error };
  }
};

/**
 * Effectively refresh local storage cache by modifying the "expires" value to 0
 * @param {name}
 * @returns {Array}
 */
export const expireCache = name => {
  if (!localStorage.getItem(name)) {
    return false;
  }

  const cache = JSON.parse(localStorage.getItem(name));
  if (Object.keys(cache).indexOf('expires') < 0) {
    return false;
  }

  const newCache = { ...cache, expires: 0 };
  localStorage.setItem(name, JSON.stringify(newCache));
  return true;
};

/**
 * Returns homes for given dealer id using cache-first strategy
 * @param {dealerId}
 * @returns {Array}
 */
export const getHomes = dealerId => {
  // Check local storage
  const cache = JSON.parse(localStorage.getItem('dealerHomes'));
  let cachedHomes = null;

  // Make sure cached dealer are for current dealer
  if (cache && Number(cache.dealerId) === Number(dealerId)) {
    // check expiration
    cachedHomes = cache.homes;
    if (cache && cache.expires > Date.now()) {
      return new Promise(resolve => resolve(cachedHomes));
    }
  }

  // Otherwise fetch and cache the homes for the given dealer
  return fetchHomes(dealerId)
    .then(data => data.json())
    .then(response => cacheHomes(dealerId, response))
    .catch(error => {
      // TODO handle js error here?
      if (cachedHomes) {
        return new Promise(resolve => resolve(cachedHomes));
      }
      return { message: error };
    });
};

/**
 * Returns dealer based on id using cache-first strategy
 * @param {dealerId}
 * @returns {Object}
 */
export const getDealer = dealerId => {
  // Check local storage
  const cache = JSON.parse(localStorage.getItem('dealerInfo'));
  let cachedDealer = null;

  // Make sure cached dealer are for current dealer
  if (cache && cache.dealer.dealerId === Number(dealerId)) {
    // check expiration
    cachedDealer = cache.dealer;
    if (cache && cache.expires > Date.now()) {
      return new Promise(resolve => resolve(cachedDealer));
    }
  }

  // Otherwise fetch and cache the homes for the given dealer
  return fetchDealer(dealerId)
    .then(data => data.json())
    .then(response => cacheDealer(response))
    .catch(error => {
      // TODO handle js error here?
      if (cachedDealer) {
        return new Promise(resolve => resolve(cachedDealer));
      }
      return { message: error };
    });
};

export default getHomes;

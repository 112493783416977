import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { WithStore } from 'pure-react-carousel';
import posed, { PoseGroup } from 'react-pose';
import HomeCard from './HomeCard';
import FlagIcon from '../../images/FlagIcon';

const PosedHome = posed.div({
  enter: {
    opacity: 1,
    x: 0,
    transition: { duration: 500, delay: 100 },
  },
  exit: { opacity: 0, x: 50 },
  flip: {
    transition: {
      scale: {
        type: 'linear',
      },
    },
  },
});

class HomeList extends PureComponent {
  static propTypes = {
    pageOfHomes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dealer: PropTypes.shape({}).isRequired,
    setPage: PropTypes.func.isRequired,
    currentSlide: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
  };

  render() {
    const { pageOfHomes, dealer, setPage, currentSlide, index } = this.props;
    return (
      <div className="home-list grid">
        {Math.abs(index - currentSlide) <= 2 && (
          <PoseGroup animateOnMount>
            {pageOfHomes.map(home => (
              <PosedHome key={home.id}>
                <Link
                  onMouseDown={event => {
                    if (event.button !== 0) {
                      return;
                    }
                    if (!event.clientX || !event.clientY) {
                      return;
                    }
                    window.dataLayer.push({
                      homeId: '',
                    });
                    this.setState({
                      startX: event.clientX,
                      startY: event.clientY,
                    });
                  }}
                  onMouseUp={event => {
                    if (!this.state) {
                      return;
                    }
                    const { startX, startY } = this.state;
                    if (
                      !event ||
                      !event.clientX ||
                      !event.clientY ||
                      !startX ||
                      !startY
                    ) {
                      return;
                    }
                    const threshhold = 10;
                    const deltaX = Math.abs(event.clientX - startX);
                    const deltaY = Math.abs(event.clientY - startY);
                    const delta = Math.sqrt(deltaX ** 2 + deltaY ** 2);
                    if (delta < threshhold) {
                      window.dataLayer.push({
                        homeId: home.id,
                      });
                      setPage(currentSlide);
                      event.target.click();
                    }
                  }}
                  to={`/${dealer.dealerId}/details/${home.id}`}
                  className="card-link"
                >
                  {Math.abs(index - currentSlide) > 2 && (
                    <div>
                      <h2>Loading...</h2>
                    </div>
                  )}

                  {Math.abs(index - currentSlide) <= 2 && (
                    <HomeCard
                      key={`${home.serialNumber || home.modelNumber}`}
                      cardDecorator={
                        home.stickerDisplayText ? (
                          <FlagIcon text={home.stickerDisplayText} />
                        ) : (
                          ''
                        )
                      }
                      image={`${home.thumbnailImage ||
                        home.images[0]}?width=500&height=350`}
                      cardTitle={home.name}
                      subTitle={home.priceLabel || '\u00a0'} // unicode symbol for &nbsp; - forces <p> to enforce styles
                      descriptionList={[
                        `${home.squareFeet.toLocaleString()} sq. ft.`,
                        `${home.beds} beds`,
                        `${home.baths} baths`,
                        `${
                          home.isMultiSection
                            ? 'Multi Section'
                            : 'Single Section'
                        }`,
                      ]}
                    />
                  )}
                </Link>
              </PosedHome>
            ))}
          </PoseGroup>
        )}
      </div>
    );
  }
}

export default WithStore(HomeList, state => ({
  currentSlide: state.currentSlide,
}));

import styled from 'styled-components';

// TODO Use this correctly
// createGlobalStyle`
//     body.modal-lock {
//         overflow: hidden;
//     }
// `;

export const ModalStyles = styled.div`
  align-items: center;
  display: flex;
  font-family: 'acumin-pro', sans-serif;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;

  .backdrop {
    background-color: rgba(0, 0, 0, 0.85);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  a {
    text-decoration: none;
  }
  button {
    align-self: center;
    margin-top: 10px;
  }
  .modal-close-button {
    position: fixed;
    right: 0;
    top: 0;
    width: 100px;
    padding: 25px;
    -webkit-appearance: none;
    border: none;
    background: transparent;
    svg {
      width: 50px;
      height: 50px;
      #Symbols {
        g {
          stroke: #fff;
          path:first-of-type {
            display: none;
          }
          path {
            stroke-width: 2px;
          }
        }
      }
    }
  }
  .modal-contents {
    align-items: center;
    background: #fff;
    height: auto;
    margin: 0 auto;
    padding: 2% 0;
    position: relative;
    text-align: center;

    &:focus {
      outline: none;
    }
    * {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .modal-content-sm {
    padding: 40px;
    width: 25%;
  }
  .modal-content-md {
    width: 50%;
  }
  .modal-content-lg {
    width: 75%;
  }
  .modal-content-account {
    width: 520px;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
    margin-top: 10vh;
  }
  @media screen and (max-width: 1024px) {
    .modal-content-sm {
      width: 50%;
    }
  }
  @media screen and (max-width: 960px) {
    .modal-contents {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .modal-content-sm {
      width: 50%;
    }
  }

  @media screen and (max-width: 767px) {
    .modal-content-sm,
    .modal-content-md,
    .modal-content-lg {
      width: 90%;
    }
  }
  @media screen and (max-height: 826px) {
    .modal-content-account:not(.no-fullscreen) {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
  @media screen and (max-width: 767px) and (orientation: landscape) {
    .modal-content-sm {
      width: 50%;
    }
  }
  @media screen and (max-width: 480px) {
    .modal-content-account {
      .modal-contents {
        border: solid 1px orange;
      }
    }
  }
`;

export default ModalStyles;
